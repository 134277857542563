import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { AccountGetCurentUserUserModel } from "api";
import { NavLink } from "react-router-dom";
import { hasAdminRole } from "shared";

import NavCard from "../../shared/components/NavCard";
import { icons } from "../../theme";

interface Props {
  user: AccountGetCurentUserUserModel;
}

function AdminDashboard({ user }: Props) {
  return (
    <Grid container spacing={2} p={3}>
      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/sites">
          <NavCard>
            <FontAwesomeIcon icon={icons.hospital} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Sites
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/patients">
          <NavCard>
            <FontAwesomeIcon icon={icons.patient} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Patients
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>
      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/studies">
          <NavCard>
            <FontAwesomeIcon icon={icons.study} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Studies
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>
      {hasAdminRole(user) && (
        <Grid
          item
          xs={6}
          lg={4}
          display={"flex"}
          sx={{
            "& a": {
              width: "100%",
              textDecoration: "none",
              "&: hover": {
                "& .MuiCard-root": {
                  backgroundColor: "primary.dark",
                  color: "primary.contrastText",
                },
              },
            },
          }}
        >
          <NavLink to="/users">
            <NavCard>
              <FontAwesomeIcon icon={icons.user} size="lg"></FontAwesomeIcon>
              <Typography variant="h3" textAlign={"center"}>
                Users
              </Typography>
            </NavCard>
          </NavLink>
        </Grid>
      )}

      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/auditlogs">
          <NavCard>
            <FontAwesomeIcon icon={icons.logs} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Audit Logs
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>

      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/export">
          <NavCard>
            <FontAwesomeIcon icon={icons.export} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Export
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>
    </Grid>
  );
}
export default AdminDashboard;
