import { Typography } from "@mui/material";
import ErrorDisplay from "shared/components/ErrorDisplay";
import useFormErrors from "./useFormErrors";

interface Props {
  label?: string;
}

function FormErrorAlert({ label }: Props) {
  const { getFormError, isError } = useFormErrors();

  if (!isError) return null;

  const { error, helperText } = getFormError();
  if (!error) return null;

  return (
    <ErrorDisplay message={helperText}>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {label || "Your request could not be completed."}
      </Typography>
    </ErrorDisplay>
  );
}

export default FormErrorAlert;
