import { Card, CardContent, CardHeader } from "@mui/material";
import { useGetStudyPatientById, useUpdateStudyPatient } from "api";
import { useSnackbar } from "notistack";
import PatientInformation from "nurses/components/PatientInformation";
import EditStudyPatientForm from "nurses/forms/EditStudyPatientForm";
import { useParams } from "react-router";

function PatientDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const patientId = Number(patientIdParam);
  const studyId = Number(studyIdParam);
  const { data } = useGetStudyPatientById(patientId, studyId);
  const { mutate, ...status } = useUpdateStudyPatient();

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit Patient" />
      <CardContent>
        <PatientInformation
          patientId={patientId}
          studyId={studyId}
          hidePatientInfo={true}
        />

        {data && data.studyPatient && (
          <EditStudyPatientForm
            status={status}
            patient={data.studyPatient}
            onSave={(form) =>
              mutate(
                { patientId, studyId, data: form },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Patient Updated", {
                      variant: "success",
                    });
                  },
                }
              )
            }
          />
        )}
      </CardContent>
    </Card>
  );
}

export default PatientDetailsPage;
