import { Route, Routes } from "react-router";

import StudyTabs from "./components/StudyTabs";
import {
  AddSamplePage,
  AddStudyPage,
  AssignedPatientListPage,
  AssignedSiteListPage,
  AssignedVisitListPage,
  AssignPatientPage,
  AssignSitePage,
  EditVisitPage,
  ManageStudyPatientPage,
  SampleDetailsPage,
  SampleListPage,
  StudyDetailsPage,
  StudyListPage,
} from "./pages";
import ImportHistoryDetailsPage from "./pages/ImportHistoryDetailsPage";
import ImportHistoryListPage from "./pages/ImportHistoryListPage";

function StudyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<StudyListPage />} />
      <Route path="add" element={<AddStudyPage />} />
      <Route path=":studyId" element={<StudyTabs />}>
        <Route path="" element={<StudyDetailsPage />} />
        <Route path="sites" element={<AssignedSiteListPage />} />
        <Route path="sites/assign" element={<AssignSitePage />} />
        <Route path="patients" element={<AssignedPatientListPage />} />
        <Route path="patients/assign" element={<AssignPatientPage />} />
        <Route
          path="patients/:patientId"
          element={<ManageStudyPatientPage />}
        />
        <Route path="visits" element={<AssignedVisitListPage />} />
        <Route path="visits/:visitId" element={<EditVisitPage />} />
        <Route path="samples" element={<SampleListPage />} />
        <Route path="samples/add" element={<AddSamplePage />} />
        <Route path="samples/:sampleId" element={<SampleDetailsPage />} />
        <Route path="imports" element={<ImportHistoryListPage />} />
        <Route
          path="imports/:importId"
          element={<ImportHistoryDetailsPage />}
        />
      </Route>
    </Routes>
  );
}

export default StudyRoutes;
