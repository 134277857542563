import { Grid, Box } from "@mui/material";
import { useGetPatientScheduledVisitDetails } from "api";
import { parseISO } from "date-fns";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";

interface Props {
  visitId: number;
  patientId: number;
  hideVisitInfomation?: boolean;
}

function StudyVisitInformation({
  patientId,
  visitId,
  hideVisitInfomation = false,
}: Props) {
  const visit = useGetPatientScheduledVisitDetails(patientId, visitId).data
    ?.patientVisit;

  if (!visit) return null;

  return (
    <Grid
      container
      sx={{
        paddingBottom: 3,
        "& .MuiGrid-item": {
          padding: ".25rem",
          display: "flex",

          "& .MuiBox-root": {
            padding: ".25rem .5rem",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "grey.100",
            borderRadius: ".25rem",
            width: "100%",
            alignItems: "center",
          },
        },
      }}
    >
      <Grid item xs={2}>
        <Box>
          <strong>Study Name: </strong>
          {visit.studyName}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <strong>Patient Initials: </strong>
          {visit.initials}
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          <strong>Patient Screening #: </strong>
          {visit.patientScreeningNumber}
        </Box>
      </Grid>
      {!hideVisitInfomation && (
        <>
          <Grid item xs={2}>
            <Box>
              <strong>Visit Name: </strong>
              {visit.name}({visit.sequence})
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box>
              <strong>Visit Accession Number: </strong>
              {visit.accessionNumber}
            </Box>
          </Grid>
          {visit.scheduledDate && (
            <Grid item xs={2}>
              <Box>
                <strong>Visit Scheduled Date: </strong>
                <DateTimeDisplay
                  date={parseISO(visit.scheduledDate)}
                  dateFormat="MM/dd/yy hh:mm"
                />
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
export default StudyVisitInformation;
