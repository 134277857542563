import { AxiosError } from "axios";

export function isAxiosError(obj: any): obj is AxiosError {
  const err = obj as AxiosError;

  return !!err.isAxiosError;
}

export function getError(error: unknown) {
  if (isAxiosError(error)) {
    if (error.response?.data.detail)
      return new Error(error.response?.data.detail);
    if (error.response?.data.title)
      return new Error(error.response?.data.title);
    return new Error(error.response?.data ?? error.message);
  }

  return error as Error;
}
