import { Typography, Link } from "@mui/material";
import { formatPhoneNumber } from "shared";

interface Props {
  phone?: string | null;
}

function PhoneDisplay({ phone }: Props) {
  if (!phone) return <Typography>N/A</Typography>;
  return (
    <Link href={`tel:${phone}`} color="inherit">
      {formatPhoneNumber(phone) || "N/A"}
    </Link>
  );
}
export default PhoneDisplay;
