import { AccountGetCurentUserUserModel } from "api";

export enum UserRoleEnum {
  Admin = 1,
  Coordinator = 2,
  LabManager = 3,
  LabTechnician = 4,
}

export const UserRoleMap = new Map<number, string>([
  [UserRoleEnum.Admin, "Admin"],
  [UserRoleEnum.Coordinator, "Coordinator"],
  [UserRoleEnum.LabManager, "Lab Manager"],
  [UserRoleEnum.LabTechnician, "Lab Technician"],
]);

export function GetUserRoleList() {
  return Array.from(UserRoleMap, ([value, label]) => ({
    label,
    value,
  }));
}

export function hasCoordinatorRole(user: AccountGetCurentUserUserModel) {
  return (
    UserRoleMap.get(user.userType.id) === "Admin" ||
    UserRoleMap.get(user.userType.id) === "Lab Manager" ||
    UserRoleMap.get(user.userType.id) === "Coordinator"
  );
}
export function hasAdminRole(user: AccountGetCurentUserUserModel) {
  return (
    UserRoleMap.get(user.userType.id) === "Admin" ||
    UserRoleMap.get(user.userType.id) === "Lab Manager"
  );
}

export function canManageStudies(user: AccountGetCurentUserUserModel) {
  return (
    UserRoleMap.get(user.userType.id) === "Admin" ||
    UserRoleMap.get(user.userType.id) === "Lab Manager"
  );
}
