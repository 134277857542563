/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  VisitsGetAllVisitsResult,
  GetAllVisitsParams,
  VisitsGetVisitByIdResult,
  VisitsUpdateVisitResult,
  VisitsUpdateVisitForm,
  VisitsDeleteVisitResult,
  VisitsAddVisitResult,
  VisitsAddVisitForm,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllVisits = (
  params?: GetAllVisitsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<VisitsGetAllVisitsResult>(
    { url: `/api/Visit`, method: "get", params },
    options
  );
};

export const getGetAllVisitsQueryKey = (params?: GetAllVisitsParams) => [
  `/api/Visit`,
  ...(params ? [params] : []),
];

export const useGetAllVisits = <
  TData = AsyncReturnType<typeof getAllVisits>,
  TError = unknown
>(
  params?: GetAllVisitsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllVisits>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllVisitsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllVisits>> = () =>
    getAllVisits(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllVisits>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getVisitById = (
  visitId: number,
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<VisitsGetVisitByIdResult>(
    { url: `/api/Visit/${visitId}/studies/${studyId}`, method: "get" },
    options
  );
};

export const getGetVisitByIdQueryKey = (visitId: number, studyId: number) => [
  `/api/Visit/${visitId}/studies/${studyId}`,
];

export const useGetVisitById = <
  TData = AsyncReturnType<typeof getVisitById>,
  TError = unknown
>(
  visitId: number,
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getVisitById>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVisitByIdQueryKey(visitId, studyId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getVisitById>> = () =>
    getVisitById(visitId, studyId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getVisitById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(visitId && studyId), ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateVisit = (
  visitId: number,
  studyId: number,
  visitsUpdateVisitForm: VisitsUpdateVisitForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<VisitsUpdateVisitResult>(
    {
      url: `/api/Visit/${visitId}/studies/${studyId}`,
      method: "post",
      data: visitsUpdateVisitForm,
    },
    options
  );
};

export const useUpdateVisit = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateVisit>,
    TError,
    { visitId: number; studyId: number; data: VisitsUpdateVisitForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateVisit>,
    { visitId: number; studyId: number; data: VisitsUpdateVisitForm }
  > = (props) => {
    const { visitId, studyId, data } = props || {};

    return updateVisit(visitId, studyId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateVisit>,
    TError,
    { visitId: number; studyId: number; data: VisitsUpdateVisitForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteVisit = (
  visitId: number,
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<VisitsDeleteVisitResult>(
    { url: `/api/Visit/${visitId}/studies/${studyId}`, method: "delete" },
    options
  );
};

export const useDeleteVisit = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteVisit>,
    TError,
    { visitId: number; studyId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteVisit>,
    { visitId: number; studyId: number }
  > = (props) => {
    const { visitId, studyId } = props || {};

    return deleteVisit(visitId, studyId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof deleteVisit>,
    TError,
    { visitId: number; studyId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const addVisit = (
  studyId: number,
  visitsAddVisitForm: VisitsAddVisitForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<VisitsAddVisitResult>(
    {
      url: `/api/Visit/studies/${studyId}`,
      method: "post",
      data: visitsAddVisitForm,
    },
    options
  );
};

export const useAddVisit = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addVisit>,
    TError,
    { studyId: number; data: VisitsAddVisitForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addVisit>,
    { studyId: number; data: VisitsAddVisitForm }
  > = (props) => {
    const { studyId, data } = props || {};

    return addVisit(studyId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addVisit>,
    TError,
    { studyId: number; data: VisitsAddVisitForm },
    TContext
  >(mutationFn, mutationOptions);
};
