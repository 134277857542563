import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import EmptyContainer from "nurses/components/EmptyContainer";
import { useNavigate, useParams } from "react-router-dom";
import SampleForm from "studies/forms/SampleForm";

import {
  SamplesAddSampleResult,
  useAddSample,
  useGetAllVisits,
} from "../../api";

function AddSamplePage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const allVisitsResults = useGetAllVisits({ studyId, pageSize: 0 });

  const { mutate, ...status } = useAddSample({
    mutation: {
      onSuccess(data: SamplesAddSampleResult) {
        enqueueSnackbar("Sample Added", {
          variant: "success",
        });
        navigate(`/studies/${studyId}/samples/${data.item?.id}`);
      },
    },
  });

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Sample" />
      <CardContent>
        {!allVisitsResults.data?.items || allVisitsResults.data?.count === 0 ? (
          <EmptyContainer>
            <Typography>Please Add Visits</Typography>
          </EmptyContainer>
        ) : (
          <SampleForm
            studyId={studyId}
            visits={allVisitsResults.data?.items}
            onSave={(form) =>
              mutate({
                data: form,
              })
            }
            status={status}
          />
        )}
      </CardContent>
    </Card>
  );
}
export default AddSamplePage;
