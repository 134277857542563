  import { DialogContent } from "@mui/material";
import StyledDialog from "shared/components/StyledDialog";
import {
  useGetCurrentUser,
  AccountGetCurentUserUserModel,
  useGetAllUsers,
  useUpdateBatchPatientSampleTask,
} from "api";
import { useSnackbar } from "notistack";
import ManagePatientSampleTasks from "./ManagePatientSampleTasks";

interface Props {
  sampleId: number;
  patientId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function LabTaskModalContainer({ sampleId, patientId, open, setOpen }: Props) {
  const { data } = useGetCurrentUser();

  if (!data?.user) return null;

  return (
    <StyledDialog
      maxWidth="lg"
      open={open}
      title={''}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <SampleTaskModalContent
          patientId={patientId}
          sampleId={sampleId}
          currentUser={data.user}
          onCancel={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </StyledDialog>
  );
}

interface SampleTaskModalContentProps {
  patientId: number;
  sampleId: number;
  currentUser: AccountGetCurentUserUserModel;
  onCancel: () => void;
}
function SampleTaskModalContent({
  patientId,
  sampleId,
}: SampleTaskModalContentProps) {

  const { enqueueSnackbar } = useSnackbar();

  const updateBatchPatientSampleTaskResult = useUpdateBatchPatientSampleTask();

  const users = useGetAllUsers({
    userTypes: ["LabTechnician", "Admin"],
    pageSize: 0,
  });

  if (!users.data?.items) return null;

  return (
    <ManagePatientSampleTasks
      patientId={patientId}
      sampleId={sampleId}
      users={users.data.items}
      onSave={(form) => {
        updateBatchPatientSampleTaskResult.mutate(
          {
            data:{
              labTasks: form.labTasks,
            },
            params:{
              patientId: patientId,
              sampleId: sampleId,
            }
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Patient Task Updated", {
                variant: "success",
              });
            },
          }
        );
      }}
    />
    );
}

export default LabTaskModalContainer;
