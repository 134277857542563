import { useRemoveSite } from "api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import DeleteButton from "shared/components/DeleteButton";

interface Props {
  studyId: number;
  siteId: number;
}

function RemoveSite({ studyId, siteId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, error, ...status } = useRemoveSite({
    mutation: {},
  });

  return (
    <DeleteButton
      item="Site"
      status={status}
      error={error}
      text="Remove"
      onConfirm={() => {
        mutate(
          {
            siteId,
            studyId,
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Site is removed", {
                variant: "success",
              });
              navigate(`/studies/${studyId}/sites`);
            },
          }
        );
      }}
    />
  );
}

export default RemoveSite;
