import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import EditVisitForm from "studies/forms/EditVisitForm";
import { useGetVisitById, useUpdateVisit } from "../../api";

function EditVisitPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const { visitId: visitIdParam } = useParams<{ visitId: string }>();
  const studyId = Number(studyIdParam);
  const visitId = Number(visitIdParam);

  const visitDetails = useGetVisitById(visitId, studyId);

  const { mutate, ...status } = useUpdateVisit();

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit Visit" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {visitDetails.data?.visit && (
              <EditVisitForm
                status={status}
                visit={visitDetails.data.visit}
                onSave={(form) => {
                  mutate(
                    {
                      studyId: studyId,
                      visitId: visitId,
                      data: {
                        name: form.name,
                        sequence: form.sequence,
                        referenceId: form.referenceId,
                      },
                    },
                    {
                      onSuccess: () => {
                        enqueueSnackbar("Visit Updated", {
                          variant: "success",
                        });
                      },
                    }
                  );
                }}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default EditVisitPage;
