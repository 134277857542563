import { Box, Button, Grid, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import BackButtonComponent from "shared/components/BackButton";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import CheckboxField from "shared/forms/fields/CheckboxField";
import DateTimeField from "shared/forms/fields/DateTimeField";
import DropDownField from "shared/forms/fields/DropDownField";
import { PatientSampleStatusEnum } from "shared/models/patient-sample-status.enum";
import {
  getSampleType,
  ShippingFrequencyEnum,
  ShippingTemperatureEnum,
  UrineSampleTypeEnum,
} from "shared/models/sample-type.enum";

import {
  NurseGetPatientSampleDetailsPatientSampleModel,
  NurseUpdatePatientSampleForm,
  useGetPatientSampleDetails,
} from "../../api";
import TextField from "../../shared/forms/fields/TextField";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import withFormStatus from "../../shared/forms/withFormStatus";
import StickyFooter from "../../shared/layout/StickyFooter";
import ScanSampleKitModal from "shared/components/ScanSampleKitModal";

interface BaseProps {
  onSave(form: NurseUpdatePatientSampleForm): void;
  onCaptureDraw(sampleId: number, refetchSample?: () => void): void;
}

interface EditPatientSampleFormProps extends BaseProps {
  patientSample: NurseGetPatientSampleDetailsPatientSampleModel;
}

function EditPatientSampleForm({
  onSave,
  onCaptureDraw,
  patientSample,
}: EditPatientSampleFormProps) {
  const { control, handleSubmit, formState, setValue } =
    useForm<NurseUpdatePatientSampleForm>({
      defaultValues: {
        drawTime: patientSample.drawTime ?? "",
        kitName: patientSample.kitName ?? "",
        status: patientSample.status,
        quantity: patientSample.expectedQuantity,
        comments: patientSample.comments,
        isFasting: patientSample.isFasting,
        sampleKitCode: patientSample.sampleKitCode,
      },
      mode: "onChange",
    });
  const onSubmit = handleSubmit((form) => onSave(form));
  const [scanCode, setScanCode] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code) {
      patientSample.sampleKitCode = code;
      setValue("sampleKitCode", code);
    }
  }, [code, patientSample, setValue]);

  return (
    <>
    {scanCode && (
        <ScanSampleKitModal
          title={"Scan sample kit"}
          open={scanCode}
          setCode={(code: string) => setCode(code)}
          setOpen={(x) => setScanCode(x)}
        />
      )}
      <Box onSubmit={onSubmit} component="form" pt={3}>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <DateTimeField
              control={control}
              name="drawTime"
              label="Draw Time"
              required
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            sx={{ alignItems: "flex-end", display: "flex" }}
          >
            <Button
              fullWidth
              onClick={() => onCaptureDraw(patientSample.sampleId)}
              sx={{ minHeight: "56px" }}
            >
              Capture Draw Time
            </Button>
          </Grid>
          <Grid item sm={4} xs={6}>
            <Button
              onClick={() => {
                setScanCode(true);
              }}
            >
              Scan Sample Kit
            </Button>
          </Grid>
          <Grid item sm={8} xs={6} display="flex">
            <Box
              sx={{
                padding: ".25rem .5rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "grey.100",
                borderRadius: ".25rem",
                width: "100%",
                alignItems: "center",
              }}
            >
              <strong>Sample Kit Code: {patientSample.sampleKitCode}</strong>
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <DropDownField
              options={PatientSampleStatusEnum}
              rules={{
                required: true,
              }}
              getOptionKey={(opt) => opt.key}
              getOptionLabel={(opt) => opt.label}
              control={control}
              name="status"
              label="Status"
              required
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              control={control}
              name="kitName"
              label="Kit Name"
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              control={control}
              name="quantity"
              label="Collection Quantity"
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CheckboxField
              control={control}
              name="isFasting"
              labelName="Is Fasting"
              label="Is Fasting"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              control={control}
              name="comments"
              label="Comments"
              fullWidth
            />
          </Grid>
        </Grid>
        <StickyFooter>
          <BackButtonComponent text="Cancel" />
          <SubmitButton variant="save" disabled={!formState.isValid} />
        </StickyFooter>
      </Box>
      <Box>
        <FormErrorAlert />
        <Grid container spacing={4}>
          {!!patientSample.urineType &&
            patientSample.sampleType === 0 &&
            patientSample.urineType >= 0 && (
              <Grid item sm={6} xs={6}>
                <Typography>
                  Urine Type:{" "}
                  {getSampleType(UrineSampleTypeEnum, patientSample.urineType)}
                </Typography>
              </Grid>
            )}
          <Grid item sm={6} xs={6}>
            <Typography>
              Expected Quantity: {patientSample.expectedQuantity}
            </Typography>
          </Grid>
          {!!patientSample.clotTime && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Clot Time: {patientSample.clotTime ?? "--"}
              </Typography>
            </Grid>
          )}
          {!!patientSample.storageTime && (
            <Grid item sm={6} xs={6}>
              <Typography>Storage Time: {patientSample.storageTime}</Typography>
            </Grid>
          )}
          {!!patientSample.storageTemperature && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Storage Temperature (C): {patientSample.storageTemperature}
              </Typography>
            </Grid>
          )}
          {patientSample.aliquotTime && (
            <Grid item sm={6} xs={6}>
              <Typography>Aliquot Time: {patientSample.aliquotTime}</Typography>
            </Grid>
          )}
          {!!patientSample.specialInstructions && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Special Instructions: {patientSample.specialInstructions}
              </Typography>
            </Grid>
          )}
          {patientSample.shipInstructions && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Shipping Instructions: {patientSample.shipInstructions}
              </Typography>
            </Grid>
          )}
          {!!patientSample.shippingInformation.temperature && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Shipping Temperature:{" "}
                {getSampleType(
                  ShippingTemperatureEnum,
                  patientSample.shippingInformation.temperature
                )}
              </Typography>
            </Grid>
          )}
          {!!patientSample.shippingInformation.frequency && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Shipping Frequency:{" "}
                {getSampleType(
                  ShippingFrequencyEnum,
                  patientSample.shippingInformation.frequency
                )}
              </Typography>
            </Grid>
          )}
          {patientSample.processingRequirements.centrifugeTime && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Centrifugation Time:{" "}
                {patientSample.processingRequirements.centrifugeTime}
              </Typography>
            </Grid>
          )}
          {!!patientSample.processingRequirements.centrifugeSpeed && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Centrifugation Speed (Xg):{" "}
                {patientSample.processingRequirements.centrifugeSpeed}
              </Typography>
            </Grid>
          )}
          {!!patientSample.processingRequirements.processingTemp && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Centrifugation Temperature:{" "}
                {patientSample.processingRequirements.processingTemp}
              </Typography>
            </Grid>
          )}
          {!!patientSample.processingRequirements.processingTempUnit && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Centrifugation Temperature Unit:{" "}
                {patientSample.processingRequirements.processingTempUnit}
              </Typography>
            </Grid>
          )}
          {patientSample.urineProcessingRequirements.expirationDate && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Urine Processing Expiration Date:
                <DateTimeDisplay
                  date={parseISO(
                    patientSample.urineProcessingRequirements.expirationDate
                  )}
                  dateFormat="MM/dd/yyyy"
                />
                {}
              </Typography>
            </Grid>
          )}
          {patientSample.urineProcessingRequirements.kitName && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Urine Processing Kit Name:{" "}
                {patientSample.urineProcessingRequirements.kitName}
              </Typography>
            </Grid>
          )}
          {patientSample.urineProcessingRequirements.lotNumber && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Urine Processing Lot Number:{" "}
                {patientSample.urineProcessingRequirements.lotNumber}
              </Typography>
            </Grid>
          )}
          {patientSample.urineProcessingRequirements.processingType && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Urine Processing Type:{" "}
                {patientSample.urineProcessingRequirements.processingType}
              </Typography>
            </Grid>
          )}
          {patientSample.urineProcessingRequirements.readTime && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Urine Processing Read Time:{" "}
                {patientSample.urineProcessingRequirements.readTime}
              </Typography>
            </Grid>
          )}
          {patientSample.drawTimeCapturedBy && (
            <Grid item sm={6} xs={6}>
              <Typography>
                Last collection time captured by:{" "}
                {patientSample.drawTimeCapturedBy}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      
    </>
  );
}

interface EditPatientSampleContainerProps extends BaseProps {
  patientId: number;
  sampleId: number;
}

function EditPatientSampleContainer({
  onSave,
  onCaptureDraw,
  patientId,
  sampleId,
}: EditPatientSampleContainerProps) {
  const { data, refetch, isRefetching } = useGetPatientSampleDetails(
    patientId,
    sampleId
  );
  const sample = data?.patientSample;

  const refetchSample = useCallback(() => {
    refetch();
  }, [refetch]);

  const captureDraw = useCallback(
    (selectedSampleId: number) => {
      if (!!onCaptureDraw) {
        onCaptureDraw(selectedSampleId, refetchSample);
      }
    },
    [onCaptureDraw, refetchSample]
  );
  const saveSample = useCallback(
    (form: NurseUpdatePatientSampleForm): void => {
      if (!!onSave) {
        onSave(form);
      }
    },
    [onSave]
  );

  if (!sample) return null;
  if (isRefetching) return null;

  return (
    <EditPatientSampleForm
      patientSample={sample}
      onSave={saveSample}
      onCaptureDraw={captureDraw}
    />
  );
}

export default withFormStatus(EditPatientSampleContainer);
