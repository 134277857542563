import LookupPatient from "nurses/components/LookupPatient";
import { useNavigate } from "react-router-dom";

function LookupPatientPage() {
  const navigate = useNavigate();
  return (
    <LookupPatient
      onPatientSelect={(studyId, patientId) => {
        navigate(`/nurses/patients/${patientId}/studies/${studyId}`);
      }}
    />
  );
}

export default LookupPatientPage;
