import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { useAddUser, UsersAddUserResult } from "../../api";
import AddUserForm from "../forms/AddUserForm";

function AddUserPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, ...status } = useAddUser({
    mutation: {
      onSuccess(data: UsersAddUserResult) {
        enqueueSnackbar("User Added", {
          variant: "success",
        });
        navigate(`/users/${data.user?.id}`);
      },
    },
  });

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add User" />

      <CardContent>
        <AddUserForm
          onSave={(form) => mutate({ data: form })}
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default AddUserPage;
