import {
  Dialog,
  DialogTitle,
  DialogProps,
  styled,
  Stack,
  Button,
} from "@mui/material";

type Props = {
  maxWidth: string;
  title: string;
  onClose: () => void;
} & DialogProps;

function StyledDialog({
  title,
  onClose,
  children,
  ...props
}: Props) {
  return (
    <Dialog onClose={onClose} fullWidth {...props} maxWidth={props.maxWidth}>
      
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <DialogTitle sx={{ textTransform: "uppercase" }}>{title}</DialogTitle>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </Stack>

      {children}
    </Dialog>
  );
}
export default styled(StyledDialog)({});
