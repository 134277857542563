import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as router from "react-router-dom";

export default function useLocationState<T>(key: string, defaultState: T) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation<{ [k: string]: T | undefined }>();
  const currentState = state?.[key] ?? defaultState;

  const updateState = useCallback(
    (next: T) =>
      navigate(pathname, { state: { ...state, [key]: next }, replace: true }),
    [navigate, state, key, pathname]
  );

  return [currentState, updateState] as const;
}

function useLocation<T>() {
  type L = router.Location & { state: T };

  return router.useLocation() as L;
}
