import { Button, ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface Props extends Omit<ButtonProps, "action" | "onClick"> {
  text?: string;
  to?: string;
}
function BackButtonComponent({ text = "Back", to, ...props }: Props) {
  const navigate = useNavigate();
  return (
    <Button
      sx={{ margin: "0 .25rem" }}
      {...props}
      onClick={() => {
        if (to) navigate(to);
        else navigate(-1);
      }}
    >
      {text}
    </Button>
  );
}
export default BackButtonComponent;
