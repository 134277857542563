import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PhoneDisplay from "shared/components/display/PhoneDisplay";

import { useGetAllSites } from "../../api";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";

const columns: GridColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/sites/${row.id}`}>
        {row.name}
      </Link>
    ),
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    renderCell: ({ row }) =>
      `${row.address?.line1} ${row.address?.city} ${row.address?.state} ${row.address?.zip}`,
  },
  {
    field: "contact.email",
    headerName: "Email",
    flex: 1,
    valueGetter: ({ row }) => row.contact?.email,
  },
  {
    field: "contact.phone",
    headerName: "Phone",
    flex: 1,
    renderCell: ({ row }) => <PhoneDisplay phone={row.contact?.phone} />,
  },
  {
    field: "site.studies",
    headerName: "Studies",
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/sites/${row.id}/studies`}>
        View Studies
      </Link>
    ),
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"Site List"}
      addButtonLabel="Add Site"
      onAdd={() => navigate("/sites/add")}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function SiteList() {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "sites",
    columns
  );
  const { data, isFetching } = useGetAllSites(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SiteList;
