import "./App.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material";
import { AuthState, getAuthSession } from "account/auth/context";
import { clearJwtToken, setJwtToken } from "api/client";
import { SnackbarProvider } from "notistack";
import React from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { theme } from "theme";

import { PublicRoutes } from "./account/AccountRoutes";
import AuthProvider from "./account/auth/AuthProvider";
import AppRoutes from "./AppRoutes";

const queryCache = new QueryCache({
  onSuccess: (_, y) => {
    y.cacheTime = 0;
  },
  onError: (_, y) => {
    y.cacheTime = 0;
  },
});
const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions:{
    queries:{
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  }
});

function onAuthStateChange(state: AuthState) {
  if (state.authenticated) {
    setJwtToken(async () =>
      (await getAuthSession(state.user)).getAccessToken().getJwtToken()
    );
  } else {
    clearJwtToken();
  }
}
function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <SnackbarProvider autoHideDuration={2000}>
            <AuthProvider
              publicRoutes={<PublicRoutes />}
              onStateChange={onAuthStateChange}
            >
              <QueryClientProvider client={queryClient}>
                <AppRoutes />
              </QueryClientProvider>
            </AuthProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
