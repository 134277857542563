import { Route, Routes } from "react-router";
import LabTabs from "./components/LabTabs";

import {
  VisitDetailsPage, VisitSamplesPage,
} from "./pages";
import PatientScheduledVisitListPage from "./pages/PatientScheduledVisitListPage";

function LabRoutes() {
  return (
    <Routes>
      <Route path="">
      <Route path="" element={<PatientScheduledVisitListPage />}/>
        <Route path="studies/:studyId/visits/:visitId/patients/:patientId" element={<LabTabs/>}>
          <Route path="" element={<VisitDetailsPage />} />
          <Route path="samples" element={<VisitSamplesPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default LabRoutes;
