import { useEffect, useState } from "react";
import { Button, Card, Grid } from "@mui/material";
import { useIdleTimer } from "react-idle-timer";
import { useCheckUserPin, useGetCurrentUser, UsersCheckUserPinForm } from "api";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import TextField from "shared/forms/fields/TextField";
import { setTimedOut, timedOut } from "shared/utils/timeout";

function TimeoutOverlay() {
  const timeout = 60000;
  const PINLength = 4;

  const re = /^[0-9\b]{4}$/;

  const { data } = useGetCurrentUser();
  const [isTimedOut, setIsTimedOut] = useState(timedOut());
  const [invalidPin, setInvalidPin] = useState(false);

  useEffect(() => {
    setTimedOut(isTimedOut);
  },[isTimedOut]);

  const { mutate } = useCheckUserPin({
    mutation: {
      onSuccess() {
        setIsTimedOut(false);
        setInvalidPin(false);
        document.body.style.overflow = "auto";
      },
      onError() {
        setInvalidPin(true);
      },
    },
  });
  
  const { control, handleSubmit, setValue, getValues } =
    useForm<UsersCheckUserPinForm>({ defaultValues: {} });

  const handleOnIdle = () => {
    setIsTimedOut(true);
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
  };

  const onSubmit = handleSubmit((form) => {
    if(getValues('pin')){
      mutate({ data: form })
      setValue('pin', undefined);
    }
  });

  const keyPressed = (digit: string) => {
    const pin = getValues("pin");
    let newPin = ( pin ?? 0) * 10 + +digit;
    setValue("pin", newPin);
    if(`${newPin}`.length === PINLength)
      onSubmit();
  };

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const logout = () => {
    setIsTimedOut(false);
    document.body.style.overflow = "auto"
  }

  return isTimedOut ? (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        zIndex: "9999",
        backgroundColor: "rgba(52, 52, 52, 0.9)",
      }}
    >
      <Card
        style={{
          height: "100%",
          width: "250px",
          zIndex: "9999",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        component="form"
        onSubmit={onSubmit}
      >
        <h2
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          SESSION TIMEOUT
        </h2>
        <h4
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          Current User: {data?.user?.username}
        </h4>
        {invalidPin && (
          <h4
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            Invalid Pin. Try again.
          </h4>
        )}
        <h4
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          Enter PIN code to continue
        </h4>
        <TextField
          type="password"
          control={control}
          name="pin"
          label="PIN"
          rules={{ 
            validate:{
              checkPIN: (value: string) =>
                re.test(value)
              },
          }}
          sx={{ input: { color: "white" } }}
        />
        <Grid container spacing={9}>
          <Grid item xs={3}>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("1")}>
              1
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("4")}>
              4
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("7")}>
              7
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "#ee4038" }}
              onClick={() => setValue("pin", undefined)}
            >
              X
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("2")}>
              2
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("5")}>
              5
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("8")}>
              8
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("0")}>
              0
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("3")}>
              3
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("6")}>
              6
            </Button>
            <Button style={{ margin: "10px" }} onClick={() => keyPressed("9")}>
              9
            </Button>
            <Button
              style={{ margin: "10px", backgroundColor: "#03fc7f" }}
              onClick={() => onSubmit()}
            >
              {">"}
            </Button>
          </Grid>
        </Grid>
        <NavLink
          to="/account/signout"
          onClick={() => logout()}
          style={{ textDecoration: "none" }}
        >
          <h5
            style={{
              color: "white",
              textAlign: "center",
              textDecorationLine: "underline",
            }}
          >
            Logout
          </h5>
        </NavLink>
      </Card>
    </div>
  ) : (
    <></>
  );
}
export default TimeoutOverlay;
