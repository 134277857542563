import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { UsersGetUserByIdUserModel, UsersUpdateUserForm } from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import PhoneField from "shared/forms/fields/PhoneField";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";
import RadioGroupField from "shared/forms/fields/RadioGroupField";
import { GetUserRoleList } from "shared";

interface Props {
  user: UsersGetUserByIdUserModel;
  onSave(form: UsersUpdateUserForm): void;
}

function EditUserForm({ user, onSave }: Props) {
  const { control, handleSubmit, formState } = useForm<UsersUpdateUserForm>({
    defaultValues: user,
    mode: "onChange",
  });

  // const { mutate } = useResetUserPassword({
  //   mutation: {
  //     onSuccess() {
  //       enqueueSnackbar("Password Reset", {
  //         variant: "success",
  //       });
  //     },
  //     onError() {
  //       enqueueSnackbar("Failed to Reset Password", {
  //         variant: "error",
  //       });
  //     },
  //   },
  // });

  const onSubmit = handleSubmit((form) => onSave(form));

  const re = /^[0-9\b]{4}$/;

  if (!user) return null;

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={12} xs={6}>
          <Typography fontWeight="bold">Username: {user.username}</Typography>
          <Typography fontWeight="bold">Status: {user.userStatus}</Typography>
        </Grid>
        <Grid item sm={4}>
          <TextField
            control={control}
            name="name.first"
            label="First Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            control={control}
            name="name.middle"
            label="Middle Name"
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            control={control}
            name="name.last"
            label="Last Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="contact.email"
            label="Contact Email"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneField
            control={control}
            name="contact.phone"
            label="Contact Phone"
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            type="password"
            control={control}
            name="pin"
            label="PIN"
            rules={{
              validate: {
                checkPIN: (value: string) => re.test(value),
              },
            }}
            helperText="Use a 4 digit numeric pin"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={12} xs={6}>
          <RadioGroupField
            control={control}
            labelName="User Type"
            name="userTypeId"
            options={GetUserRoleList()}
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent />
        {/* Remove until we can figure out how to set the link in the Reset Password email
         At the moment, the email sends a message and contains a verification code, but there are no instructions
         of how to use that code.
        {user.canResetPassword && (
          <Button
            onClick={() => {
              mutate({ id: user.id });
            }}
          >
            Reset Password
          </Button>
        )} */}
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditUserForm);
