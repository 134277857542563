import { Grid, Typography } from "@mui/material";
import { ImportGetImportHistoryDetailsImportStudyModel } from "api";

interface Props {
  importStudy: ImportGetImportHistoryDetailsImportStudyModel;
}

function ImportStudyDetails({ importStudy }: Props) {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography>CC Id: {importStudy.ccId}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Study Name: {importStudy.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Study Description: {importStudy.description}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default ImportStudyDetails;
