import { Route, Routes } from "react-router";
import AuditLogListPage from "./pages/AuditLogPage";

function AuditLogRoutes() {
  return (
    <Routes>
      <Route path="" element={<AuditLogListPage />} />
    </Routes>
  );
}

export default AuditLogRoutes;
