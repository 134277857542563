type Value = { key: number; label: string };
type Values = Value[];

export const SampleTypeEnum: Values = [
  {
    label: "Urine",
    key: 0,
  },
  {
    label: "Serum",
    key: 1,
  },
  {
    label: "Citrate Plasma",
    key: 2,
  },
  {
    label: "Coag Plasma",
    key: 3,
  },
  {
    label: "EDTA Plasma",
    key: 4,
  },
  {
    label: "P800 Plasma",
    key: 5,
  },
  {
    label: "EDTA Whole Blood",
    key: 6,
  },
  {
    label: "Heparinized Whole Blood",
    key: 7,
  },
  {
    label: "NaF Whole Blood",
    key: 8,
  },
  {
    label: "Whole Blood PAX Gene",
    key: 9,
  },
  {
    label: "Mouthwash",
    key: 10,
  },
  {
    label: "SST Serum",
    key: 11,
  },
  {
    label: "Swab",
    key: 12,
  },
  {
    label: "Saliva",
    key: 13,
  },
];

export const UrineSampleTypeEnum: Values = [
  { key: 0, label: "UPT" },
  { key: 1, label: "Urinalysis" },
  { key: 2, label: "Urine Drug Screening" },
];

export const ShippingTemperatureEnum: Values = [
  { key: 0, label: "Frozen" },
  { key: 1, label: "Ambient" },
  { key: 2, label: "Refrigerated" },
];

export const ShippingFrequencyEnum: Values = [
  { key: 0, label: "Day of Collection" },
  { key: 1, label: "Weekly" },
  { key: 2, label: "Monthly" },
];

export function getSampleType(values: Values, key: number) {
  return values[key]?.label ?? "";
}
