import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { StudiesAddStudyResult, useAddStudy, useGetAllUsers } from "../../api";
import AddStudyForm from "../forms/AddStudyForm";

function AddSitePage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { data } = useGetAllUsers();

  const { mutate, ...status } = useAddStudy({
    mutation: {
      onSuccess(data: StudiesAddStudyResult) {
        enqueueSnackbar("Study Added", {
          variant: "success",
        });
        navigate(`/studies/${data.item?.id}`);
      },
    },
  });

  if (!data?.items) return null;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Study" />

      <CardContent>
        <AddStudyForm
          onSave={(form) => mutate({ data: form })}
          status={status}
          users={data.items}
        />
      </CardContent>
    </Card>
  );
}
export default AddSitePage;
