import React from "react";
import { Button } from "@mui/material";

type Props = React.PropsWithChildren<{}>;
function ButtonComponent({ children }: Props) {
  return (
    <Button
      sx={{
        height: "fit-content",
        boxShadow: "none",
        margin: "0 .25rem",
        fontWeight: "700",
      }}
    >
      {children}
    </Button>
  );
}
export default ButtonComponent;
