/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  UsersGetAllUsersResult,
  GetAllUsersParams,
  UsersAddUserResult,
  UsersAddUserForm,
  UsersGetUserByIdResult,
  UsersUpdateUserResult,
  UsersUpdateUserForm,
  UsersCheckUserPinResult,
  UsersCheckUserPinForm,
  UsersSetUserPasswordForm,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllUsers = (
  params?: GetAllUsersParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersGetAllUsersResult>(
    { url: `/api/Users`, method: "get", params },
    options
  );
};

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams) => [
  `/api/Users`,
  ...(params ? [params] : []),
];

export const useGetAllUsers = <
  TData = AsyncReturnType<typeof getAllUsers>,
  TError = unknown
>(
  params?: GetAllUsersParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getAllUsers>, TError, TData>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllUsers>> = () =>
    getAllUsers(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllUsers>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addUser = (
  usersAddUserForm: UsersAddUserForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersAddUserResult>(
    { url: `/api/Users`, method: "post", data: usersAddUserForm },
    options
  );
};

export const useAddUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addUser>,
    TError,
    { data: UsersAddUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addUser>,
    { data: UsersAddUserForm }
  > = (props) => {
    const { data } = props || {};

    return addUser(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addUser>,
    TError,
    { data: UsersAddUserForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getUserById = (
  id: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersGetUserByIdResult>(
    { url: `/api/Users/${id}`, method: "get" },
    options
  );
};

export const getGetUserByIdQueryKey = (id: number) => [`/api/Users/${id}`];

export const useGetUserById = <
  TData = AsyncReturnType<typeof getUserById>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getUserById>, TError, TData>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserById>> = () =>
    getUserById(id, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUserById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateUser = (
  id: number,
  usersUpdateUserForm: UsersUpdateUserForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersUpdateUserResult>(
    { url: `/api/Users/${id}`, method: "post", data: usersUpdateUserForm },
    options
  );
};

export const useUpdateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateUser>,
    TError,
    { id: number; data: UsersUpdateUserForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateUser>,
    { id: number; data: UsersUpdateUserForm }
  > = (props) => {
    const { id, data } = props || {};

    return updateUser(id, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateUser>,
    TError,
    { id: number; data: UsersUpdateUserForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const checkUserPin = (
  usersCheckUserPinForm: UsersCheckUserPinForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersCheckUserPinResult>(
    { url: `/api/Users/pin`, method: "post", data: usersCheckUserPinForm },
    options
  );
};

export const useCheckUserPin = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof checkUserPin>,
    TError,
    { data: UsersCheckUserPinForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof checkUserPin>,
    { data: UsersCheckUserPinForm }
  > = (props) => {
    const { data } = props || {};

    return checkUserPin(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof checkUserPin>,
    TError,
    { data: UsersCheckUserPinForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const resetUserPassword = (
  id: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<UsersUpdateUserResult>(
    { url: `/api/Users/${id}/resetpassword`, method: "post" },
    options
  );
};

export const useResetUserPassword = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof resetUserPassword>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof resetUserPassword>,
    { id: number }
  > = (props) => {
    const { id } = props || {};

    return resetUserPassword(id, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof resetUserPassword>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const setUserPassword = (
  id: number,
  usersSetUserPasswordForm: UsersSetUserPasswordForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<void>(
    {
      url: `/api/Users/${id}/setpassword`,
      method: "post",
      data: usersSetUserPasswordForm,
    },
    options
  );
};

export const useSetUserPassword = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof setUserPassword>,
    TError,
    { id: number; data: UsersSetUserPasswordForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof setUserPassword>,
    { id: number; data: UsersSetUserPasswordForm }
  > = (props) => {
    const { id, data } = props || {};

    return setUserPassword(id, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof setUserPassword>,
    TError,
    { id: number; data: UsersSetUserPasswordForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const resendConfirmationEmail = (
  id: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<void>(
    { url: `/api/Users/${id}/resendconfirmation`, method: "post" },
    options
  );
};

export const useResendConfirmationEmail = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof resendConfirmationEmail>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof resendConfirmationEmail>,
    { id: number }
  > = (props) => {
    const { id } = props || {};

    return resendConfirmationEmail(id, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof resendConfirmationEmail>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
