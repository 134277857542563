import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useGetLabTechnicianPatientSamplesByVisitId } from "api";
import LabTaskModalContainer from "lab/components/LabTaskModalContainer";
import SampleDetails from "lab/components/SampleDetails";
import SampleList from "lab/components/SampleList";
import StudyVisitInformation from "lab/components/StudyVisitInformation";
import { useState } from "react";
import { useParams } from "react-router-dom";
import BackButtonComponent from "shared/components/BackButton";
import StickyFooter from "shared/layout/StickyFooter";

function VisitSamplesPage() {
  const { patientId: patientIdParam, visitId: visitIdParam } =
    useParams<{ patientId: string; visitId: string }>();

  const patientId = Number(patientIdParam);
  const visitId = Number(visitIdParam);

  const [open, setOpen] = useState(false);

  const [selectedSample, setSelectedSample] = useState(0);

  const { data } = useGetLabTechnicianPatientSamplesByVisitId(
    patientId,
    visitId
  );

  if (!data) return null;

  return (
    <>
      <LabTaskModalContainer
        sampleId={selectedSample}
        patientId={patientId}
        open={open}
        setOpen={setOpen}
        />
      <Card elevation={0} sx={{ overflow: "unset" }}>
        <CardHeader title="Study-Patient Info" />
        <CardContent>
          <StudyVisitInformation
            patientId={patientId}
            visitId={visitId}
            hideVisitInfomation={false}
          />
          <Grid container spacing={2}>
            <Grid item xs={6} display={"flex"} sx={{ width: "100%" }}>
              <SampleList
                samples={data.patientSamples}
                onSampleSelect={(sampleId) => {
                  setSelectedSample(sampleId);
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              display={"flex"}
              sx={{
                width: "100%",
                border: "solid thin",
                borderRadius: ".5rem",
                borderColor: "grey.300",
              }}
            >
              <SampleDetails sampleId={selectedSample} patientId={patientId} />
            </Grid>
          </Grid>
        </CardContent>
        <StickyFooter>
          {!!selectedSample && <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Record Tasks
          </Button>}
          <BackButtonComponent text="Back to list" to="/lab" />
        </StickyFooter>
      </Card>
    </>
  );
}

export default VisitSamplesPage;
