import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  SamplesGetSampleByIdSampleModel,
  SamplesAddSampleForm,
  SamplesUpdateSampleForm,
  VisitsGetAllVisitsVisitModel,
} from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";
import NumberField from "shared/forms/fields/NumberField";
import DropDownField from "shared/forms/fields/DropDownField";
import {
  SampleTypeEnum,
  ShippingFrequencyEnum,
  ShippingTemperatureEnum,
  UrineSampleTypeEnum,
} from "shared/models/sample-type.enum";
import DateField from "shared/forms/fields/DateField";
import Button from "@mui/material/Button";

interface Props {
  studyId: number;
  sample?: SamplesGetSampleByIdSampleModel;
  visits: VisitsGetAllVisitsVisitModel[];
  setOpen?(state: boolean): void;
  onSave(form: SamplesAddSampleForm | SamplesUpdateSampleForm): void;
}

function SampleForm({ studyId, visits, sample, onSave, setOpen }: Props) {
  const { control, handleSubmit, formState, watch } = useForm<
    SamplesAddSampleForm | SamplesUpdateSampleForm
  >({
    defaultValues: {
      ...sample,
    },
    mode: "onChange",
  });

  const sampleType = watch("sampleType");

  const onSubmit = handleSubmit((form) => onSave(form));

  const centrifugationTimeOptions = [
    { key: "", label: "N/A" },
    { key: "00:05:00", label: "5 Minutes" },
    { key: "00:10:00", label: "10 Minutes" },
    { key: "00:15:00", label: "15 Minutes" },
    { key: "00:20:00", label: "20 Minutes" },
    { key: "00:25:00", label: "25 Minutes" },
    { key: "00:30:00", label: "30 Minutes" },
  ];

  const timeOptions = [
    { key: "00:15:00", label: "15 Minutes" },
    { key: "00:30:00", label: "30 Minutes" },
    { key: "00:45:00", label: "45 Minutes" },
    { key: "01:00:00", label: "1 Hour" },
    { key: "01:15:00", label: "1 Hour 15 Minutes" },
    { key: "01:30:00", label: "1 Hour 30 Minutes" },
    { key: "01:45:00", label: "1 Hour 45 Minutes" },
    { key: "02:00:00", label: "2 Hours" },
  ];

  const clotTimeOptions = [
    { key: "00:30:00", label: "30 Minutes" },
    { key: "00:35:00", label: "35 Minutes" },
    { key: "00:40:00", label: "40 Minutes" },
    { key: "00:45:00", label: "45 Minutes" },
    { key: "00:50:00", label: "50 Minutes" },
    { key: "00:55:00", label: "55 Minutes" },
    { key: "01:00:00", label: "60 Minutes" },
  ];

  const tempUnits = [
    { key: "C", label: "C" },
    { key: "F", label: "F" },
  ];

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <DropDownField
            options={visits}
            getOptionKey={(opt: VisitsGetAllVisitsVisitModel) => opt.id}
            getOptionLabel={(opt: VisitsGetAllVisitsVisitModel) =>
              `${opt.name}`
            }
            control={control}
            name="visitId"
            label="Visit Name"
            required
          />
        </Grid>
        <Grid item sm={sampleType === 0 ? 2 : 4}>
          <DropDownField
            options={SampleTypeEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="sampleType"
            label="Type"
          />
        </Grid>
        {sampleType === 0 && (
          <Grid item sm={2}>
            <DropDownField
              options={UrineSampleTypeEnum}
              getOptionKey={(opt) => opt.key}
              getOptionLabel={(opt) => opt.label}
              control={control}
              name="urineType"
              label="Urine Type"
            />
          </Grid>
        )}
        {setOpen && (
        <Grid item sm={2}>
          <Button
            style={{margin: "30px"}}
            onClick={() => {
              setOpen(true);
            }}
          >
            Manage Tasks
          </Button>
        </Grid>)}
        <Grid item sm={6}>
          <NumberField
            control={control}
            name="quantity"
            label="Quantity"
            fullWidth
            required
            rules={{
              min: 0,
            }}
            helperText={
              formState.errors?.quantity?.type === "min" &&
              "Cannot be less than 0."
            }
          />
        </Grid>
        <Grid item sm={6}>
          <DropDownField
            control={control}
            options={clotTimeOptions}
            name="processingRequirements.clotTime"
            label="Clot Time"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={6}>
          <NumberField
            control={control}
            name="transferTubeQuantity"
            label="Transfer Tube Quantity"
            fullWidth
            required
            rules={{
              min: 0,
            }}
            helperText={
              formState.errors?.transferTubeQuantity?.type === "min" &&
              "Cannot be less than 0."
            }
          />
        </Grid>
        <Grid item sm={6}>
          <NumberField
            control={control}
            name="aliquotQuantity"
            label="Aliquot Quantity"
            fullWidth
            rules={{
              min: 0,
            }}
            helperText={
              formState.errors?.aliquotQuantity?.type === "min" &&
              "Cannot be less than 0."
            }
          />
        </Grid>
        <Grid item sm={6}>
          <DropDownField
            control={control}
            options={timeOptions}
            name="storageTime"
            label="Storage Time"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            required
          />
        </Grid>
        <Grid item sm={6}>
          <NumberField
            control={control}
            name="storageTemperature"
            label="Storage Temperature (C)"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={6}>
          <DropDownField
            control={control}
            options={timeOptions}
            name="aliquotTime"
            label="Aliquot Time"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="specialInstructions"
            label="Special Instructions"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="shipInstructions"
            label="Shipping Instructions"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <DropDownField
            control={control}
            options={ShippingTemperatureEnum}
            name="shippingInformation.temperature"
            label="Shipping Temperature"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={3}>
          <DropDownField
            control={control}
            options={ShippingFrequencyEnum}
            name="shippingInformation.frequency"
            label="Shipping Frequency"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={3}>
          <DropDownField
            control={control}
            options={centrifugationTimeOptions}
            name="processingRequirements.centrifugeTime"
            label="Centrifugation Time"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={3}>
          <NumberField
            control={control}
            name="processingRequirements.centrifugeSpeed"
            label="Centrifugation Speed (Xg)"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <NumberField
            control={control}
            name="processingRequirements.processingTemp"
            label="Centrifugation Temperature"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <DropDownField
            control={control}
            options={tempUnits}
            name="processingRequirements.processingTempUnit"
            label="Centrifugation Temp Unit"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item sm={6}>
          <DateField
            control={control}
            name="urineProcessingRequirements.expirationDate"
            label="Expiration Date"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="urineProcessingRequirements.kitName"
            label="Kit Name"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="urineProcessingRequirements.lotNumber"
            label="Lot #"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="urineProcessingRequirements.processingType"
            label="Processing Type"
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <DropDownField
            control={control}
            options={timeOptions}
            name="urineProcessingRequirements.readTime"
            label="Read Time"
            fullWidth
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent to={`/studies/${studyId}/samples`} />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(SampleForm);
