import { Tab, Tabs } from "@mui/material";
import { useParams } from "react-router";
import { Link, Outlet, useMatch } from "react-router-dom";

function StudyTabs() {
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const parentMatch = useMatch("/studies/:studyId/");
  const routeMatch = useMatch("/studies/:studyId/:pattern");
  const currentTab = parentMatch?.pathname ?? routeMatch?.pathname;
  const basePath = `/studies/${studyId}`;

  return (
    <>
      <Tabs
        value={currentTab}
        sx={{
          backgroundColor: "gray.light",
          "& .Mui-selected": { backgroundColor: "gray.contrastText" },
        }}
      >
        <Tab label="Study" value={basePath} to={basePath} component={Link} />
        <Tab
          label="Sites"
          value={`${basePath}/sites`}
          to={`${basePath}/sites`}
          component={Link}
        />
        <Tab
          label="Patients"
          value={`${basePath}/patients`}
          to={`${basePath}/patients`}
          component={Link}
        />
        <Tab
          label="Visits"
          value={`${basePath}/visits`}
          to={`${basePath}/visits`}
          component={Link}
        />
        <Tab
          label="Samples"
          value={`${basePath}/samples`}
          to={`${basePath}/samples`}
          component={Link}
        />
        <Tab
          label="Imports"
          value={`${basePath}/imports`}
          to={`${basePath}/imports`}
          component={Link}
        />
      </Tabs>
      <Outlet />
    </>
  );
}

export default StudyTabs;
