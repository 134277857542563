import { useRemovePatient } from "api";
import { useSnackbar } from "notistack";
import DeleteButton from "shared/components/DeleteButton";

interface Props {
  studyId: number;
  patientId: number;
  onDelete: () => void;
}

function RemovePatient({ studyId, patientId, onDelete }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, error, ...status } = useRemovePatient({
    mutation: {},
  });

  return (
    <DeleteButton
      item="Patient"
      status={status}
      error={error}
      text="Remove"
      onConfirm={() => {
        mutate(
          {
            patientId,
            studyId,
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Patient is removed", {
                variant: "success",
              });
              onDelete();
            },
          }
        );
      }}
    />
  );
}

export default RemovePatient;
