import { useDeleteVisit } from "api";
import { useSnackbar } from "notistack";
import DeleteButton from "shared/components/DeleteButton";

interface Props {
  visitId: number;
  studyId: number;
  onDelete?: () => void;
}

function RemoveVisit({ visitId, studyId, onDelete }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, error, ...status } = useDeleteVisit({
    mutation: {},
  });

  return (
    <DeleteButton
      item="Visit"
      status={status}
      error={error}
      text="Remove"
      onConfirm={() => {
        mutate(
          {
            visitId,
            studyId,
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Visit is removed", {
                variant: "success",
              });
              if (onDelete) onDelete();
            },
          }
        );
      }}
    />
  );
}

export default RemoveVisit;
