import React from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  ButtonProps,
} from "@mui/material";
import StyledDialog from "./StyledDialog";
import { FormStatus } from "../forms/FormStatusProvider";
import { getError } from "shared";
import ErrorDisplay from "./ErrorDisplay";

export interface Props extends Omit<ButtonProps, "action" | "onClick"> {
  item: string;
  status: FormStatus;
  onConfirm: () => void;
  error?: unknown;
  text?: string;
}

function DeleteButton({
  item,
  status,
  onConfirm,
  error,
  text = "Delete",
  ...props
}: Props) {
  const [open, setOpen] = React.useState(false);

  const confirmDialog = (
    <StyledDialog
      maxWidth="sm"
      open={open}
      title={`Delete ${item}`}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        {error && <ErrorDisplay message={getError(error).message} />}
        Are you sure you want to delete {item}?
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
  const resultDialog = (
    <StyledDialog
      maxWidth="sm"
      open={open}
      title={`Delete ${item}`}
      onClose={() => setOpen(false)}
    >
      <DialogContent>{item} successfully deleted!</DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );

  const dialogState = status.isSuccess === true ? resultDialog : confirmDialog;

  return (
    <>
      <Button
        sx={{ margin: "0 .25rem" }}
        type="button"
        onClick={() => {
          setOpen(true);
        }}
        {...props}
      >
        {text}
      </Button>
      {dialogState}
    </>
  );
}

export default DeleteButton;
