/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  SitesGetAllSitesResult,
  GetAllSitesParams,
  SitesAddSiteResult,
  SitesAddSiteForm,
  SitesGetSiteByIdResult,
  SitesUpdateSiteResult,
  SitesUpdateSiteForm,
  SitesDeleteSiteResult,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllSites = (
  params?: GetAllSitesParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SitesGetAllSitesResult>(
    { url: `/api/Site`, method: "get", params },
    options
  );
};

export const getGetAllSitesQueryKey = (params?: GetAllSitesParams) => [
  `/api/Site`,
  ...(params ? [params] : []),
];

export const useGetAllSites = <
  TData = AsyncReturnType<typeof getAllSites>,
  TError = unknown
>(
  params?: GetAllSitesParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getAllSites>, TError, TData>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllSitesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllSites>> = () =>
    getAllSites(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllSites>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addSite = (
  sitesAddSiteForm: SitesAddSiteForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SitesAddSiteResult>(
    { url: `/api/Site`, method: "post", data: sitesAddSiteForm },
    options
  );
};

export const useAddSite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addSite>,
    TError,
    { data: SitesAddSiteForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addSite>,
    { data: SitesAddSiteForm }
  > = (props) => {
    const { data } = props || {};

    return addSite(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addSite>,
    TError,
    { data: SitesAddSiteForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getSiteById = (
  siteId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SitesGetSiteByIdResult>(
    { url: `/api/Site/${siteId}`, method: "get" },
    options
  );
};

export const getGetSiteByIdQueryKey = (siteId: number) => [
  `/api/Site/${siteId}`,
];

export const useGetSiteById = <
  TData = AsyncReturnType<typeof getSiteById>,
  TError = unknown
>(
  siteId: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getSiteById>, TError, TData>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetSiteByIdQueryKey(siteId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getSiteById>> = () =>
    getSiteById(siteId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getSiteById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!siteId, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateSite = (
  siteId: number,
  sitesUpdateSiteForm: SitesUpdateSiteForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SitesUpdateSiteResult>(
    { url: `/api/Site/${siteId}`, method: "post", data: sitesUpdateSiteForm },
    options
  );
};

export const useUpdateSite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSite>,
    TError,
    { siteId: number; data: SitesUpdateSiteForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateSite>,
    { siteId: number; data: SitesUpdateSiteForm }
  > = (props) => {
    const { siteId, data } = props || {};

    return updateSite(siteId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateSite>,
    TError,
    { siteId: number; data: SitesUpdateSiteForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteSite = (
  siteId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SitesDeleteSiteResult>(
    { url: `/api/Site/${siteId}`, method: "delete" },
    options
  );
};

export const useDeleteSite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteSite>,
    TError,
    { siteId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteSite>,
    { siteId: number }
  > = (props) => {
    const { siteId } = props || {};

    return deleteSite(siteId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof deleteSite>,
    TError,
    { siteId: number },
    TContext
  >(mutationFn, mutationOptions);
};
