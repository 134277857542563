import { Grid, Box } from "@mui/material";
import { useGetStudyPatientById } from "api";
import { getStudyPatientStatus } from "shared";

interface Props {
  patientId: number;
  studyId: number;
  hidePatientInfo?: boolean;
}

function PatientInformation({
  patientId,
  studyId,
  hidePatientInfo = false,
}: Props) {
  const { data } = useGetStudyPatientById(patientId, studyId);

  if (!data) return null;

  return (
    <Grid
      container
      sx={{
        paddingBottom: 3,
        "& .MuiGrid-item": {
          padding: ".25rem",
          display: "flex",

          "& .MuiBox-root": {
            padding: ".25rem .5rem",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "grey.100",
            borderRadius: ".25rem",
            width: "100%",
            alignItems: "center",
          },
        },
      }}
    >
      <Grid item sm={2} xs={4}>
        <Box>
          <strong>Study Name: </strong>
          {data.studyPatient.studyName}
        </Box>
      </Grid>
      {!hidePatientInfo && (
        <>
          <Grid item sm={2} xs={4}>
            <Box>
              <strong>Name: </strong>
              {data.studyPatient.initials}
            </Box>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Box>
              <strong>DOB: </strong>
              {data.studyPatient.dob}
            </Box>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Box>
              <strong>ID: </strong>
              {data.studyPatient.referenceId}
            </Box>
          </Grid>
        </>
      )}
      <Grid item sm={2} xs={4}>
        <Box>
          <strong>Screening Number: </strong>
          {data.studyPatient.screeningNumber}
        </Box>
      </Grid>
      <Grid item sm={2} xs={4}>
        <Box>
          <strong>Status: </strong>
          {getStudyPatientStatus(data.studyPatient.studyPatientStatus)}
        </Box>
      </Grid>
    </Grid>
  );
}
export default PatientInformation;
