import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  PatientsGetPatientByIdPatientModel,
  PatientsUpdatePatientForm,
} from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";
import DeletePatientForm from "./DeletePatientForm";
import DropDownField from "shared/forms/fields/DropDownField";
import DateField from "shared/forms/fields/DateField";
import { GenderEnum } from "shared";

interface Props {
  patient: PatientsGetPatientByIdPatientModel;
  onSave(form: PatientsUpdatePatientForm): void;
}

function EditPatientForm({ patient, onSave }: Props) {
  const { control, handleSubmit, formState } =
    useForm<PatientsUpdatePatientForm>({
      defaultValues: patient,
      mode: "onChange",
    });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <TextField
            control={control}
            name="initials"
            label="Patient Initials"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            control={control}
            name="referenceId"
            label="Patient Id"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DropDownField
            options={GenderEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="gender"
            label="Gender"
          />
        </Grid>
        <Grid item xs={6}>
          <DateField control={control} name="dob" label="DOB" />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent to="/patients" />
        <DeletePatientForm patient={patient} />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditPatientForm);
