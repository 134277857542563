import { Box, Grid, TextField } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  AuditArea,
  AuditGetAuditLogLogModel,
  AuditGetAuditLogResult,
  SampleType,
  SamplesGetLabTasksLabTaskModel,
  useGetAuditLogs,
} from "api";
import { AuditAreaEnum, useDataGrid } from "../../shared";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import {
  isValid,
  parseISO,
  formatISO,
  startOfMonth,
  startOfToday,
} from "date-fns";
import DropDownField from "shared/forms/fields/DropDownField";
import DateField from "shared/forms/fields/DateField";
import { getSampleType, SampleTypeEnum } from "shared/models/sample-type.enum";

function getColumns(area: AuditArea | undefined): GridColumns {
  let columns: GridColumns = [
    {
      field: "timestamp",
      headerName: "Timestamp",
      flex: 2,
      renderCell: ({ row }) => (
        <DateTimeDisplay
          date={parseISO(row.timestamp)}
          dateFormat="MM/dd/yyyy hh:mm a"
        />
      ),
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      valueGetter: ({ row }) => row.username,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      valueGetter: ({ row }) => row.action,
    },
    {
      field: "studyId",
      headerName: "Study",
      flex: 1,
      valueGetter: ({ row }) => row.studyName,
    },
    {
      field: "patientId",
      headerName: "Patient",
      flex: 1,
      valueGetter: ({ row }) => row.patientName,
    },
    {
      field: "visitId",
      headerName: "Visit",
      flex: 1,
      valueGetter: ({ row }) => row.visitName,
    },
  ];
  if (area === 3) {
    columns.push({
      field: "labTaskId",
      headerName: "Lab Task",
      flex: 1,
      valueGetter: ({ row }) => row.labTaskName,
    });
  }
  if (area === 2 || area === 3) {
    columns.push({
      field: "sampleType",
      headerName: "Sample Type",
      flex: 1,
      valueGetter: ({ row }) => {
        return getSampleType(SampleTypeEnum, row.sampleType);
      },
    });
  }
  columns.push({
    field: "fieldName",
    headerName: "Field",
    flex: 1,
    valueGetter: ({ row }) => row.displayName,
  });
  columns.push({
    field: "originalValue",
    headerName: "Original",
    flex: 2,
    renderCell: ({ row }) => {
      if (isValid(parseISO(row.originalValue))) {
        return (
          <DateTimeDisplay
            date={parseISO(row.originalValue)}
            dateFormat="MM/dd/yyyy hh:mm a"
          />
        );
      }
      return row.originalValue;
    },
  });
  columns.push({
    field: "newValue",
    headerName: "New",
    flex: 2,
    renderCell: ({ row }) => {
      if (isValid(parseISO(row.newValue))) {
        return (
          <DateTimeDisplay
            date={parseISO(row.newValue)}
            dateFormat="MM/dd/yyyy hh:mm a"
          />
        );
      }
      return row.newValue;
    },
  });
  return columns;
}

interface Props {
  labTasks: SamplesGetLabTasksLabTaskModel[];
}

function AuditLogListContainer({ labTasks }: Props) {
  const [auditLogs, setAuditLogs] = useState<Array<AuditGetAuditLogLogModel>>(
    []
  );
  const { control, register, getValues, watch, setValue } = useForm<{
    area?: AuditArea;
    start?: string;
    end?: string;
    username?: string;
    studyName?: string;
    patientInitials?: string;
    visitReferenceId?: string;
    labTaskId?: number;
    sampleType?: SampleType;
  }>({
    defaultValues: {
      studyName: "",
      patientInitials: "",
      visitReferenceId: "",
      area: 0,
      start: formatISO(startOfMonth(new Date()), {
        representation: "date",
      }),
      end: formatISO(startOfToday(), {
        representation: "date",
      }),
    },
  });

  const {
    area,
    patientInitials,
    studyName,
    visitReferenceId,
    username,
    labTaskId,
    sampleType,
    start,
    end,
  } = watch();
  const { grid } = useDataGrid<{
    area?: AuditArea;
    start?: string;
    end?: string;
    username?: string;
    studyName?: string;
    patientInitials?: string;
    visitReferenceId?: string;
    labTaskId?: number;
    sampleType?: SampleType;
  }>("audit", getColumns(area));

  const { refetch } = useGetAuditLogs(
    {
      patientInitials: getValues("patientInitials"),
      studyName: getValues("studyName"),
      visitReferenceId: getValues("visitReferenceId"),
      area: getValues("area"),
      username: getValues("username"),
      start: getValues("start"),
      end: getValues("end"),
      labTaskId: getValues("labTaskId"),
      sampleType: getValues("sampleType"),
    },
    {
      query: {
        onSuccess: (results: AuditGetAuditLogResult) => {
          setAuditLogs(results.items);
        },
        retryOnMount: false,
        refetchOnWindowFocus: false,
      },
    }
  );

  useEffect(() => {
    if (area !== 3) {
      setValue("labTaskId", undefined);
    }
    refetch({
      cancelRefetch:
        !area ||
        !patientInitials ||
        !studyName ||
        !visitReferenceId ||
        !username ||
        !start ||
        !end ||
        !labTaskId ||
        !sampleType,
    });
  }, [
    area,
    patientInitials,
    studyName,
    visitReferenceId,
    username,
    start,
    end,
    labTaskId,
    sampleType,
    refetch,
    setValue,
  ]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <DateField control={control} name="start" label="Start" />
        </Grid>
        <Grid item xs={3}>
          <DateField control={control} name="end" label="End" />
        </Grid>
        <Grid item xs={3}>
          <DropDownField
            options={AuditAreaEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="area"
            label="Area"
            disableClearable
          />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Username" {...register("username")} />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Study" {...register("studyName")} />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Patient" {...register("patientInitials")} />
        </Grid>
        <Grid item xs={3}>
          <TextField label="Visit" {...register("visitReferenceId")} />
        </Grid>
        {(area === 2 || area === 3) && (
          <Grid item xs={3}>
            <DropDownField
              options={SampleTypeEnum}
              getOptionKey={(opt) => opt.key}
              getOptionLabel={(opt) => opt.label}
              control={control}
              name="sampleType"
              label="Type"
            />
          </Grid>
        )}
        {area === 3 && (
          <>
            <Grid item xs={3}>
              <DropDownField
                options={labTasks}
                getOptionKey={(opt) => opt.id}
                getOptionLabel={(opt) => opt.name}
                control={control}
                name="labTaskId"
                label="Lab Task"
              />
            </Grid>
          </>
        )}
      </Grid>
      <DataGrid
        {...grid}
        columns={getColumns(area)}
        rows={auditLogs}
        rowCount={auditLogs.length}
        autoHeight
        hideFooter={true}
        getRowId={(row) => `${row.id}-${row.fieldName}`}
      />
    </Box>
  );
}
export default AuditLogListContainer;
