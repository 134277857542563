import { useGetStudyById } from "api";
import { useParams } from "react-router";

import AssignedPatientList from "../components/AssignedPatientList";

function AssignedPatientListPage() {
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const { data } = useGetStudyById(studyId);
  if (!data?.study) return null;
  return <AssignedPatientList study={data?.study} />;
}

export default AssignedPatientListPage;
