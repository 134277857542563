import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { SitesGetSiteByIdSiteModel, SitesUpdateSiteForm } from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import PhoneField from "shared/forms/fields/PhoneField";
import TextField from "shared/forms/fields/TextField";
import DropDownField from "shared/forms/fields/DropDownField";
import USStates from "shared/USStates.json";
import StickyFooter from "shared/layout/StickyFooter";
import DeleteSiteForm from "./DeleteSiteForm";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";

interface Props {
  site: SitesGetSiteByIdSiteModel;
  onSave(form: SitesUpdateSiteForm): void;
}

function EditSiteForm({ site, onSave }: Props) {
  const { control, handleSubmit, formState } = useForm<SitesUpdateSiteForm>({
    defaultValues: site,
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <TextField
            control={control}
            name="name"
            label="Site Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="address.line1"
            label="Site Address Line 1"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="address.line2"
            label="Site Address Line 2"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="address.city"
            label="City"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <DropDownField
            options={USStates}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.key}
            control={control}
            name="address.state"
            label="State"
            required
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            control={control}
            name="address.zip"
            label="Zip"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="contact.email"
            label="Contact Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneField
            control={control}
            name="contact.phone"
            label="Contact Phone"
            fullWidth
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent to="/sites" />
        <DeleteSiteForm site={site} />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditSiteForm);
