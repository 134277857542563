import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { parseISO } from "date-fns";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import { getPatientSampleStatus } from "shared/models/patient-sample-status.enum";
import {
  getSampleType,
  SampleTypeEnum,
  ShippingFrequencyEnum,
  UrineSampleTypeEnum,
} from "shared/models/sample-type.enum";

import { useGetAllUsers, useGetLabTechnicianPatientSampleDetails } from "../../api";

interface VisitDetailsProps {
  sampleId: number;
  patientId: number;
}

function SampleDetails({ sampleId, patientId }: VisitDetailsProps) {
  const { data } = useGetLabTechnicianPatientSampleDetails(patientId, sampleId);
  
  const users = useGetAllUsers({
    userTypes: ["LabTechnician", "Admin"],
    pageSize: 0,
  });
  
  if (!data || !users.data?.items) return null;

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardHeader title="Sample Info" />
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box pl={3}>
              <Grid container spacing={6}>
                {data.patientSample.sampleType >= 0 && (
                  <Grid item sm={6} xs={6}>
                    <strong>Sample Type: </strong>
                    {getSampleType(
                      SampleTypeEnum,
                      data.patientSample.sampleType
                    )}
                  </Grid>
                )}
                {data.patientSample.sampleType === 0 &&
                  data.patientSample.urineType >= 0 && (
                    <Grid item sm={6} xs={6}>
                      <strong>Urine Type: </strong>
                      {getSampleType(
                        UrineSampleTypeEnum,
                        data.patientSample.urineType
                      )}
                    </Grid>
                  )}
                {!!data.patientSample.quantity && (
                  <Grid item sm={6} xs={6}>
                    <strong>Quantity: </strong>
                    {data.patientSample.quantity}
                  </Grid>
                )}
                {!!data.patientSample.transferTubeQuantity && (
                  <Grid item sm={6} xs={6}>
                    <strong>Transfer Tube Quantity: </strong>
                    {data.patientSample.transferTubeQuantity}
                  </Grid>
                )}
                {!!data.patientSample.aliquotTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Aliquot Time: </strong>
                    {data.patientSample.aliquotTime}
                  </Grid>
                )}
                {!!data.patientSample.aliquotQuantity && (
                  <Grid item sm={6} xs={6}>
                    <strong>Aliquot Quantity: </strong>
                    {data.patientSample.aliquotQuantity}
                  </Grid>
                )}
                {!!data.patientSample.storageTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Storage Time: </strong>
                    {data.patientSample.storageTime}
                  </Grid>
                )}
                {!!data.patientSample.storageTemperature && (
                  <Grid item sm={6} xs={6}>
                    <strong>Storage Temperature (C): </strong>
                    {data.patientSample.storageTemperature}
                  </Grid>
                )}
                {!!data.patientSample.clotTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Clot Time: </strong>
                    {data.patientSample.clotTime}
                  </Grid>
                )}
                {!!data.patientSample.status && (
                  <Grid item sm={6} xs={6}>
                    <strong>Status: </strong>
                    {getPatientSampleStatus(data.patientSample.status)}
                  </Grid>
                )}
                {!!data.patientSample.kitName && (
                  <Grid item sm={6} xs={6}>
                    <strong>Kit Name: </strong>
                    {data.patientSample.kitName}
                  </Grid>
                )}
                {!!data.patientSample.isFasting && (
                  <Grid item sm={6} xs={6}>
                    <strong>Is Fasting: </strong>
                    {data.patientSample.isFasting ? "Yes" : "No"}
                  </Grid>
                )}
                {!!data.patientSample.drawTimeCapturedBy && (
                  <Grid item sm={6} xs={6}>
                    <strong>Last collection time captured by: </strong>
                    {data.patientSample.drawTimeCapturedBy}
                  </Grid>
                )}
                {!!data.patientSample.drawTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Draw Time: </strong>
                    <DateTimeDisplay
                      date={parseISO(data.patientSample.drawTime)}
                      dateFormat="MM/dd/yyyy hh:mm a"
                    />
                  </Grid>
                )}
                {!!data.patientSample.processingRequirements.centrifugeTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Centrifugation Time: </strong>
                    {data.patientSample.processingRequirements.centrifugeTime}
                  </Grid>
                )}
                {!!data.patientSample.processingRequirements
                  .centrifugeSpeed && (
                  <Grid item sm={6} xs={6}>
                    <strong>Centrifugation Speed (Xg): </strong>
                    {data.patientSample.processingRequirements.centrifugeSpeed}
                  </Grid>
                )}
                {!!data.patientSample.processingRequirements.processingTemp && (
                  <Grid item sm={6} xs={6}>
                    <strong>Centrifugation Temperature: </strong>
                    {data.patientSample.processingRequirements.processingTemp}
                  </Grid>
                )}
                {!!data.patientSample.processingRequirements
                  .processingTempUnit && (
                  <Grid item sm={6} xs={6}>
                    <strong>Centrifugation Temperature Unit: </strong>
                    {
                      data.patientSample.processingRequirements
                        .processingTempUnit
                    }
                  </Grid>
                )}
                {!!data.patientSample.urineProcessingRequirements
                  .expirationDate && (
                  <Grid item sm={6} xs={6}>
                    <strong>Urine Processing Expiration Date: </strong>
                    <DateTimeDisplay
                      date={parseISO(
                        data.patientSample.urineProcessingRequirements
                          .expirationDate
                      )}
                      dateFormat="MM/dd/yyyy"
                    />
                  </Grid>
                )}
                {!!data.patientSample.urineProcessingRequirements.kitName && (
                  <Grid item sm={6} xs={6}>
                    <strong>Urine Processing Kit Name: </strong>
                    {data.patientSample.urineProcessingRequirements.kitName}
                  </Grid>
                )}
                {!!data.patientSample.urineProcessingRequirements.lotNumber && (
                  <Grid item sm={6} xs={6}>
                    <strong>Urine Processing Lot Number: </strong>
                    {data.patientSample.urineProcessingRequirements.lotNumber}
                  </Grid>
                )}
                {!!data.patientSample.urineProcessingRequirements
                  .processingType && (
                  <Grid item sm={6} xs={6}>
                    <strong>Urine Processing Type: </strong>
                    {
                      data.patientSample.urineProcessingRequirements
                        .processingType
                    }
                  </Grid>
                )}
                {!!data.patientSample.urineProcessingRequirements.readTime && (
                  <Grid item sm={6} xs={6}>
                    <strong>Urine Processing Read Time: </strong>
                    {data.patientSample.urineProcessingRequirements.readTime}
                  </Grid>
                )}
                {!!data.patientSample.shippingInformation.frequency && (
                  <Grid item sm={6} xs={6}>
                    <strong>Shipping Frequency: </strong>
                    {getSampleType(
                      ShippingFrequencyEnum,
                      data.patientSample.shippingInformation.frequency
                    )}
                  </Grid>
                )}
                {!!data.patientSample.shipInstructions && (
                  <Grid item sm={12} xs={12}>
                    <strong>Ship Instructions: </strong>
                    {data.patientSample.shipInstructions}
                  </Grid>
                )}
                {!!data.patientSample.specialInstructions && (
                  <Grid item sm={12} xs={12}>
                    <strong>Special Instructions: </strong>
                    {data.patientSample.specialInstructions}
                  </Grid>
                )}
                {!!data.patientSample.comments && (
                  <Grid item sm={12} xs={12}>
                    <strong>Comments: </strong>
                    {data.patientSample.comments}
                  </Grid>
                )}
                {!!data.patientSample.collectedQuantity && (
                  <Grid item sm={12} xs={12}>
                    <strong>Collected Quantity: </strong>
                    {data.patientSample.collectedQuantity}
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          
        </Box>
      </CardContent>
    </Card>
  );
}

export default SampleDetails;
