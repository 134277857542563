import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { ImportGetImportHistoryDetailsImportStudyVisitModel } from "api";
import { useDataGrid } from "shared";

const columns: GridColumns = [
  {
    field: "ccId",
    headerName: "CC Id",
    flex: 1,
    valueGetter: ({ row }) => row.ccId,
  },
  {
    field: "name",
    headerName: "Visit Name",
    flex: 1,
    valueGetter: ({ row }) => row.name,
  },
];

interface Props {
  studyVisits: ImportGetImportHistoryDetailsImportStudyVisitModel[];
}

function ImportVisitList({ studyVisits }: Props) {
  const { grid } = useDataGrid("import-visits", columns);

  return (
    <DataGrid
      {...grid}
      rows={studyVisits}
      rowCount={studyVisits.length}
      autoHeight
      hideFooter={true}
      getRowId={(row) => row.ccId}
    />
  );
}

export default ImportVisitList;
