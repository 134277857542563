import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import TimeoutOverlay from "shared/components/TimeoutOverlay";

import { icons } from "../../theme";
import ButtonComponent from "../components/Button";
import Header from "./Header";

function Shell() {
  return (
    <>
      <TimeoutOverlay/>
      <Stack m={0} p={0} sx={{ marginBottom: "5rem" }} width={"100%"}>
        <Header>
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <ButtonComponent>
              <Typography sx={{ fontWeight: "700" }}>Home</Typography>
            </ButtonComponent>
          </NavLink>
          <NavLink to="/account/signout" style={{ textDecoration: "none" }}>
            <ButtonComponent>
              <Typography sx={{ fontWeight: "700" }}>Sign Out</Typography>
            </ButtonComponent>
          </NavLink>
          <ButtonComponent>
            <FontAwesomeIcon icon={icons.user}></FontAwesomeIcon>
          </ButtonComponent>
        </Header>
        <Outlet />
      </Stack>
    </>
    
  );
}
export default Shell;
