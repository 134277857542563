import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import AddPatientForm from "patients/forms/AddPatientForm";
import { useNavigate } from "react-router-dom";

import { PatientsAddPatientResult, useAddPatient } from "../../api";

function AddPatientPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, ...status } = useAddPatient({
    mutation: {
      onSuccess(data: PatientsAddPatientResult) {
        enqueueSnackbar("Patient Added", {
          variant: "success",
        });
        navigate(`/patients`);
      },
    },
  });

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Patient" />

      <CardContent>
        <AddPatientForm
          onSave={(form) => mutate({ data: form })}
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default AddPatientPage;
