import { Button, Grid, DialogContent, Box, Checkbox } from "@mui/material";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import StyledDialog from "shared/components/StyledDialog";
import {
  useGetCurrentUser,
  AccountGetCurentUserUserModel,
  useGetAllUsers,
  useGetLabTasks,
  SamplesUpdateSampleTasksForm,
  useUpdateSampleTasks,
  useGetTasksForSample,
  SamplesUpdateSampleTasksSampleTaskRecord,
} from "api";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDataGrid } from "shared";

interface Props {
  sampleId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function SampleTaskModalContainer({ sampleId, open, setOpen }: Props) {
  const { mutate } = useUpdateSampleTasks();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetCurrentUser();
  const currentSampleTasks = useGetTasksForSample(sampleId);

  if (!data?.user) return null;

  return (
    <StyledDialog
      maxWidth="sm"
      open={open}
      title={`Manage Sample Tasks`}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <SampleTaskModalContent
          currentTasks={
            currentSampleTasks.data?.labTasks.map((x) => {
              return x.id;
            }) ?? []
          }
          sampleId={sampleId}
          currentUser={data.user}
          onUpdate={(form) => {
            mutate(
              {
                sampleId,
                data: {
                  labTasks: form.labTasks,
                },
              },
              {
                onSuccess: () => {
                  currentSampleTasks.refetch();
                  enqueueSnackbar("Tasks Updated", {
                    variant: "success",
                  });
                },
                onError: () => {
                  enqueueSnackbar("There was error while removing a task", {
                    variant: "error",
                  });
                },
              }
            );
          }}
          onCancel={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </StyledDialog>
  );
}

interface SampleTaskModalContentProps {
  currentTasks: number[];
  sampleId: number;
  currentUser: AccountGetCurentUserUserModel;
  onUpdate: (form: SamplesUpdateSampleTasksForm) => void;
  onCancel: () => void;
}
function SampleTaskModalContent({
  currentTasks,
  sampleId,
  currentUser,
  onUpdate,
  onCancel,
}: SampleTaskModalContentProps) {
  const { handleSubmit } = useForm<SamplesUpdateSampleTasksForm>({
    defaultValues: {
      labTasks: new Array<SamplesUpdateSampleTasksSampleTaskRecord>(),
    },
  });

  const sampleTasks = useGetLabTasks();

  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] =
    useState<Array<number>>(currentTasks);

  function selectRow(taskId: number) {
    if (selectedTaskIds.some((s) => s === taskId))
      setSelectedTaskIds(selectedTaskIds.filter((s) => s !== taskId));
    else setSelectedTaskIds([...selectedTaskIds, taskId]);
  }

  function selectAll() {
    if (allRowsSelected) {
      setSelectedTaskIds([]);
    } else {
      if (sampleTasks.data)
        setSelectedTaskIds(sampleTasks.data?.labTasks.map((m) => m.id));
    }
    setAllRowsSelected(!allRowsSelected);
  }
  const { grid } = useDataGrid("visit-tasks", [
    {
      field: "id",
      headerName: "Select",
      renderHeader: () => (
        <>
          <Checkbox checked={allRowsSelected} onChange={() => selectAll()} />
          Select All
        </>
      ),
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Checkbox
            checked={selectedTaskIds.some((s) => s === row.id)}
            onChange={() => selectRow(row.id)}
          />
          {row.name}
        </>
      ),
    },
  ]);

  const users = useGetAllUsers({
    userTypes: ["LabTechnician", "Admin"],
    pageSize: 0,
  });

  const onSubmit = handleSubmit((form) => {
    onUpdate({
      labTasks: selectedTaskIds.map((x) => {
        return {
          sampleId: sampleId,
          labTaskId: x,
        };
      }),
    });
  });

  if (!users.data?.items) return null;

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container>
        <DataGrid
          {...grid}
          rows={sampleTasks.data?.labTasks ?? []}
          hideFooter={true}
          autoHeight={true}
        />
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ paddingTop: ".5rem" }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginRight: ".5rem" }}
        >
          Save
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={() => onCancel()}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
}

export default SampleTaskModalContainer;
