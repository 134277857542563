import React from "react";
import { useParams } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { useGetAllVisits } from "../../api";
import { Link as RouterLink } from "react-router-dom";
import RemoveVisit from "./RemoveVisit";
import AddVisit from "./AddVisit";

interface Filters {
  searchQuery?: string;
}

function AssignedVisitList() {
  const [open, setOpen] = React.useState(false);
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const { grid, query, updateSearchQuery } = useDataGrid<Filters>("visits", [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/studies/${studyId}/visits/${row.id}`}
        >
          {row.name}
        </Link>
      ),
    },
    {
      field: "sequence",
      headerName: "Sequence",
      flex: 1,
      renderCell: ({ row }) => <>{row.sequence}</>,
    },
    {
      field: "referenceId",
      headerName: "Visit Id",
      flex: 1,
      renderCell: ({ row }) => <>{row.referenceId}</>,
    },
    {
      field: "remove",
      headerName: "Remove",
      flex: 1,
      renderCell: ({ row }) => (
        <RemoveVisit
          studyId={studyId}
          visitId={row.id}
          onDelete={() => {
            refetch();
          }}
        />
      ),
    },
  ]);

  const { data, isFetching, refetch } = useGetAllVisits(
    {
      ...query,
      studyId: studyId,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  if (!data?.items) return null;

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data.items}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: (x) => {
                    return (
                      <DataGridToolbar
                        addButtonLabel="Add Visit"
                        header={"Visit List"}
                        onAdd={() => {
                          setOpen(true);
                        }}
                      />
                    );
                  },
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
          <AddVisit
            studyId={studyId}
            open={open}
            onClose={() => {
              setOpen(false);
              refetch();
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default AssignedVisitList;
