import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";
import { makeStyles } from "@mui/styles";
import RequiredMarker from "shared/components/RequiredMarker";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiBox-root": {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        order: 999,
      },
    },
  },
});

type TextFieldProps<T> = FormTextFieldProps<T>;

function TextField<T>({
  type,
  name,
  control,
  className,
  rules,
  readOnly = false,
  label,
  required = false,
  ...props
}: TextFieldProps<T>) {
  const classes = useStyles();
  const { getError } = useFormErrors();
  rules = { ...rules, required };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <MuiTextField
          type={type}
          {...props}
          {...getError(name)}
          className={clsx(
            className,
            classes.root,
            readOnly && "textfield-readonly"
          )}
          value={value ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          InputProps={{ readOnly }}
          label={
            <>
              {label}
              <RequiredMarker required={required} />
            </>
          }
        />
      )}
    />
  );
}

export default asVariants(TextField);
