import { Card, CardContent, CardHeader } from "@mui/material";
import {
  NurseAddStudyPatientResult,
  useAddStudyPatient,
  useGetAllStudies,
} from "api";
import { useSnackbar } from "notistack";
import AddStudyPatientForm from "nurses/forms/AddStudyPatientForm";
import { useNavigate } from "react-router-dom";

function AddStudyPatientPage() {
  const { data } = useGetAllStudies({
    pageSize: 100,
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, ...status } = useAddStudyPatient({
    mutation: {
      onSuccess(_: NurseAddStudyPatientResult) {
        enqueueSnackbar("Patient Added", {
          variant: "success",
        });
        navigate(`../patients/lookup`);
      },
    },
  });

  if (!data?.items) return null;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Patient" />

      <CardContent>
        <AddStudyPatientForm
          onSave={(form) => mutate({ data: form })}
          status={status}
          studies={data?.items}
        />
      </CardContent>
    </Card>
  );
}
export default AddStudyPatientPage;
