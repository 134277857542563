import { NavLink } from "react-router-dom";
import ErrorDisplay from "shared/components/ErrorDisplay";

function UnauthorizedPage() {
  return (
    <ErrorDisplay message="You are not authorized to access this page.">
      Unauthorized Access &bull;{" "}
      <NavLink to="/account/signout">Sign Out</NavLink>
    </ErrorDisplay>
  );
}

export default UnauthorizedPage;
