import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  NurseGetPatientSamplesPatientSampleModel,
  NurseGetPatientVisitsPatientVisitModel,
  useGetPatientSamplesByVisitId,
} from "api";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useDataGrid } from "shared";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import { getPatientSampleStatus } from "shared/models/patient-sample-status.enum";
import { getSampleType, SampleTypeEnum } from "shared/models/sample-type.enum";

interface BaseProps {
  onSampleSelect: (sampleId: number) => void;
  onVisitChange: (visitId: number) => void;
  studyVisits: NurseGetPatientVisitsPatientVisitModel[];
  selectedVisitId: number;
  onCaptureBatchDraw(sampleIds: number[]): void;
}

interface PatientSampleListProps extends BaseProps {
  patientSamples: NurseGetPatientSamplesPatientSampleModel[];
  isFetching: boolean;
}

function PatientSampleList({
  studyVisits,
  selectedVisitId,
  patientSamples,
  onSampleSelect,
  onVisitChange,
  isFetching,
  onCaptureBatchDraw,
}: PatientSampleListProps) {
  const [selectedSampleIds, setSelectedSampleIds] = useState<Array<number>>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);

  function selectRow(sampleId: number) {
    if (selectedSampleIds.some((s) => s === sampleId))
      setSelectedSampleIds(selectedSampleIds.filter((s) => s !== sampleId));
    else setSelectedSampleIds([...selectedSampleIds, sampleId]);
  }

  function selectAll() {
    if (allRowsSelected) {
      setSelectedSampleIds([]);
    } else {
      setSelectedSampleIds(patientSamples.map((x) => x.id));
    }
    setAllRowsSelected(!allRowsSelected);
  }

  const { grid } = useDataGrid("patients-visit-samples", [
    {
      field: "capture",
      headerName: "Select",
      renderHeader: () => (
        <Checkbox checked={allRowsSelected} onChange={() => selectAll()} />
      ),
      flex: 1,
      renderCell: ({ row }) => (
        <Checkbox
          checked={selectedSampleIds.some((s) => s === row.id)}
          onChange={() => selectRow(row.id)}
        />
      ),
    },
    {
      field: "sampleType",
      headerName: "Sample Type",
      flex: 1,
      valueGetter: ({ row }) => getSampleType(SampleTypeEnum, row.sampleType),
    },
    {
      field: "quantity",
      headerName: "Collected Quantity",
      flex: 1,
      valueGetter: ({ row }) => `${row.collectedQuantity}/${row.expectedQuantity}`,
    },
    {
      field: "transferTubeQuantity",
      headerName: "# of Tubes",
      flex: 1,
      valueGetter: ({ row }) => row.transferTubeQuantity ?? "--",
    },
    {
      field: "aliquotQuantity",
      headerName: "# of Aliquots",
      hideable: true,
      flex: 1,
      valueGetter: ({ row }) => row.aliquotQuantity ?? "--",
    },
    {
      field: "drawTime",
      headerName: "Draw Time",
      flex: 1,
      renderCell: ({ row }) => {
        if (!row.drawTime) return <>--</>;
        return (
          <DateTimeDisplay
            date={parseISO(row.drawTime)}
            dateFormat="MM/dd hh:mm a"
          />
        );
      },
    },
    {
      field: "drawTimeCapturedBy",
      headerName: "Draw Time Captured By",
      flex: 1,
      valueGetter: ({ row }) => row.drawTimeCapturedBy,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: ({ row }) => getPatientSampleStatus(row.status),
    },
  ]);

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Select
          fullWidth
          value={selectedVisitId}
          onChange={(e) => {
            onVisitChange(e.target.value as number);
          }}
        >
          {studyVisits.map((m) => (
            <MenuItem value={m.id} key={m.id}>
              {`${m.name} (${m.referenceId})`}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }} mb={2}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={patientSamples}
                rowCount={patientSamples.length}
                loading={isFetching}
                autoHeight
                onRowClick={(row) => {
                  onSampleSelect(Number(row.id));
                }}
                hideFooter={true}
              />
            </Box>
          </Box>
          <Button
            disabled={!selectedSampleIds.length}
            onClick={() => {
              onCaptureBatchDraw(selectedSampleIds);
              setSelectedSampleIds([]);
              setAllRowsSelected(false);
            }}
          >
            Capture Batch Collected
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

interface PatientSampleListContainerProps extends BaseProps {
  patientId: number;
  refetchSampleList: boolean;
  setRefetchSampleList: any;
}
function PatientSampleListContainer({
  patientId,
  selectedVisitId,
  onSampleSelect,
  onVisitChange,
  studyVisits,
  refetchSampleList,
  setRefetchSampleList,
  onCaptureBatchDraw,
}: PatientSampleListContainerProps) {
  const { data, isFetching, isRefetching, refetch } =
    useGetPatientSamplesByVisitId(patientId, selectedVisitId);

  useEffect(() => {
    if (!!refetchSampleList) refetch();
    setRefetchSampleList(false);
  }, [refetchSampleList, setRefetchSampleList, refetch]);

  if (!data?.patientSamples) return null;
  return (
    <>
      <PatientSampleList
        studyVisits={studyVisits}
        selectedVisitId={selectedVisitId}
        onVisitChange={(x) => onVisitChange(x)}
        patientSamples={data.patientSamples}
        onSampleSelect={(x) => {
          onSampleSelect(x);
        }}
        onCaptureBatchDraw={onCaptureBatchDraw}
        isFetching={isFetching || isRefetching}
      />
    </>
  );
}

export default PatientSampleListContainer;
