import { useForm } from "react-hook-form";
import { Box, DialogContent, Grid } from "@mui/material";
import { UsersSetUserPasswordForm, useSetUserPassword } from "api";
import SubmitButton from "shared/forms/SubmitButton";
import TextField from "shared/forms/fields/TextField";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import StyledDialog from "shared/components/StyledDialog";
import { useSnackbar } from "notistack";

interface Props {
  open: boolean;
  userId: number;
  onClose: () => void;
}

function SetUserPasswordForm({ open, userId, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, control, handleSubmit, formState, reset } =
    useForm<UsersSetUserPasswordForm>({
      mode: "onChange",
    });

  const { mutate } = useSetUserPassword({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Password Set", {
          variant: "success",
        });
        onClose();
        reset();
      },
      onError() {
        enqueueSnackbar("Failed to Set Password", {
          variant: "error",
        });
      },
    },
  });

  const onSubmit = handleSubmit((form) => {
    mutate({
      data: {
        password: form.password,
        confirmPassword: form.confirmPassword,
      },
      id: userId,
    });
  });

  return (
    <>
      {!open && <></>}
      {open && (
        <StyledDialog open={open} title={`Set Password`} onClose={onClose} maxWidth="sm">
          <DialogContent>
            <Box onSubmit={onSubmit} component="form">
              <FormErrorAlert />
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <TextField
                    control={control}
                    name="password"
                    label="Password"
                    fullWidth
                    rules={{ minLength: 8 }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    control={control}
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                    rules={{
                      validate: {
                        passwordEqual: (value: string) =>
                          value === getValues().password ||
                          "Passwords do not match",
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <SubmitButton variant="save" disabled={!formState.isValid} />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </StyledDialog>
      )}
    </>
  );
}

export default SetUserPasswordForm;
