import { Box, Card, CardContent } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { parseISO } from "date-fns";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import { getSampleType, SampleTypeEnum } from "shared/models/sample-type.enum";

import { LabTechnicianGetPatientSamplesPatientSampleModel } from "../../api";
import { useDataGrid } from "../../shared";

const columns = (): GridColumns => [
  {
    field: "sampleType",
    headerName: "Type",
    flex: 1,
    valueGetter: ({ row }) => getSampleType(SampleTypeEnum, row.sampleType),
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    valueGetter: ({ row }) => row.quantity,
  },
  {
    field: "transferTubeQuantity",
    headerName: "Transfer Tube Quantity",
    flex: 1,
    valueGetter: ({ row }) => row.transferTubeQuantity,
  },
  {
    field: "drawTime",
    headerName: "Draw Time",
    flex: 1,
    renderCell: ({ row }) =>
      row.drawTime && (
        <DateTimeDisplay
          date={parseISO(row.drawTime)}
          dateFormat="MM/dd/yyyy hh:mm a"
        />
      ),
  },
];

interface Filters {
  searchQuery?: string;
}

interface Props {
  samples: LabTechnicianGetPatientSamplesPatientSampleModel[];
  onSampleSelect: (sampleId: number) => void;
}

function SampleList({ samples, onSampleSelect }: Props) {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "samples",
    columns()
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={samples ?? []}
                rowCount={samples.length}
                onRowClick={(params) => onSampleSelect(params.row.id)}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SampleList;
