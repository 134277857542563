import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { getSampleType, SampleTypeEnum } from "shared/models/sample-type.enum";

import { StudiesGetStudyByIdStudyModel, useGetAllSamples } from "../../api";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";

const columns = (studyId: number): GridColumns => [
  {
    field: "visitName",
    headerName: "Visit",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${studyId}/samples/${row.id}`}>
        {`${row.visitName}`}
      </Link>
    ),
  },
  {
    field: "sampleType",
    headerName: "Type",
    flex: 1,
    valueGetter: ({ row }) => getSampleType(SampleTypeEnum, row.sampleType),
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    valueGetter: ({ row }) => row.quantity,
  },
  {
    field: "TransferTubeQuantity",
    headerName: "# of Tubes",
    flex: 1,
    valueGetter: ({ row }) => row.transferTubeQuantity ?? "--",
  },
  {
    field: "aliquotQuantity",
    headerName: "# of Aliquots",
    flex: 1,
    valueGetter: ({ row }) => row.aliquotQuantity ?? "--",
  },
  {
    field: "processingRequirements.clotTime",
    headerName: "Clot Time",
    flex: 1,
    valueGetter: ({ row }) => row.clotTime,
  },
  {
    field: "shipInstructions",
    headerName: "Ship Instructions",
    flex: 1,
    valueGetter: ({ row }) => row.shipInstructions,
  },
  {
    field: "specialInstructions",
    headerName: "Special Instruction",
    flex: 1,
    valueGetter: ({ row }) => row.specialInstructions,
  },
];

function Toolbar(
  study: StudiesGetStudyByIdStudyModel,
  props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">
) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"Sample List"}
      addButtonLabel="Add Sample"
      onAdd={() => navigate(`/studies/${study.id}/samples/add`)}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

interface Props {
  study: StudiesGetStudyByIdStudyModel;
}

function SampleList({ study }: Props) {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "samples",
    columns(study.id)
  );
  const { data, isFetching } = useGetAllSamples(
    {
      ...query,
      studyId: study.id,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: (x) => Toolbar(study, x),
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SampleList;
