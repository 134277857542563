/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type { AccountGetCurentUserResult } from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getCurrentUser = (
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<AccountGetCurentUserResult>(
    { url: `/api/Account/current`, method: "get" },
    options
  );
};

export const getGetCurrentUserQueryKey = () => [`/api/Account/current`];

export const useGetCurrentUser = <
  TData = AsyncReturnType<typeof getCurrentUser>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getCurrentUser>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getCurrentUser>> = () =>
    getCurrentUser(requestOptions);

  const query = useQuery<AsyncReturnType<typeof getCurrentUser>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};
