import { Tab, Tabs } from "@mui/material";
import { Link, Outlet, useMatch, useParams } from "react-router-dom";

function LabTabs() {
  const { studyId: studyIdParam, visitId: visitIdParam, patientId: patientIdParam } = 
      useParams<{ studyId: string, visitId: string, patientId: string }>();

  const studyId = Number(studyIdParam);
  const visitId = Number(visitIdParam);
  const patientId = Number(patientIdParam);
  
  const parentMatch = useMatch("/lab/studies/:studyId/visits/:visitId/patients/:patientId");
  const routeMatch = useMatch("/lab/studies/:studyId/visits/:visitId/patients/:patientId/:pattern");
  const currentTab = parentMatch?.pathname ?? routeMatch?.pathname;
  const basePath = `/lab/studies/${studyId}/visits/${visitId}/patients/${patientId}`;

  return (
    <>
      <Tabs
        value={currentTab}
        sx={{
          backgroundColor: "gray.light",
          "& .Mui-selected": { backgroundColor: "gray.contrastText" },
        }}
      >
        <Tab label="Visit" value={basePath} to={basePath} component={Link} />
        <Tab
          label="Samples"
          value={`${basePath}/samples`}
          to={`${basePath}/samples`}
          component={Link}
        />
      </Tabs>
      <Outlet />
    </>
  );
}

export default LabTabs;
