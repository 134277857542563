import { Autocomplete, TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps } from "./FieldVariants";
import { makeStyles } from "@mui/styles";
import RequiredMarker from "shared/components/RequiredMarker";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiBox-root": {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        order: 999,
      },
    },
  },
});

type DropDownFieldProps<T, TOptions> = FormTextFieldProps<
  T,
  {
    options: TOptions[];
    getOptionKey(option: TOptions): string | number;
    getOptionLabel(option: TOptions): string;
    disableClearable?: boolean;
  }
>;

function DropDownField<T, TOptions>({
  name,
  control,
  options,
  label,
  className,
  rules,
  readOnly = false,
  required = false,
  getOptionKey,
  getOptionLabel,
  disableClearable = false,
  placeholder,
  ...props
}: DropDownFieldProps<T, TOptions>) {
  const { getError } = useFormErrors();
  const classes = useStyles();
  rules = { ...rules, required };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value: keyValue, ref } }) => {
        // autocomplete is controlled, so the value is either null or a matching option
        const currentOption: TOptions | null =
          options.find((opt) => getOptionKey(opt) === keyValue) ?? null;

        return (
          <Autocomplete
            disableClearable={disableClearable}
            {...props}
            value={currentOption}
            options={options}
            onChange={(_, newLabelValue) =>
              onChange(newLabelValue ? getOptionKey(newLabelValue) : null)
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            renderInput={({ InputProps, ...params }: any) => (
              <MuiTextField
              placeholder={placeholder}
                {...params}
                {...getError(name)}
                className={clsx(
                  className,
                  classes.root,
                  readOnly && "textfield-readonly"
                )}
                onBlur={onBlur}
                inputRef={ref}
                InputProps={{ ...InputProps, readOnly }}
                label={
                  <>
                    {label}
                    <RequiredMarker required={required} />
                  </>
                }
              />
            )}
          />
        );
      }}
    />
  );
}

export default DropDownField;
