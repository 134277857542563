import { useParams } from "react-router";
import ImportHistoryDetails from "studies/components/imports/ImportHistoryDetails";

function ImportHistoryDetailsPage() {
  const { importId: importIdParam } = useParams<{ importId: string }>();

  const importId = Number(importIdParam);

  return <ImportHistoryDetails importId={importId} />;
}

export default ImportHistoryDetailsPage;
