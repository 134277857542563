import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useGetImportHistoryDetails } from "api";
import BackButtonComponent from "shared/components/BackButton";
import StickyFooter from "shared/layout/StickyFooter";
import ImportPatientList from "./ImportPatientList";
import ImportStudyDetails from "./ImportStudyDetails";
import ImportStudyPatientList from "./ImportStudyPatientList";
import ImportVisitList from "./ImportVisitList";

interface Props {
  importId: number;
}

function ImportHistoryDetails({ importId }: Props) {
  const { data } = useGetImportHistoryDetails(importId);

  if (!data?.model) return null;

  if (!data.model.study){
        return (
          <Card elevation={0} sx={{ flex: 1 }}>
            <CardContent sx={{ flex: 1 }}>
              <CardHeader title="The import may have failed, please check scheduler logs or contact administrator."/>
            </CardContent>
            <StickyFooter>
              <BackButtonComponent />
            </StickyFooter>
          </Card>
        );
      }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}></Grid>
            {data.model.study && (
              <Grid item xs={12}>
                <ImportStudyDetails importStudy={data.model.study} />
              </Grid>
            )}
            {data.model.patients.length > 0 && (
              <Grid item xs={12}>
                <ImportPatientList patients={data.model.patients} />
              </Grid>
            )}
            {data.model.studyPatients.length > 0 && (
              <Grid item xs={12}>
                <ImportStudyPatientList
                  studyPatients={data.model.studyPatients}
                />
              </Grid>
            )}
            {data.model.studyVisits.length > 0 && (
              <Grid item xs={12}>
                <ImportVisitList studyVisits={data.model.studyVisits} />
              </Grid>
            )}
          </Grid>

          <StickyFooter>
            <BackButtonComponent />
          </StickyFooter>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ImportHistoryDetails;
