import { useParams } from "react-router";
import ImportHistoryList from "../components/imports/ImportHistoryList";

function ImportHistoryListPage() {
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const studyId = Number(studyIdParam);

  return <ImportHistoryList studyId={studyId} />;
}

export default ImportHistoryListPage;
