import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import ManageStudyPatientForm from "studies/forms/ManageStudyPatientForm";

import { StudiesUpdateStudyPatientResult, useGetStudyPatient, useUpdateStudyPatientAssignment } from "../../api";

function ManageStudyPatientPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();
  const studyId = Number(studyIdParam);
  const patientId = Number(patientIdParam);
  const { data } = useGetStudyPatient(studyId, patientId);

  const { mutate, ...status } = useUpdateStudyPatientAssignment({
    mutation: {
      onSuccess(data: StudiesUpdateStudyPatientResult) {
        enqueueSnackbar("Patient Updated", {
          variant: "success",
        });
      },
    },
  });

  if (!data?.studyPatient) return null;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title={`Manage Patient ${data.studyPatient.initials}`} />
      <CardContent>
        <ManageStudyPatientForm
          studyPatient={data.studyPatient}
          onSave={(form) =>
            mutate({
              data: form,
              studyId: studyId,
              patientId: patientId,
            })
          }
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default ManageStudyPatientPage;
