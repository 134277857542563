import { Typography } from "@mui/material";
import { format } from "date-fns";

interface Props {
  date?: number | Date | null;
  dateFormat?: string;
}

function DateTimeDisplay({ date, dateFormat = "M/d" }: Props) {
  let displayDate = "--";
  if (!!date) {
    displayDate = format(date, dateFormat);
  }
  return (
    <Typography component={"span"} noWrap display="block">
      {displayDate}
    </Typography>
  );
}

export default DateTimeDisplay;
