import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { ImportGetImportHistoryDetailsImportPatientModel } from "api";
import { useDataGrid } from "shared";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";

interface Props {
  patients: ImportGetImportHistoryDetailsImportPatientModel[];
}

const columns: GridColumns = [
  {
    field: "ccId",
    headerName: "CC Id",
    flex: 1,
    valueGetter: ({ row }) => row.ccId,
  },
  {
    field: "initials",
    headerName: "Initials",
    flex: 1,
    valueGetter: ({ row }) => row.intials,
  },
  {
    field: "dob",
    headerName: "DOB",
    flex: 1,
    renderCell: ({ row }) => (
      <DateTimeDisplay date={row.dob} dateFormat="mm/dd/yy" />
    ),
  },
  {
    field: "genderCode",
    headerName: "Gender Code",
    flex: 1,
    valueGetter: ({ row }) => row.genderCode,
  },
];

function ImportPatientList({ patients }: Props) {
  const { grid } = useDataGrid("import-patients", columns);
  return (
    <DataGrid
      {...grid}
      rows={patients}
      rowCount={patients.length}
      autoHeight
      hideFooter={true}
      getRowId={(row) => row.ccId}
    />
  );
}

export default ImportPatientList;
