/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  SamplesGetAllSamplesResult,
  GetAllSamplesParams,
  SamplesAddSampleResult,
  SamplesAddSampleForm,
  SamplesGetSampleByIdResult,
  SamplesUpdateSampleResult,
  SamplesUpdateSampleForm,
  SamplesGetLabTasksResult,
  GetLabTasksParams,
  SamplesGetTasksForSampleResult,
  SamplesUpdateSampleTasksResult,
  SamplesUpdateSampleTasksForm,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllSamples = (
  params?: GetAllSamplesParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesGetAllSamplesResult>(
    { url: `/api/Sample`, method: "get", params },
    options
  );
};

export const getGetAllSamplesQueryKey = (params?: GetAllSamplesParams) => [
  `/api/Sample`,
  ...(params ? [params] : []),
];

export const useGetAllSamples = <
  TData = AsyncReturnType<typeof getAllSamples>,
  TError = unknown
>(
  params?: GetAllSamplesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllSamples>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllSamplesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllSamples>> = () =>
    getAllSamples(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllSamples>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addSample = (
  samplesAddSampleForm: SamplesAddSampleForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesAddSampleResult>(
    { url: `/api/Sample`, method: "post", data: samplesAddSampleForm },
    options
  );
};

export const useAddSample = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addSample>,
    TError,
    { data: SamplesAddSampleForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addSample>,
    { data: SamplesAddSampleForm }
  > = (props) => {
    const { data } = props || {};

    return addSample(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addSample>,
    TError,
    { data: SamplesAddSampleForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getSampleById = (
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesGetSampleByIdResult>(
    { url: `/api/Sample/${sampleId}`, method: "get" },
    options
  );
};

export const getGetSampleByIdQueryKey = (sampleId: number) => [
  `/api/Sample/${sampleId}`,
];

export const useGetSampleById = <
  TData = AsyncReturnType<typeof getSampleById>,
  TError = unknown
>(
  sampleId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getSampleById>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetSampleByIdQueryKey(sampleId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getSampleById>> = () =>
    getSampleById(sampleId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getSampleById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!sampleId, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateSample = (
  sampleId: number,
  samplesUpdateSampleForm: SamplesUpdateSampleForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesUpdateSampleResult>(
    {
      url: `/api/Sample/${sampleId}`,
      method: "put",
      data: samplesUpdateSampleForm,
    },
    options
  );
};

export const useUpdateSample = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSample>,
    TError,
    { sampleId: number; data: SamplesUpdateSampleForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateSample>,
    { sampleId: number; data: SamplesUpdateSampleForm }
  > = (props) => {
    const { sampleId, data } = props || {};

    return updateSample(sampleId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateSample>,
    TError,
    { sampleId: number; data: SamplesUpdateSampleForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getLabTasks = (
  params?: GetLabTasksParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesGetLabTasksResult>(
    { url: `/api/Sample/tasks`, method: "get", params },
    options
  );
};

export const getGetLabTasksQueryKey = (params?: GetLabTasksParams) => [
  `/api/Sample/tasks`,
  ...(params ? [params] : []),
];

export const useGetLabTasks = <
  TData = AsyncReturnType<typeof getLabTasks>,
  TError = unknown
>(
  params?: GetLabTasksParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getLabTasks>, TError, TData>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetLabTasksQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getLabTasks>> = () =>
    getLabTasks(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLabTasks>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getTasksForSample = (
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesGetTasksForSampleResult>(
    { url: `/api/Sample/${sampleId}/tasks`, method: "get" },
    options
  );
};

export const getGetTasksForSampleQueryKey = (sampleId: number) => [
  `/api/Sample/${sampleId}/tasks`,
];

export const useGetTasksForSample = <
  TData = AsyncReturnType<typeof getTasksForSample>,
  TError = unknown
>(
  sampleId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getTasksForSample>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTasksForSampleQueryKey(sampleId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getTasksForSample>
  > = () => getTasksForSample(sampleId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getTasksForSample>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!sampleId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const updateSampleTasks = (
  sampleId: number,
  samplesUpdateSampleTasksForm: SamplesUpdateSampleTasksForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<SamplesUpdateSampleTasksResult>(
    {
      url: `/api/Sample/${sampleId}/sampleTasks`,
      method: "post",
      data: samplesUpdateSampleTasksForm,
    },
    options
  );
};

export const useUpdateSampleTasks = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateSampleTasks>,
    TError,
    { sampleId: number; data: SamplesUpdateSampleTasksForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateSampleTasks>,
    { sampleId: number; data: SamplesUpdateSampleTasksForm }
  > = (props) => {
    const { sampleId, data } = props || {};

    return updateSampleTasks(sampleId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateSampleTasks>,
    TError,
    { sampleId: number; data: SamplesUpdateSampleTasksForm },
    TContext
  >(mutationFn, mutationOptions);
};
