export const AuditAreaEnum = [
  {
    label: "Patient",
    key: 0,
  },
  {
    label: "Visit",
    key: 1,
  },
  {
    label: "Sample",
    key: 2,
  },
  {
    label: "Tasks",
    key: 3,
  },
];

export function getAuditArea(key: number) {
  return AuditAreaEnum.find((f) => f.key === key)?.label;
}
