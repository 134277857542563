import { Route, Routes } from "react-router";

import AddUserPage from "./pages/AddUserPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import UserListPage from "./pages/UserListPage";

function UserRoutes() {
  return (
    <Routes>
      <Route path="" element={<UserListPage />} />
      <Route path="add" element={<AddUserPage />} />
      <Route path=":userId" element={<UserDetailsPage />} />
    </Routes>
  );
}

export default UserRoutes;
