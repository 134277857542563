/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  LabTechnicianGetPatientScheduledVisitsResult,
  GetPatientScheduledVisitsParams,
  LabTechnicianGetPatientSamplesResult,
  LabTechnicianGetPatientSampleDetailsResult,
  LabTechnicianFindPatientSampleBySampleKitResult,
  FindPatientSampleBySampleKitParams,
  LabTechnicianGetPatientVisitDetailsResult,
  LabTechnicianGetPatientSampleTasksResult,
  LabTechnicianCapturePatientSampleTaskResult,
  LabTechnicianUpdateBatchPatientSampleTaskResult,
  LabTechnicianUpdateBatchPatientSampleTaskForm,
  UpdateBatchPatientSampleTaskParams,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getPatientScheduledVisits = (
  params?: GetPatientScheduledVisitsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianGetPatientScheduledVisitsResult>(
    { url: `/api/LabTechnician`, method: "get", params },
    options
  );
};

export const getGetPatientScheduledVisitsQueryKey = (
  params?: GetPatientScheduledVisitsParams
) => [`/api/LabTechnician`, ...(params ? [params] : [])];

export const useGetPatientScheduledVisits = <
  TData = AsyncReturnType<typeof getPatientScheduledVisits>,
  TError = unknown
>(
  params?: GetPatientScheduledVisitsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientScheduledVisits>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientScheduledVisitsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientScheduledVisits>
  > = () => getPatientScheduledVisits(params, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientScheduledVisits>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getLabTechnicianPatientSamplesByVisitId = (
  patientId: number,
  visitId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianGetPatientSamplesResult>(
    {
      url: `/api/LabTechnician/patients/${patientId}/visits/${visitId}/samples`,
      method: "get",
    },
    options
  );
};

export const getGetLabTechnicianPatientSamplesByVisitIdQueryKey = (
  patientId: number,
  visitId: number
) => [`/api/LabTechnician/patients/${patientId}/visits/${visitId}/samples`];

export const useGetLabTechnicianPatientSamplesByVisitId = <
  TData = AsyncReturnType<typeof getLabTechnicianPatientSamplesByVisitId>,
  TError = unknown
>(
  patientId: number,
  visitId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getLabTechnicianPatientSamplesByVisitId>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLabTechnicianPatientSamplesByVisitIdQueryKey(patientId, visitId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLabTechnicianPatientSamplesByVisitId>
  > = () =>
    getLabTechnicianPatientSamplesByVisitId(patientId, visitId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getLabTechnicianPatientSamplesByVisitId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && visitId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const getLabTechnicianPatientSampleDetails = (
  patientId: number,
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianGetPatientSampleDetailsResult>(
    {
      url: `/api/LabTechnician/patients/${patientId}/samples/${sampleId}`,
      method: "get",
    },
    options
  );
};

export const getGetLabTechnicianPatientSampleDetailsQueryKey = (
  patientId: number,
  sampleId: number
) => [`/api/LabTechnician/patients/${patientId}/samples/${sampleId}`];

export const useGetLabTechnicianPatientSampleDetails = <
  TData = AsyncReturnType<typeof getLabTechnicianPatientSampleDetails>,
  TError = unknown
>(
  patientId: number,
  sampleId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getLabTechnicianPatientSampleDetails>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLabTechnicianPatientSampleDetailsQueryKey(patientId, sampleId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getLabTechnicianPatientSampleDetails>
  > = () =>
    getLabTechnicianPatientSampleDetails(patientId, sampleId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getLabTechnicianPatientSampleDetails>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && sampleId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const findPatientSampleBySampleKit = (
  params?: FindPatientSampleBySampleKitParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianFindPatientSampleBySampleKitResult>(
    { url: `/api/LabTechnician/search`, method: "get", params },
    options
  );
};

export const getFindPatientSampleBySampleKitQueryKey = (
  params?: FindPatientSampleBySampleKitParams
) => [`/api/LabTechnician/search`, ...(params ? [params] : [])];

export const useFindPatientSampleBySampleKit = <
  TData = AsyncReturnType<typeof findPatientSampleBySampleKit>,
  TError = unknown
>(
  params?: FindPatientSampleBySampleKitParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof findPatientSampleBySampleKit>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getFindPatientSampleBySampleKitQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof findPatientSampleBySampleKit>
  > = () => findPatientSampleBySampleKit(params, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof findPatientSampleBySampleKit>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getPatientScheduledVisitDetails = (
  patientId: number,
  visitId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianGetPatientVisitDetailsResult>(
    {
      url: `/api/LabTechnician/patients/${patientId}/visits/${visitId}`,
      method: "get",
    },
    options
  );
};

export const getGetPatientScheduledVisitDetailsQueryKey = (
  patientId: number,
  visitId: number
) => [`/api/LabTechnician/patients/${patientId}/visits/${visitId}`];

export const useGetPatientScheduledVisitDetails = <
  TData = AsyncReturnType<typeof getPatientScheduledVisitDetails>,
  TError = unknown
>(
  patientId: number,
  visitId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientScheduledVisitDetails>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientScheduledVisitDetailsQueryKey(patientId, visitId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientScheduledVisitDetails>
  > = () => getPatientScheduledVisitDetails(patientId, visitId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientScheduledVisitDetails>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && visitId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const getPatientSampleTasks = (
  patientId: number,
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianGetPatientSampleTasksResult>(
    {
      url: `/api/LabTechnician/patients/${patientId}/samples/${sampleId}/tasks`,
      method: "get",
    },
    options
  );
};

export const getGetPatientSampleTasksQueryKey = (
  patientId: number,
  sampleId: number
) => [`/api/LabTechnician/patients/${patientId}/samples/${sampleId}/tasks`];

export const useGetPatientSampleTasks = <
  TData = AsyncReturnType<typeof getPatientSampleTasks>,
  TError = unknown
>(
  patientId: number,
  sampleId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientSampleTasks>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientSampleTasksQueryKey(patientId, sampleId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientSampleTasks>
  > = () => getPatientSampleTasks(patientId, sampleId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientSampleTasks>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && sampleId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const capturePatientSampleTask = (
  patientId: number,
  sampleId: number,
  taskId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianCapturePatientSampleTaskResult>(
    {
      url: `/api/LabTechnician/patients/${patientId}/samples/${sampleId}/tasks/${taskId}`,
      method: "post",
    },
    options
  );
};

export const useCapturePatientSampleTask = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof capturePatientSampleTask>,
    TError,
    { patientId: number; sampleId: number; taskId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof capturePatientSampleTask>,
    { patientId: number; sampleId: number; taskId: number }
  > = (props) => {
    const { patientId, sampleId, taskId } = props || {};

    return capturePatientSampleTask(
      patientId,
      sampleId,
      taskId,
      requestOptions
    );
  };

  return useMutation<
    AsyncReturnType<typeof capturePatientSampleTask>,
    TError,
    { patientId: number; sampleId: number; taskId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const updateBatchPatientSampleTask = (
  labTechnicianUpdateBatchPatientSampleTaskForm: LabTechnicianUpdateBatchPatientSampleTaskForm,
  params?: UpdateBatchPatientSampleTaskParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<LabTechnicianUpdateBatchPatientSampleTaskResult>(
    {
      url: `/api/LabTechnician/batch`,
      method: "post",
      data: labTechnicianUpdateBatchPatientSampleTaskForm,
      params,
    },
    options
  );
};

export const useUpdateBatchPatientSampleTask = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateBatchPatientSampleTask>,
    TError,
    {
      data: LabTechnicianUpdateBatchPatientSampleTaskForm;
      params?: UpdateBatchPatientSampleTaskParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateBatchPatientSampleTask>,
    {
      data: LabTechnicianUpdateBatchPatientSampleTaskForm;
      params?: UpdateBatchPatientSampleTaskParams;
    }
  > = (props) => {
    const { data, params } = props || {};

    return updateBatchPatientSampleTask(data, params, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateBatchPatientSampleTask>,
    TError,
    {
      data: LabTechnicianUpdateBatchPatientSampleTaskForm;
      params?: UpdateBatchPatientSampleTaskParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
