import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ResendConfirmationEmailButton from "users/components/ResendConfirmationEmailButton";
import SetUserPasswordForm from "users/forms/SetUserPasswordForm";

import {
  PatientsUpdatePatientResult,
  useGetUserById,
  useUpdateUser,
} from "../../api";
import EditUserForm from "../forms/EditUserForm";

function UserDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userId: userIdParam } = useParams<{ userId: string }>();

  const [open, setOpen] = React.useState(false);

  const id = Number(userIdParam);
  const { data } = useGetUserById(id);
  const { mutate, ...status } = useUpdateUser({
    mutation: {
      onSuccess(data: PatientsUpdatePatientResult) {
        enqueueSnackbar("User Updated", {
          variant: "success",
        });
        navigate(`/users/${id}`);
      },
    },
  });

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit User" />
      <CardContent>
        {data && data.user && (
          <>
            <EditUserForm
              status={status}
              user={data.user}
              onSave={(form) => mutate({ id, data: form })}
            />
            <Grid container spacing={4}>
              <Grid item sm={12} xs={6}>
                <SetUserPasswordForm
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  userId={id}
                />
              </Grid>
              <Grid item sm={12} xs={6}>
                <Stack direction="row">
                  <Button
                    sx={{ mr: 2 }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Set Password
                  </Button>
                  <ResendConfirmationEmailButton
                    enabled={data.user.canResendConfirmation}
                    userId={data.user.id}
                  />
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default UserDetailsPage;
