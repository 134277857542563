import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import {
  NurseCaptureSampleDrawResult,
  NurseGetPatientVisitsPatientVisitModel,
  NurseUpdatePatientSampleResult,
  useCaptureSampleDraw,
  useGetPatientVisitsByStudyId,
  useUpdatePatientSample,
  useCaptureBatchSampleDraw,
  NurseCaptureBatchSampleDrawResult,
} from "api";
import { useSnackbar } from "notistack";
import EmptyContainer from "nurses/components/EmptyContainer";
import PatientInformation from "nurses/components/PatientInformation";
import PatientSampleListContainer from "nurses/components/PatientSampleList";
import EditPatientSampleContainer from "nurses/forms/EditPatientSampleForm";
import { useState } from "react";
import { useParams } from "react-router-dom";

function PatientSamplesPage() {
  const { enqueueSnackbar } = useSnackbar();
  const [sampleId, setSampleId] = useState<number | undefined>(undefined);
  const [refetchSampleList, setRefetchSampleList] = useState<boolean>(false);
  const [studyVisits, setStudyVisits] = useState<
    NurseGetPatientVisitsPatientVisitModel[]
  >([]);
  const [selectedVisitId, setSelectedVisitId] = useState<number | undefined>(
    undefined
  );

  const { patientId: patientIdParam } = useParams<{ patientId: string }>();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const patientId = Number(patientIdParam);
  const studyId = Number(studyIdParam);

  useGetPatientVisitsByStudyId(patientId, studyId, {
    query: {
      onSuccess: (x) => {
        setStudyVisits(x.patientVisits);
        if (x.patientVisits.length > 0)
          setSelectedVisitId(x.patientVisits[0].id);
      },
    },
  });

  const { mutateAsync, ...status } = useUpdatePatientSample({
    mutation: {
      onSuccess(_: NurseUpdatePatientSampleResult) {
        enqueueSnackbar("Sample updated", {
          variant: "success",
        });
        setRefetchSampleList(true);
      },
    },
  });

  const startSampleDraw = useCaptureSampleDraw({
    mutation: {
      onSuccess(_: NurseCaptureSampleDrawResult) {
        enqueueSnackbar("Draw Time captured", {
          variant: "success",
        });
        setRefetchSampleList(true);
      },
    },
  });

  const captureBatchSampleDraw = useCaptureBatchSampleDraw({
    mutation: {
      onSuccess(_: NurseCaptureBatchSampleDrawResult) {
        enqueueSnackbar("Draw Time captured for selected samples", {
          variant: "success",
        });
        setRefetchSampleList(true);
      },
    },
  });

  if (studyVisits.length === 0) return null;

  const selectedVisit = studyVisits.find((x) => x.id === selectedVisitId);

  // Select first in-complete visit or if all visits are completed, select the first visit
  const defaultVisitId =
    studyVisits.find((f) => !f.completedAt)?.id ?? studyVisits[0].id;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Patient Samples" />
      <CardContent>
        <PatientInformation patientId={patientId} studyId={studyId} />

        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <PatientSampleListContainer
              patientId={patientId}
              selectedVisitId={selectedVisitId ?? defaultVisitId}
              studyVisits={studyVisits}
              onVisitChange={(x) => 
                {
                  setSelectedVisitId(x);
                  setSampleId(undefined);
              }}
              onSampleSelect={(x) => {
                setSampleId(x);
              }}
              refetchSampleList={refetchSampleList}
              setRefetchSampleList={setRefetchSampleList}
              onCaptureBatchDraw={async (x) => {
                await captureBatchSampleDraw.mutateAsync({
                  patientId,
                  data: {
                    sampleIds: x,
                  },
                });
              }}
            />
          </Grid>
          {!selectedVisit?.accessionNumber ? (
            <EmptyContainer>
              <Typography textAlign="center" variant="h5">
                Please set accession number for the visit before proceeding.
              </Typography>
            </EmptyContainer>
          ) : (
            <>
              {!sampleId && (
                <EmptyContainer>
                  <Typography textAlign="center" variant="h5">
                    Please select sample to view details.
                  </Typography>
                </EmptyContainer>
              )}
              {sampleId && (
                <Grid
                  item
                  sm={6}
                  xs={5}
                  p={4}
                  sx={{
                    marginTop: "32px",
                    border: "solid thin",
                    borderRadius: ".5rem",
                    borderColor: "grey.300",
                  }}
                >
                  <EditPatientSampleContainer
                    patientId={patientId}
                    sampleId={sampleId}
                    onSave={async (form) => {
                      await mutateAsync({
                        patientId,
                        sampleId,
                        data: form,
                      });
                    }}
                    status={status}
                    onCaptureDraw={async (selectedSampleId, refetchSample) => {
                      await startSampleDraw.mutateAsync({
                        patientId,
                        sampleId: selectedSampleId,
                      });
                      if (refetchSample) {
                        refetchSample();
                      }
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PatientSamplesPage;
