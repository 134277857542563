export const PatientSampleStatusEnum = [
  {
    label: "Collected",
    key: 0,
  },
  {
    label: "Not Collected",
    key: 1,
  },
  {
    label: "Redraw",
    key: 2,
  },
];

export function getPatientSampleStatus(key: number) {
  return PatientSampleStatusEnum.find((f) => f.key === key)?.label;
}
