import React from "react";
import { IconButton, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { Control, ControllerProps, Path, useWatch } from "react-hook-form";
import clsx from "clsx";
import Icon from "../../../shared/Icon";

export type FormFieldProps<T> = {
  name: Path<T>;
  type?: string;
  control: Control<T, any>;
  readOnly?: boolean;
  rules?: ControllerProps["rules"];
  className?: string;
  required?: boolean;
  placeholder?:string;
};

export type FormTextFieldProps<T, TProps = unknown> = FormFieldProps<T> &
  InheritedTextFieldProps &
  TProps;

type InheritedTextFieldProps = Pick<
  MuiTextFieldProps,
  "id" | "label" | "className" | "sx" | "helperText" | "fullWidth"
>;

type FormComponent = <T>(props: FormFieldProps<T>) => JSX.Element;

export const asVariants = <TComponent extends FormComponent>(
  Component: TComponent
) => {
  return Object.assign(Component, {
    Calculated: calculatedVariant(Component),
  });
};

const calculatedVariant = <TComponent extends FormComponent>(
  Component: TComponent
): TComponent =>
  (<T extends object>({
    control,
    name,
    className,
    ...props
  }: FormFieldProps<T>) => {
    const overridden = useWatch({
      control,
      name: `${name}.overridden` as Path<T>,
    });

    const expected = useWatch({
      control,
      name: `${name}.expected` as Path<T>,
    });

    let helperText: React.ReactNode = undefined;
    if (overridden) {
      helperText = (
        <>
          <IconButton aria-label="restore" size="small">
            <Icon icon="undo" />
          </IconButton>
          Expected {expected}
        </>
      );
    }

    return (
      <Component
        control={control}
        name={`${name}.value` as Path<T>}
        className={clsx(className, "calculated", overridden && "overridden")}
        helperText={helperText}
        {...(props as any)}
      />
    );
  }) as TComponent;
