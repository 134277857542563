import React from "react";
import { Box, Stack } from "@mui/material";
import Logo from "../../media/logos/rcrLogo.png";

type Props = React.PropsWithChildren<{}>;
function Header({ children }: Props) {
  return (
    <Stack
      direction={"row"}
      m={0}
      p={0}
      width={"100%"}
      justifyContent={"space-between"}
      sx={{ borderBottom: "solid thin", borderColor: "gray.light" }}
    >
      <Box p={2}>
        <img src={Logo} alt="Logo"></img>
      </Box>
      <Stack direction={"row"} alignItems={"center"} p={2}>
        {children}
      </Stack>
    </Stack>
  );
}
export default Header;
