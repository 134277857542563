import { Box, Card, CardContent } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useLookupPatients } from "api";
import { useNavigate } from "react-router-dom";
import { DataGridToolbar, getStudyPatientStatus, useDataGrid } from "shared";

import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";

const columns: GridColumns = [
  {
    field: "initials",
    headerName: "Initials",
    flex: 1,
    valueGetter: ({ row }) => row.initials,
  },
  {
    field: "studyName",
    headerName: "Study Name",
    flex: 1,
    valueGetter: ({ row }) => row.studyName,
  },
  {
    field: "referenceId",
    headerName: "Id",
    flex: 1,
    valueGetter: ({ row }) => row.referenceId,
  },
  {
    field: "screeningNumber",
    headerName: "Screening #",
    flex: 1,
    valueGetter: ({ row }) => row.screeningNumber,
  },
  {
    field: "studyPatientStatus",
    headerName: "Status",
    flex: 1,
    valueGetter: ({ row }) => getStudyPatientStatus(row.studyPatientStatus),
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"Find Patients"}
      addButtonLabel="Add Patient"
      onAdd={() => navigate("/nurses/patients/add")}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

interface Props {
  onPatientSelect: (studyId: number, patientId: number) => void;
}

function LookupPatient({ onPatientSelect }: Props) {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "patients",
    columns
  );
  const { data, isFetching } = useLookupPatients(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                  pagination: {},
                }}
                getRowId={(row) => `${row.patientId}-${row.studyId}`}
                onRowClick={({ row }) => {
                  onPatientSelect(row.studyId, row.patientId);
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default LookupPatient;
