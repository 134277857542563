import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useParams } from "react-router";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PhoneDisplay from "shared/components/display/PhoneDisplay";

import { useGetAllSites } from "../../api";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";
import RemoveSite from "./RemoveSite";

const columns = (studyId: number): GridColumns => [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/sites/${row.id}`}>
        {row.name}
      </Link>
    ),
  },
  {
    field: "address",
    headerName: "Address",
    flex: 1,
    renderCell: ({ row }) =>
      `${row.address?.line1} ${row.address?.city} ${row.address?.state} ${row.address?.zip}`,
  },
  {
    field: "contact.email",
    headerName: "Email",
    flex: 1,
    valueGetter: ({ row }) => row.contact?.email,
  },
  {
    field: "contact.phone",
    headerName: "Phone",
    flex: 1,
    renderCell: ({ row }) => <PhoneDisplay phone={row.contact?.phone} />,
  },
  {
    field: "id",
    headerName: "Remove",
    type: "number",
    align: "right",
    flex: 1,
    renderCell: ({ row }) => <RemoveSite studyId={studyId} siteId={row.id} />,
  },
];

function Toolbar(
  studyId: number,
  props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">
) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"Site List"}
      addButtonLabel="Assign Site"
      onAdd={() => navigate(`/studies/${studyId}/sites/assign`)}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function AssignedSiteList() {
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "sites",
    columns(studyId)
  );
  const { data, isFetching } = useGetAllSites(
    {
      ...query,
      studyId: studyId,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rowHeight={75}
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: (x) => Toolbar(studyId, x),
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AssignedSiteList;
