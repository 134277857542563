import { Grid, Box } from "@mui/material";
import TextField from "../../shared/forms/fields/TextField";
import { useForm } from "react-hook-form";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import withFormStatus from "../../shared/forms/withFormStatus";
import { PatientsAddPatientForm } from "../../api";
import StickyFooter from "../../shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";
import DropDownField from "shared/forms/fields/DropDownField";
import DateField from "shared/forms/fields/DateField";
import { GenderEnum } from "shared";

interface Props {
  onSave(form: PatientsAddPatientForm): void;
}

function AddPatientForm({ onSave }: Props) {
  const { control, handleSubmit, formState } = useForm<PatientsAddPatientForm>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            control={control}
            name="initials"
            label="Patient Initials"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            control={control}
            name="referenceId"
            label="Patient Id"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DropDownField
            options={GenderEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="gender"
            label="Gender"
          />
        </Grid>
        <Grid item xs={6}>
          <DateField control={control} name="dob" label="DOB" />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent text="Cancel" />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AddPatientForm);
