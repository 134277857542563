import { DesktopDateTimePicker, DateTimePickerProps } from "@mui/lab";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { parseISO, formatISO } from "date-fns";
import useFormErrors from "../useFormErrors";
import { FormFieldProps, asVariants } from "./FieldVariants";
import { makeStyles } from "@mui/styles";
import RequiredMarker from "shared/components/RequiredMarker";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiBox-root": {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        order: 999,
      },
    },
  },
});

type DateTimeFieldProps<T> = FormFieldProps<T> &
  Pick<DateTimePickerProps, "label">;

function DateTimeField<T>({
  name,
  label,
  control,
  className,
  rules,
  readOnly = false,
  required = false,
  ...props
}: DateTimeFieldProps<T>) {
  const classes = useStyles();
  const { getError } = useFormErrors();
  rules = { ...rules, required };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        // we will manually marshall the date value to/from a string value for the form
        // .. this way we can get the exact ISO format we want and avoid timezone issues

        const dateVal = !value ? null : parseISO(String(value));

        return (
          <DesktopDateTimePicker
            {...props}
            inputFormat="MM/dd/yyyy hh:mm:ss a"
            value={dateVal}
            readOnly={readOnly}
            ampm={true}
            onChange={(date) => {
              const strValue = !(date instanceof Date && !isNaN(date.getTime()))
                ? null
                : formatISO(date, { representation: "complete" });
              return onChange(strValue);
            }}
            onClose={() => onBlur()}
            renderInput={(params: any) => (
              <MuiTextField
                {...params}
                {...getError(name)}
                ref={ref}
                className={clsx(
                  className,
                  classes.root,
                  readOnly && "textfield-readonly"
                )}
                label={
                  <>
                    {label}
                    <RequiredMarker required={required} />
                  </>
                }
              />
            )}
          />
        );
      }}
    />
  );
}

export default asVariants(DateTimeField);
