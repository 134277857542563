export const GenderEnum = [
  {
    label: "Male",
    key: 0,
  },
  {
    label: "Female",
    key: 1,
  },
  {
    label: "Other",
    key: 2,
  },
];

export function getGender(key: number) {
  return GenderEnum.find((f) => f.key === key)?.label;
}
