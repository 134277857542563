import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AssignPatient from "studies/components/AssignPatient";

import { useAssignPatient, useAvailablePatients, useGetStudyById } from "../../api";

function AssignPatientPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const studyId = Number(studyIdParam);

  const { data } = useGetStudyById(studyId);
  const { mutate, ...status } = useAssignPatient({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Patient Assigned", {
          variant: "success",
        });
        navigate(`/studies/${studyId}/patients`);
      },
    },
  });

  const availablePatientResults = useAvailablePatients(studyId);

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      {data && data.study && availablePatientResults.data?.patients && (
        <>
          <CardHeader title="Assign Patient" />

          <CardContent>
            <AssignPatient
              availablePatients={availablePatientResults.data.patients}
              status={status}
              onAssign={(patientId) =>
                mutate({
                  patientId: patientId,
                  studyId: studyId,
                })
              }
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default AssignPatientPage;
