import { useForm } from "react-hook-form";
import StyledDialog from "shared/components/StyledDialog";
import {
  Box,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

interface Props {
  title: string;
  open: boolean;
  setCode: (code: string) => void;
  setOpen: (open: boolean) => void;
}

function ScanSampleKitModal({ title, open, setOpen, setCode }: Props) {
  const { register, getValues, handleSubmit } = useForm<{
    barCode: string;
  }>({ defaultValues: { barCode: "" } });

  const onSubmit = handleSubmit((form) => {
    setCode(getValues("barCode"));
    setOpen(false);
  });

  return (
    <StyledDialog
      maxWidth="sm"
      open={open}
      title={title}
      onClose={() => setOpen(false)}
      sx={{ "& .MuiDialog-paper": { padding: "1rem" } }}
    >
      <DialogContent>
        <Box component="form" onSubmit={onSubmit}>
          <TextField
            autoComplete="off"
            autoFocus
            label="Scan sample kit"
            {...register("barCode")}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "8px 24px" }}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ScanSampleKitModal;
