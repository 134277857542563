import React, { useEffect } from "react";
import { Navigate } from "react-router";

import { useAuthState } from "./auth/context";

function SignOutPage() {
  const auth = useAuthState();

  useEffect(() => {
    auth.signOut();
  }, [auth]);

  return <Navigate to="/" />;
}

export default SignOutPage;
