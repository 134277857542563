import {
  LabTechnicianFindPatientSampleBySampleKitPatientSampleTaskModel,
  useFindPatientSampleBySampleKit,
} from "api";
import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import TextField from "shared/forms/fields/TextField";
import { SubmitButton } from "shared/forms";

interface ScanInputFormFields {
  code: string;
}

interface ScanInputComponentProps {
  onSuccess: (
    patientVisitTasks: LabTechnicianFindPatientSampleBySampleKitPatientSampleTaskModel[]
  ) => void;
  onFailure?: () => void;
}
function ScanInputComponent({ onFailure, onSuccess }: ScanInputComponentProps) {
  const [code, setCode] = useState("");
  const findPatientVisitBySampleKit = useFindPatientSampleBySampleKit(
    {
      code,
    },
    {
      query: {
        enabled: false,
        onSuccess: (result) => {
          if (result.patientSampleTasks) {
            onSuccess(result.patientSampleTasks);
            setCode("");
          }
        },
        onError: () => {
          if (onFailure) onFailure();
        },
      },
    }
  );

  const { handleSubmit, control, setValue, setFocus } =
    useForm<ScanInputFormFields>({
      defaultValues: {
        code: "",
      },
    });

  useEffect(() => {
    setFocus("code");
    if (code)
      findPatientVisitBySampleKit.refetch({
        cancelRefetch: !code,
      });
  }, [code, setFocus, findPatientVisitBySampleKit]);

  const onSubmit = handleSubmit((form) => {
    if (form.code) {
      setCode(form.code);
      setValue("code", "");
    }
  });

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <TextField
            control={control}
            name="code"
            fullWidth
            helperText="Scan sample kit code to lookup a sample or enter accession number to lookup all visit samples."
            rules={{
              required: true,
            }}
            sx={{ "& label": { margin: "0px" } }}
          />
        </Grid>
        <Grid item xs={2}>
          <SubmitButton variant="submit" />
        </Grid>
      </Grid>
    </Box>
  );
}
export default ScanInputComponent;
