/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  NurseLookupPatientsResult,
  LookupPatientsParams,
  NurseAddStudyPatientResult,
  NurseAddStudyPatientForm,
  NurseGetStudyPatientByIdResult,
  NurseUpdateStudyPatientResult,
  NurseUpdateStudyPatientForm,
  NurseCaptureSampleDrawResult,
  NurseCaptureBatchSampleDrawResult,
  NurseCaptureBatchSampleDrawForm,
  NurseGetPatientSamplesResult,
  NurseGetPatientVisitsResult,
  NurseGetPatientSampleDetailsResult,
  NurseUpdatePatientSampleResult,
  NurseUpdatePatientSampleForm,
  NurseGetPatientVisitDetailsResult,
  NurseUpdatePatientVisitResult,
  NurseUpdatePatientVisitForm,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const lookupPatients = (
  params?: LookupPatientsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseLookupPatientsResult>(
    { url: `/api/Nurse/patients`, method: "get", params },
    options
  );
};

export const getLookupPatientsQueryKey = (params?: LookupPatientsParams) => [
  `/api/Nurse/patients`,
  ...(params ? [params] : []),
];

export const useLookupPatients = <
  TData = AsyncReturnType<typeof lookupPatients>,
  TError = unknown
>(
  params?: LookupPatientsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof lookupPatients>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getLookupPatientsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof lookupPatients>> = () =>
    lookupPatients(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof lookupPatients>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addStudyPatient = (
  nurseAddStudyPatientForm: NurseAddStudyPatientForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseAddStudyPatientResult>(
    {
      url: `/api/Nurse/patients`,
      method: "post",
      data: nurseAddStudyPatientForm,
    },
    options
  );
};

export const useAddStudyPatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addStudyPatient>,
    TError,
    { data: NurseAddStudyPatientForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addStudyPatient>,
    { data: NurseAddStudyPatientForm }
  > = (props) => {
    const { data } = props || {};

    return addStudyPatient(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addStudyPatient>,
    TError,
    { data: NurseAddStudyPatientForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getStudyPatientById = (
  patientId: number,
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseGetStudyPatientByIdResult>(
    { url: `/api/Nurse/patients/${patientId}/study/${studyId}`, method: "get" },
    options
  );
};

export const getGetStudyPatientByIdQueryKey = (
  patientId: number,
  studyId: number
) => [`/api/Nurse/patients/${patientId}/study/${studyId}`];

export const useGetStudyPatientById = <
  TData = AsyncReturnType<typeof getStudyPatientById>,
  TError = unknown
>(
  patientId: number,
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getStudyPatientById>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetStudyPatientByIdQueryKey(patientId, studyId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getStudyPatientById>
  > = () => getStudyPatientById(patientId, studyId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getStudyPatientById>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && studyId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const updateStudyPatient = (
  patientId: number,
  studyId: number,
  nurseUpdateStudyPatientForm: NurseUpdateStudyPatientForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseUpdateStudyPatientResult>(
    {
      url: `/api/Nurse/patients/${patientId}/study/${studyId}`,
      method: "post",
      data: nurseUpdateStudyPatientForm,
    },
    options
  );
};

export const useUpdateStudyPatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateStudyPatient>,
    TError,
    { patientId: number; studyId: number; data: NurseUpdateStudyPatientForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateStudyPatient>,
    { patientId: number; studyId: number; data: NurseUpdateStudyPatientForm }
  > = (props) => {
    const { patientId, studyId, data } = props || {};

    return updateStudyPatient(patientId, studyId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateStudyPatient>,
    TError,
    { patientId: number; studyId: number; data: NurseUpdateStudyPatientForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const captureSampleDraw = (
  patientId: number,
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseCaptureSampleDrawResult>(
    {
      url: `/api/Nurse/patients/${patientId}/sample/${sampleId}/capture`,
      method: "post",
    },
    options
  );
};

export const useCaptureSampleDraw = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof captureSampleDraw>,
    TError,
    { patientId: number; sampleId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof captureSampleDraw>,
    { patientId: number; sampleId: number }
  > = (props) => {
    const { patientId, sampleId } = props || {};

    return captureSampleDraw(patientId, sampleId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof captureSampleDraw>,
    TError,
    { patientId: number; sampleId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const captureBatchSampleDraw = (
  patientId: number,
  nurseCaptureBatchSampleDrawForm: NurseCaptureBatchSampleDrawForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseCaptureBatchSampleDrawResult>(
    {
      url: `/api/Nurse/patients/${patientId}/sample/capture/batch`,
      method: "post",
      data: nurseCaptureBatchSampleDrawForm,
    },
    options
  );
};

export const useCaptureBatchSampleDraw = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof captureBatchSampleDraw>,
    TError,
    { patientId: number; data: NurseCaptureBatchSampleDrawForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof captureBatchSampleDraw>,
    { patientId: number; data: NurseCaptureBatchSampleDrawForm }
  > = (props) => {
    const { patientId, data } = props || {};

    return captureBatchSampleDraw(patientId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof captureBatchSampleDraw>,
    TError,
    { patientId: number; data: NurseCaptureBatchSampleDrawForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPatientSamplesByVisitId = (
  patientId: number,
  visitId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseGetPatientSamplesResult>(
    {
      url: `/api/Nurse/patients/${patientId}/visits/${visitId}/samples`,
      method: "get",
    },
    options
  );
};

export const getGetPatientSamplesByVisitIdQueryKey = (
  patientId: number,
  visitId: number
) => [`/api/Nurse/patients/${patientId}/visits/${visitId}/samples`];

export const useGetPatientSamplesByVisitId = <
  TData = AsyncReturnType<typeof getPatientSamplesByVisitId>,
  TError = unknown
>(
  patientId: number,
  visitId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientSamplesByVisitId>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientSamplesByVisitIdQueryKey(patientId, visitId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientSamplesByVisitId>
  > = () => getPatientSamplesByVisitId(patientId, visitId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientSamplesByVisitId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && visitId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const getPatientVisitsByStudyId = (
  patientId: number,
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseGetPatientVisitsResult>(
    {
      url: `/api/Nurse/patients/${patientId}/studies/${studyId}/visits`,
      method: "get",
    },
    options
  );
};

export const getGetPatientVisitsByStudyIdQueryKey = (
  patientId: number,
  studyId: number
) => [`/api/Nurse/patients/${patientId}/studies/${studyId}/visits`];

export const useGetPatientVisitsByStudyId = <
  TData = AsyncReturnType<typeof getPatientVisitsByStudyId>,
  TError = unknown
>(
  patientId: number,
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientVisitsByStudyId>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientVisitsByStudyIdQueryKey(patientId, studyId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientVisitsByStudyId>
  > = () => getPatientVisitsByStudyId(patientId, studyId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientVisitsByStudyId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && studyId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const getPatientSampleDetails = (
  patientId: number,
  sampleId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseGetPatientSampleDetailsResult>(
    {
      url: `/api/Nurse/patients/${patientId}/samples/${sampleId}`,
      method: "get",
    },
    options
  );
};

export const getGetPatientSampleDetailsQueryKey = (
  patientId: number,
  sampleId: number
) => [`/api/Nurse/patients/${patientId}/samples/${sampleId}`];

export const useGetPatientSampleDetails = <
  TData = AsyncReturnType<typeof getPatientSampleDetails>,
  TError = unknown
>(
  patientId: number,
  sampleId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientSampleDetails>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientSampleDetailsQueryKey(patientId, sampleId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientSampleDetails>
  > = () => getPatientSampleDetails(patientId, sampleId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientSampleDetails>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && sampleId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const updatePatientSample = (
  patientId: number,
  sampleId: number,
  nurseUpdatePatientSampleForm: NurseUpdatePatientSampleForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseUpdatePatientSampleResult>(
    {
      url: `/api/Nurse/patients/${patientId}/samples/${sampleId}`,
      method: "post",
      data: nurseUpdatePatientSampleForm,
    },
    options
  );
};

export const useUpdatePatientSample = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePatientSample>,
    TError,
    { patientId: number; sampleId: number; data: NurseUpdatePatientSampleForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePatientSample>,
    { patientId: number; sampleId: number; data: NurseUpdatePatientSampleForm }
  > = (props) => {
    const { patientId, sampleId, data } = props || {};

    return updatePatientSample(patientId, sampleId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updatePatientSample>,
    TError,
    { patientId: number; sampleId: number; data: NurseUpdatePatientSampleForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getPatientVisitDetails = (
  patientId: number,
  visitId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseGetPatientVisitDetailsResult>(
    {
      url: `/api/Nurse/patients/${patientId}/visits/${visitId}`,
      method: "get",
    },
    options
  );
};

export const getGetPatientVisitDetailsQueryKey = (
  patientId: number,
  visitId: number
) => [`/api/Nurse/patients/${patientId}/visits/${visitId}`];

export const useGetPatientVisitDetails = <
  TData = AsyncReturnType<typeof getPatientVisitDetails>,
  TError = unknown
>(
  patientId: number,
  visitId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientVisitDetails>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPatientVisitDetailsQueryKey(patientId, visitId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getPatientVisitDetails>
  > = () => getPatientVisitDetails(patientId, visitId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getPatientVisitDetails>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(patientId && visitId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const updatePatientVisit = (
  patientId: number,
  visitId: number,
  nurseUpdatePatientVisitForm: NurseUpdatePatientVisitForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<NurseUpdatePatientVisitResult>(
    {
      url: `/api/Nurse/patients/${patientId}/visits/${visitId}`,
      method: "post",
      data: nurseUpdatePatientVisitForm,
    },
    options
  );
};

export const useUpdatePatientVisit = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePatientVisit>,
    TError,
    { patientId: number; visitId: number; data: NurseUpdatePatientVisitForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePatientVisit>,
    { patientId: number; visitId: number; data: NurseUpdatePatientVisitForm }
  > = (props) => {
    const { patientId, visitId, data } = props || {};

    return updatePatientVisit(patientId, visitId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updatePatientVisit>,
    TError,
    { patientId: number; visitId: number; data: NurseUpdatePatientVisitForm },
    TContext
  >(mutationFn, mutationOptions);
};
