import { Grid, Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import {
  DownloadReportParams,
  StudiesGetAllStudiesStudyModel,
} from "../../api";
import StickyFooter from "../../shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";
import DropDownField from "shared/forms/fields/DropDownField";
import DateField from "shared/forms/fields/DateField";

interface Props {
  studies: StudiesGetAllStudiesStudyModel[];
  onSave(form: DownloadReportParams): void;
}
function ExportSampleReportForm({ onSave, studies }: Props) {
  const { control, handleSubmit, formState } = useForm<DownloadReportParams>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DropDownField
            options={studies}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => opt.name}
            control={control}
            name="studyId"
            label="Select Study"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DateField control={control} name="start" label="Start" />
        </Grid>
        <Grid item xs={6}>
          <DateField control={control} name="end" label="End" />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent text="Cancel" />
        <SubmitButton variant="save" disabled={!formState.isValid}>
          <Typography>Export</Typography>
        </SubmitButton>
      </StickyFooter>
    </Box>
  );
}

export default ExportSampleReportForm;
