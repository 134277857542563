import { useForm } from "react-hook-form";
import SubmitButton from "shared/forms/SubmitButton";
import { Grid, Box } from "@mui/material";
import { StudiesGetAvailableSitesSiteModel } from "../../api";
import StickyFooter from "../../shared/layout/StickyFooter";
import DropDownField from "shared/forms/fields/DropDownField";
import withFormStatus from "shared/forms/withFormStatus";

interface Props {
  availableSites: StudiesGetAvailableSitesSiteModel[];
  onAssign(siteId: number): void;
}

function AssignSite({ onAssign, availableSites }: Props) {
  const { control, handleSubmit, formState } = useForm<{
    siteId?: number;
  }>({
    defaultValues: undefined,
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => {
    if (form.siteId) onAssign(form.siteId);
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <DropDownField
            options={availableSites}
            getOptionKey={(opt: StudiesGetAvailableSitesSiteModel) => opt.id}
            getOptionLabel={(opt: StudiesGetAvailableSitesSiteModel) =>
              opt.name
            }
            control={control}
            name="siteId"
            label="Select Site"
            required
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AssignSite);
