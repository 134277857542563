import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import {
  NurseUpdateStudyPatientForm,
  NurseGetStudyPatientByIdStudyPatientModel,
} from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";
import DropDownField from "shared/forms/fields/DropDownField";
import DateField from "shared/forms/fields/DateField";
import { GenderEnum } from "shared";
import { StudyPatientStatusEnum } from "shared/models/study-patient-status.enum";

interface Props {
  patient: NurseGetStudyPatientByIdStudyPatientModel;
  onSave(form: NurseUpdateStudyPatientForm): void;
}

function EditStudyPatientForm({ patient, onSave }: Props) {
  const { control, handleSubmit, formState } =
    useForm<NurseUpdateStudyPatientForm>({
      defaultValues: {
        dob: patient.dob,
        gender: patient.gender,
        initials: patient.initials,
        referenceId: patient.referenceId,
        screeningNumber: patient.screeningNumber,
        status: patient.studyPatientStatus,
      },
      mode: "onChange",
    });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            control={control}
            name="initials"
            label="Patient Initials"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            control={control}
            name="referenceId"
            label="Patient Id"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DropDownField
            options={GenderEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="gender"
            label="Gender"
          />
        </Grid>
        <Grid item xs={6}>
          <DateField control={control} name="dob" label="DOB" />
        </Grid>
        <Grid item xs={12}>
          <Typography>Study Name: {patient.studyName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            control={control}
            name="screeningNumber"
            label="Screening Number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <DropDownField
            options={StudyPatientStatusEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="status"
            label="Patient Status"
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent text="Back to List" to="/nurses/patients/lookup" />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditStudyPatientForm);
