import React from "react";
import { Alert, Typography } from "@mui/material";

type Props = React.PropsWithChildren<{
  message?: string;
}>;

function ErrorDisplay({ message, children }: Props) {
  return (
    <Alert severity="error" sx={{ mb: 2 }}>
      {children && <>{children}</>}
      <Typography variant="body2">{message}</Typography>
    </Alert>
  );
}
export default ErrorDisplay;
