import { Route, Routes } from "react-router";
import ExportPage from "./pages/ExportPage";

function ExportRoutes() {
  return (
    <Routes>
      <Route path="" element={<ExportPage />} />
    </Routes>
  );
}

export default ExportRoutes;
