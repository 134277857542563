import { Button } from "@mui/material";
import { useGetCurrentUser } from "api";
import BatchScanModalContainer from "lab/components/ExecuteSampleTasks/BatchScanModalContainer";
import PatientScheduledVisitList from "lab/components/PatientScheduledVisitList";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StickyFooter from "shared/layout/StickyFooter";

function PatientScheduledVisitListPage() {
  const { data } = useGetCurrentUser();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  if (!data?.user) return null;
  return (
    <>
      <BatchScanModalContainer open={open} setOpen={setOpen} />
      <PatientScheduledVisitList
        onPatientSelect={(studyId, visitId, patientId) => {
          navigate(
            `/lab/studies/${studyId}/visits/${visitId}/patients/${patientId}`
          );
        }}
      />
      <StickyFooter>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          Scan Sample Kit codes
        </Button>
      </StickyFooter>
    </>
  );
}

export default PatientScheduledVisitListPage;
