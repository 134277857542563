import { Route, Routes } from "react-router";

import AddSitePage from "./pages/AddSitePage";
import SiteDetailsPage from "./pages/SiteDetailsPage";
import SiteListPage from "./pages/SiteListPage";

function SiteRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SiteListPage />} />
      <Route path="add" element={<AddSitePage />} />
      <Route path=":siteId" element={<SiteDetailsPage />} />
    </Routes>
  );
}

export default SiteRoutes;
