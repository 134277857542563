import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { ImportGetImportHistoryDetailsImportStudyPatientModel } from "api";
import { useDataGrid } from "shared";

const columns: GridColumns = [
  {
    field: "ccId",
    headerName: "CC Id",
    flex: 1,
    valueGetter: ({ row }) => row.ccId,
  },
  {
    field: "patientCCId",
    headerName: "Patient CCId",
    flex: 1,
    valueGetter: ({ row }) => row.patientCCId,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    valueGetter: ({ row }) => row.status,
  },
  {
    field: "screeningNumber",
    headerName: "Screening Number",
    flex: 1,
    valueGetter: ({ row }) => row.screeningNumber,
  },
];

interface Props {
  studyPatients: ImportGetImportHistoryDetailsImportStudyPatientModel[];
}

function ImportStudyPatientList({ studyPatients }: Props) {
  const { grid } = useDataGrid("import-study-patients", columns);
  return (
    <DataGrid
      {...grid}
      rows={studyPatients}
      rowCount={studyPatients.length}
      autoHeight
      hideFooter={true}
      getRowId={(row) => row.ccId}
    />
  );
}

export default ImportStudyPatientList;
