import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  required?: boolean;
};
function RequiredMarker({ required = false }: Props) {
  if (!required) return null;
  return (
    <Box
      sx={{
        backgroundColor: "error.main",
        color: "white",
        padding: ".125rem",
        borderRadius: ".25rem",
        width: "min-content",
        marginBottom: "1rem",
      }}
    >
      <Typography sx={{ fontSize: ".5rem" }}>Required</Typography>
    </Box>
  );
}
export default RequiredMarker;
