/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  PatientsGetAllPatientsResult,
  GetAllPatientsParams,
  PatientsAddPatientResult,
  PatientsAddPatientForm,
  PatientsGetAllStudyPatientsResult,
  GetAllStudyPatientsParams,
  PatientsGetPatientByIdResult,
  PatientsDeletePatientResult,
  PatientsUpdatePatientResult,
  PatientsUpdatePatientForm,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllPatients = (
  params?: GetAllPatientsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsGetAllPatientsResult>(
    { url: `/api/Patient`, method: "get", params },
    options
  );
};

export const getGetAllPatientsQueryKey = (params?: GetAllPatientsParams) => [
  `/api/Patient`,
  ...(params ? [params] : []),
];

export const useGetAllPatients = <
  TData = AsyncReturnType<typeof getAllPatients>,
  TError = unknown
>(
  params?: GetAllPatientsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllPatients>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllPatientsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllPatients>> = () =>
    getAllPatients(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllPatients>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addPatient = (
  patientsAddPatientForm: PatientsAddPatientForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsAddPatientResult>(
    { url: `/api/Patient`, method: "post", data: patientsAddPatientForm },
    options
  );
};

export const useAddPatient = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addPatient>,
    TError,
    { data: PatientsAddPatientForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addPatient>,
    { data: PatientsAddPatientForm }
  > = (props) => {
    const { data } = props || {};

    return addPatient(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addPatient>,
    TError,
    { data: PatientsAddPatientForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getAllStudyPatients = (
  params?: GetAllStudyPatientsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsGetAllStudyPatientsResult>(
    { url: `/api/Patient/studies`, method: "get", params },
    options
  );
};

export const getGetAllStudyPatientsQueryKey = (
  params?: GetAllStudyPatientsParams
) => [`/api/Patient/studies`, ...(params ? [params] : [])];

export const useGetAllStudyPatients = <
  TData = AsyncReturnType<typeof getAllStudyPatients>,
  TError = unknown
>(
  params?: GetAllStudyPatientsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllStudyPatients>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAllStudyPatientsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAllStudyPatients>
  > = () => getAllStudyPatients(params, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getAllStudyPatients>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getPatientById = (
  patientId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsGetPatientByIdResult>(
    { url: `/api/Patient/${patientId}`, method: "get" },
    options
  );
};

export const getGetPatientByIdQueryKey = (patientId: number) => [
  `/api/Patient/${patientId}`,
];

export const useGetPatientById = <
  TData = AsyncReturnType<typeof getPatientById>,
  TError = unknown
>(
  patientId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getPatientById>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPatientByIdQueryKey(patientId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getPatientById>> = () =>
    getPatientById(patientId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getPatientById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!patientId, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const deletePatient = (
  patientId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsDeletePatientResult>(
    { url: `/api/Patient/${patientId}`, method: "delete" },
    options
  );
};

export const useDeletePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePatient>,
    TError,
    { patientId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePatient>,
    { patientId: number }
  > = (props) => {
    const { patientId } = props || {};

    return deletePatient(patientId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof deletePatient>,
    TError,
    { patientId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const updatePatient = (
  patientId: number,
  patientsUpdatePatientForm: PatientsUpdatePatientForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<PatientsUpdatePatientResult>(
    {
      url: `/api/Patient/${patientId}`,
      method: "post",
      data: patientsUpdatePatientForm,
    },
    options
  );
};

export const useUpdatePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updatePatient>,
    TError,
    { patientId: number; data: PatientsUpdatePatientForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updatePatient>,
    { patientId: number; data: PatientsUpdatePatientForm }
  > = (props) => {
    const { patientId, data } = props || {};

    return updatePatient(patientId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updatePatient>,
    TError,
    { patientId: number; data: PatientsUpdatePatientForm },
    TContext
  >(mutationFn, mutationOptions);
};
