import { Card, CardContent, CardHeader } from "@mui/material";
import AuditLogListContainer from "audits/components/AuditLogList";
import { useGetLabTasks } from "../../api";

function AuditLogListPage() {
  const allTasks = useGetLabTasks();

  if (!allTasks.data?.labTasks) return null;
  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Audit Log" />
      <CardContent>
        <AuditLogListContainer labTasks={allTasks.data.labTasks} />
      </CardContent>
    </Card>
  );
}

export default AuditLogListPage;
