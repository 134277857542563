import { Route, Routes } from "react-router";
import NavigationDashboard from "./pages/NavigationDashboard";

function HomeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<NavigationDashboard />} />
      <Route path="*" element={<NavigationDashboard />} />
    </Routes>
  );
}

export default HomeRoutes;
