import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import {
  NurseUpdatePatientVisitResult,
  useUpdatePatientVisit,
  useGetPatientVisitsByStudyId,
} from "api";
import { useSnackbar } from "notistack";
import EmptyContainer from "nurses/components/EmptyContainer";
import PatientInformation from "nurses/components/PatientInformation";
import PatientVisitList from "nurses/components/PatientVisitList";
import EditPatientVisitContainer from "nurses/forms/EditPatientVisitForm";
import { useState } from "react";
import { useParams } from "react-router-dom";

function PatientVisitsPage() {
  const [visitId, setVisitId] = useState<number | undefined>(undefined);
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const patientId = Number(patientIdParam);
  const studyId = Number(studyIdParam);

  const { data, refetch } = useGetPatientVisitsByStudyId(patientId, studyId, {
    query: { keepPreviousData: true },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { mutate, ...status } = useUpdatePatientVisit({
    mutation: {
      onSuccess(_: NurseUpdatePatientVisitResult) {
        refetch({
          cancelRefetch: true,
        });
        enqueueSnackbar("Visit updated", {
          variant: "success",
        });
      },
    },
  });

  if (!data?.patientVisits) return null;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Patient Visits" />
      <CardContent>
        <PatientInformation patientId={patientId} studyId={studyId} />
        <Grid container spacing={4}>
          <Grid item sm={6} xs={7}>
            <PatientVisitList
              patientVisits={data.patientVisits}
              studyId={studyId}
              onVisitSelect={(x) => {
                setVisitId(x);
              }}
            />
          </Grid>
          {!visitId && (
            <EmptyContainer>
              <Typography textAlign="center" variant="h5">
                Please select visit to view details.
              </Typography>
            </EmptyContainer>
          )}
          {visitId && (
            <Grid
              item
              sm={6}
              xs={5}
              p={4}
              sx={{
                marginTop: "32px",
                border: "solid thin",
                borderRadius: ".5rem",
                borderColor: "grey.300",
              }}
            >
              <EditPatientVisitContainer
                patientId={patientId}
                visitId={visitId}
                status={status}
                onSave={(form) => mutate({ patientId, visitId, data: form })}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
export default PatientVisitsPage;
