 import { Button, Grid, DialogContent, Box } from "@mui/material";
import React, { useState, useMemo } from "react";
import StyledDialog from "shared/components/StyledDialog";
import {
  useGetCurrentUser,
  LabTechnicianFindPatientSampleBySampleKitPatientSampleTaskModel,
  AccountGetCurentUserUserModel,
  useGetAllUsers,
  LabTechnicianUpdateBatchPatientSampleTaskForm,
  LabTechnicianUpdateBatchPatientSampleTaskPatientSampleTaskRecord,
  useUpdateBatchPatientSampleTask,
} from "api";
import ScanInputComponent from "./ScanInputComponent";
import { useFieldArray, useForm } from "react-hook-form";
import DropDownField from "shared/forms/fields/DropDownField";
import DateTimeField from "shared/forms/fields/DateTimeField";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import NumberField from "shared/forms/fields/NumberField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../../../theme";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function BatchScanModalContainer({ open, setOpen }: Props) {
  const { mutate } = useUpdateBatchPatientSampleTask();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetCurrentUser();

  if (!data?.user) return null;

  return (
    <StyledDialog
      maxWidth="xl"
      open={open}
      title={`Scan sample kit codes for batch update`}
      onClose={() => setOpen(false)}
    >
      <DialogContent>
        <BatchScanModalContent
          currentUser={data.user}
          onUpdate={(form) => {
            mutate(
              {
                data: {
                  labTasks: form.labTasks,
                },
              },
              {
                onSuccess: () => {
                  enqueueSnackbar("Tasks Updated", {
                    variant: "success",
                  });
                  setOpen(false);
                },
              }
            );
          }}
          onCancel={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </StyledDialog>
  );
}

interface BatchScanModalContentProps {
  currentUser: AccountGetCurentUserUserModel;
  onUpdate: (form: LabTechnicianUpdateBatchPatientSampleTaskForm) => void;
  onCancel: () => void;
}
function BatchScanModalContent({
  currentUser,
  onUpdate,
  onCancel,
}: BatchScanModalContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [patientTasks, setPatientTasks] = useState<
    Array<LabTechnicianFindPatientSampleBySampleKitPatientSampleTaskModel>
  >([]);

  const { handleSubmit, control, register } =
    useForm<LabTechnicianUpdateBatchPatientSampleTaskForm>({
      defaultValues: {
        labTasks:
          new Array<LabTechnicianUpdateBatchPatientSampleTaskPatientSampleTaskRecord>(),
      },
    });

  const { fields, append, remove } = useFieldArray({
    name: "labTasks",
    control: control,
    keyName: "id",
  });

  const mappedPatientSamples = useMemo(() => {
    return new Map(
      patientTasks.map((m) => [
        `${m.patientId}-${m.sampleId}-${m.labTaskId}`,
        m,
      ])
    );
  }, [patientTasks]);

  const users = useGetAllUsers({
    userTypes: ["LabTechnician", "Admin"],
    pageSize: 0,
  });

  const onSubmit = handleSubmit((form) => {
    onUpdate(form);
  });

  if (!users.data?.items) return null;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ScanInputComponent
            onSuccess={(x) => {
              if(x.length === 0)
              {
                enqueueSnackbar("Patient has no tasks to record", {
                  variant: "success",
                });
                return;
              }
              if (mappedPatientSamples.size === 0) {
                setPatientTasks(x);
                x.forEach((task) => {
                  append({
                    sampleId: task.sampleId,
                    executedById: currentUser.id,
                    labTaskId: task.labTaskId,
                    patientId: task.patientId,
                    timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
                  });
                });

                enqueueSnackbar("Patient found", {
                  variant: "success",
                });
              }
            }}
            onFailure={() => {
              enqueueSnackbar("Patient not found", {
                variant: "error",
              });
            }}
          />
        </Grid>
      </Grid>
      {fields.length > 0 && (
        <Box component="form" onSubmit={onSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                sx={{
                  "& .MuiGrid-item": {
                    display: "flex",
                    alignItems: "center",
                    paddingRight: ".25rem",
                    "& .MuiButton-text": { height: "100%" },
                    "& .MuiAutocomplete-root": { width: "100%" },
                  },
                }}
              >
                <Grid item xs={1}>
                  <strong>Study Name</strong>
                </Grid>
                <Grid item xs={3}>
                  <strong>Visit Name</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Patient Info</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Sample Type</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Task Name</strong>
                </Grid>
                <Grid item xs={2}>
                  <strong>Timestamp</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Executed By</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Qty.</strong>
                </Grid>
                <Grid item xs={1}>
                  <strong>Remove</strong>
                </Grid>
                {fields.map((patientSample, i) => {
                  return (
                    <React.Fragment key={patientSample.id}>
                      <Grid item xs={1}>
                        {
                          mappedPatientSamples.get(
                            `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                          )?.studyName
                        }
                      </Grid>
                      <Grid item xs={3}>
                        {
                          mappedPatientSamples.get(
                            `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                          )?.visitName
                        }
                      </Grid>
                      <Grid item xs={1}>
                        {
                          mappedPatientSamples.get(
                            `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                          )?.patientInitials
                        }
                      </Grid>
                      <Grid item xs={1}>
                        {
                          mappedPatientSamples.get(
                            `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                          )?.sampleType
                        }
                      </Grid>
                      <Grid item xs={1}>
                        {
                          mappedPatientSamples.get(
                            `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                          )?.labTaskName
                        }
                      </Grid>
                      <Grid item xs={2}>
                        <DateTimeField
                          {...register(`labTasks.${i}.timestamp`)}
                          control={control}
                          name={`labTasks.${i}.timestamp`}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <DropDownField
                          control={control}
                          {...register(`labTasks.${i}.executedById`)}
                          name={`labTasks.${i}.executedById`}
                          options={users.data.items}
                          getOptionKey={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.username}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <NumberField
                          control={control}
                          {...register(`labTasks.${i}.quantity`)}
                          name={`labTasks.${i}.quantity`}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setPatientTasks(
                              patientTasks.filter((f) => {
                                const key = `${f.patientId}-${f.sampleId}-${f.labTaskId}`;
                                return (
                                  key !==
                                  `${patientSample.patientId}-${patientSample.sampleId}-${patientSample.labTaskId}`
                                );
                              })
                            );
                            remove(i);
                          }}
                        >
                          <FontAwesomeIcon icon={icons.trash}></FontAwesomeIcon>
                        </Button>
                        <input
                          type="hidden"
                          {...register(`labTasks.${i}.patientId`)}
                          name={`labTasks.${i}.patientId`}
                        />
                        <input
                          type="hidden"
                          {...register(`labTasks.${i}.sampleId`)}
                          name={`labTasks.${i}.visitId`}
                        />
                        <input
                          type="hidden"
                          {...register(`labTasks.${i}.labTaskId`)}
                          name={`labTasks.${i}.labTaskId`}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ paddingTop: ".5rem" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: ".5rem" }}
            >
              Submit
            </Button>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default BatchScanModalContainer;
