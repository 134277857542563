/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  StudiesGetAllStudiesResult,
  GetAllStudiesParams,
  StudiesAddStudyResult,
  StudiesAddStudyForm,
  StudiesGetStudyByIdResult,
  StudiesUpdateStudyResult,
  StudiesUpdateStudyForm,
  StudiesDeleteStudyResult,
  StudiesAssignSiteResult,
  StudiesRemoveSiteResult,
  StudiesRemovePatientResult,
  StudiesGetAvailableSitesResult,
  StudiesGetAvailablePatientsResult,
  StudiesGetStudyPatientResult,
  StudiesUpdateStudyPatientResult,
  StudiesUpdateStudyPatientForm,
  StudiesAssignPatientResult,
  StudiesSyncStudyResult,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAllStudies = (
  params?: GetAllStudiesParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesGetAllStudiesResult>(
    { url: `/api/Study`, method: "get", params },
    options
  );
};

export const getGetAllStudiesQueryKey = (params?: GetAllStudiesParams) => [
  `/api/Study`,
  ...(params ? [params] : []),
];

export const useGetAllStudies = <
  TData = AsyncReturnType<typeof getAllStudies>,
  TError = unknown
>(
  params?: GetAllStudiesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAllStudies>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAllStudiesQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllStudies>> = () =>
    getAllStudies(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllStudies>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const addStudy = (
  studiesAddStudyForm: StudiesAddStudyForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesAddStudyResult>(
    { url: `/api/Study`, method: "post", data: studiesAddStudyForm },
    options
  );
};

export const useAddStudy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof addStudy>,
    TError,
    { data: StudiesAddStudyForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof addStudy>,
    { data: StudiesAddStudyForm }
  > = (props) => {
    const { data } = props || {};

    return addStudy(data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof addStudy>,
    TError,
    { data: StudiesAddStudyForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const getStudyById = (
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesGetStudyByIdResult>(
    { url: `/api/Study/${studyId}`, method: "get" },
    options
  );
};

export const getGetStudyByIdQueryKey = (studyId: number) => [
  `/api/Study/${studyId}`,
];

export const useGetStudyById = <
  TData = AsyncReturnType<typeof getStudyById>,
  TError = unknown
>(
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getStudyById>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetStudyByIdQueryKey(studyId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getStudyById>> = () =>
    getStudyById(studyId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getStudyById>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studyId, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateStudy = (
  studyId: number,
  studiesUpdateStudyForm: StudiesUpdateStudyForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesUpdateStudyResult>(
    {
      url: `/api/Study/${studyId}`,
      method: "post",
      data: studiesUpdateStudyForm,
    },
    options
  );
};

export const useUpdateStudy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateStudy>,
    TError,
    { studyId: number; data: StudiesUpdateStudyForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateStudy>,
    { studyId: number; data: StudiesUpdateStudyForm }
  > = (props) => {
    const { studyId, data } = props || {};

    return updateStudy(studyId, data, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof updateStudy>,
    TError,
    { studyId: number; data: StudiesUpdateStudyForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteStudy = (
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesDeleteStudyResult>(
    { url: `/api/Study/${studyId}`, method: "delete" },
    options
  );
};

export const useDeleteStudy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteStudy>,
    TError,
    { studyId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteStudy>,
    { studyId: number }
  > = (props) => {
    const { studyId } = props || {};

    return deleteStudy(studyId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof deleteStudy>,
    TError,
    { studyId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const assignSite = (
  studyId: number,
  siteId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesAssignSiteResult>(
    { url: `/api/Study/${studyId}/sites/${siteId}`, method: "post" },
    options
  );
};

export const useAssignSite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof assignSite>,
    TError,
    { studyId: number; siteId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof assignSite>,
    { studyId: number; siteId: number }
  > = (props) => {
    const { studyId, siteId } = props || {};

    return assignSite(studyId, siteId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof assignSite>,
    TError,
    { studyId: number; siteId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const removeSite = (
  studyId: number,
  siteId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesRemoveSiteResult>(
    { url: `/api/Study/${studyId}/sites/${siteId}`, method: "delete" },
    options
  );
};

export const useRemoveSite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof removeSite>,
    TError,
    { studyId: number; siteId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof removeSite>,
    { studyId: number; siteId: number }
  > = (props) => {
    const { studyId, siteId } = props || {};

    return removeSite(studyId, siteId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof removeSite>,
    TError,
    { studyId: number; siteId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const removePatient = (
  studyId: number,
  patientId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesRemovePatientResult>(
    { url: `/api/Study/${studyId}/patient/${patientId}`, method: "delete" },
    options
  );
};

export const useRemovePatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof removePatient>,
    TError,
    { studyId: number; patientId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof removePatient>,
    { studyId: number; patientId: number }
  > = (props) => {
    const { studyId, patientId } = props || {};

    return removePatient(studyId, patientId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof removePatient>,
    TError,
    { studyId: number; patientId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const availableSites = (
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesGetAvailableSitesResult>(
    { url: `/api/Study/${studyId}/sites/available`, method: "get" },
    options
  );
};

export const getAvailableSitesQueryKey = (studyId: number) => [
  `/api/Study/${studyId}/sites/available`,
];

export const useAvailableSites = <
  TData = AsyncReturnType<typeof availableSites>,
  TError = unknown
>(
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof availableSites>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getAvailableSitesQueryKey(studyId);

  const queryFn: QueryFunction<AsyncReturnType<typeof availableSites>> = () =>
    availableSites(studyId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof availableSites>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!studyId, ...queryOptions }
  );

  return {
    queryKey,
    ...query,
  };
};

export const availablePatients = (
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesGetAvailablePatientsResult>(
    { url: `/api/Study/${studyId}/patients/available`, method: "get" },
    options
  );
};

export const getAvailablePatientsQueryKey = (studyId: number) => [
  `/api/Study/${studyId}/patients/available`,
];

export const useAvailablePatients = <
  TData = AsyncReturnType<typeof availablePatients>,
  TError = unknown
>(
  studyId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof availablePatients>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getAvailablePatientsQueryKey(studyId);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof availablePatients>
  > = () => availablePatients(studyId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof availablePatients>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!studyId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const getStudyPatient = (
  studyId: number,
  patientId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesGetStudyPatientResult>(
    {
      url: `/api/Study/${studyId}/patients/${patientId}/manage`,
      method: "get",
    },
    options
  );
};

export const getGetStudyPatientQueryKey = (
  studyId: number,
  patientId: number
) => [`/api/Study/${studyId}/patients/${patientId}/manage`];

export const useGetStudyPatient = <
  TData = AsyncReturnType<typeof getStudyPatient>,
  TError = unknown
>(
  studyId: number,
  patientId: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getStudyPatient>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStudyPatientQueryKey(studyId, patientId);

  const queryFn: QueryFunction<AsyncReturnType<typeof getStudyPatient>> = () =>
    getStudyPatient(studyId, patientId, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getStudyPatient>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(studyId && patientId), ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

export const updateStudyPatientAssignment = (
  studyId: number,
  patientId: number,
  studiesUpdateStudyPatientForm: StudiesUpdateStudyPatientForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesUpdateStudyPatientResult>(
    {
      url: `/api/Study/${studyId}/patients/${patientId}/manage`,
      method: "post",
      data: studiesUpdateStudyPatientForm,
    },
    options
  );
};

export const useUpdateStudyPatientAssignment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof updateStudyPatientAssignment>,
    TError,
    { studyId: number; patientId: number; data: StudiesUpdateStudyPatientForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof updateStudyPatientAssignment>,
    { studyId: number; patientId: number; data: StudiesUpdateStudyPatientForm }
  > = (props) => {
    const { studyId, patientId, data } = props || {};

    return updateStudyPatientAssignment(
      studyId,
      patientId,
      data,
      requestOptions
    );
  };

  return useMutation<
    AsyncReturnType<typeof updateStudyPatientAssignment>,
    TError,
    { studyId: number; patientId: number; data: StudiesUpdateStudyPatientForm },
    TContext
  >(mutationFn, mutationOptions);
};
export const assignPatient = (
  studyId: number,
  patientId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesAssignPatientResult>(
    { url: `/api/Study/${studyId}/patients/${patientId}`, method: "post" },
    options
  );
};

export const useAssignPatient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof assignPatient>,
    TError,
    { studyId: number; patientId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof assignPatient>,
    { studyId: number; patientId: number }
  > = (props) => {
    const { studyId, patientId } = props || {};

    return assignPatient(studyId, patientId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof assignPatient>,
    TError,
    { studyId: number; patientId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const syncStudy = (
  studyId: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<StudiesSyncStudyResult>(
    { url: `/api/Study/${studyId}/sync`, method: "post" },
    options
  );
};

export const useSyncStudy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof syncStudy>,
    TError,
    { studyId: number },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof syncStudy>,
    { studyId: number }
  > = (props) => {
    const { studyId } = props || {};

    return syncStudy(studyId, requestOptions);
  };

  return useMutation<
    AsyncReturnType<typeof syncStudy>,
    TError,
    { studyId: number },
    TContext
  >(mutationFn, mutationOptions);
};
