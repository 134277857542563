import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  StudiesGetStudyPatientStudyPatientModel,
  StudiesUpdateStudyPatientForm,
} from "api";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import BackButtonComponent from "shared/components/BackButton";
import { StudyPatientStatusEnum } from "shared/models/study-patient-status.enum";
import DropDownField from "shared/forms/fields/DropDownField";

interface Props {
  studyPatient: StudiesGetStudyPatientStudyPatientModel;
  onSave(form: StudiesUpdateStudyPatientForm): void;
}

function ManageStudyPatientForm({ studyPatient, onSave }: Props) {
  const { control, handleSubmit, formState } =
    useForm<StudiesUpdateStudyPatientForm>({
      defaultValues: {
        screeningNumber: studyPatient.screeningNumber,
        status: studyPatient.status,
      },
      mode: "onChange",
    });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="screeningNumber"
            label="Screening Number"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DropDownField
            options={StudyPatientStatusEnum}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.label}
            control={control}
            name="status"
            label="Patient Status"
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(ManageStudyPatientForm);
