import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import RequiredMarker from "shared/components/RequiredMarker";
import { FormTextFieldProps } from "./FieldVariants";

type RadioOption = {
  value: boolean | number | string;
  label: string;
};

type RadioGroupFieldProps<T> = FormTextFieldProps<
  T,
  {
    options: RadioOption[];
    labelName: string;
  }
>;

function RadioGroupField<T>({
  name,
  labelName,
  control,
  rules,
  required = false,
  options,
  ...props
}: RadioGroupFieldProps<T>) {
  rules = { ...rules, required };
  const optionMap = new Map(options.map((opt) => [String(opt.value), opt]));

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <Box>
          <FormLabel>
            <>
              {labelName}
              <RequiredMarker required={required} />
            </>
          </FormLabel>
          <RadioGroup
            row
            onBlur={onBlur}
            value={value ?? null}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const radioValue = optionMap.get(event.target.value);
              onChange(radioValue?.value);
            }}
          >
            {options.map((option, index) => (
              <FormControlLabel
                {...props}
                key={index}
                value={String(option.value)}
                label={option.label}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </Box>
      )}
    />
  );
}

export default RadioGroupField;
