import React from "react";
import { Card, Stack } from "@mui/material";

type Props = React.PropsWithChildren<{}>;
function NavCard({ children }: Props) {
  return (
    <Card
      sx={{
        padding: "2rem",
        backgroundColor: "primary.light",
        borderRadius: "1rem",
        display: "flex",
        flex: "1",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2rem",
        transition:
          "background-color 0.25s ease-in-out, color 0.25s ease-in-out",
        "& svg": {
          color: "primary.contrastText",
          backgroundColor: "primary.black",
          width: "fit-content",
          padding: "2rem",
          borderRadius: "50%",
          minWidth: "50px",
          minHeight: "50px",
        },
      }}
    >
      <Stack
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {children}
      </Stack>
    </Card>
  );
}
export default NavCard;
