export const StudyPatientStatusEnum = [
  {
    label: "NonQualify",
    key: 0,
  },
  {
    label: "Prescreened",
    key: 1,
  },
  {
    label: "Screened",
    key: 2,
  },
  {
    label: "RunIn",
    key: 3,
  },
  {
    label: "Randomized",
    key: 4,
  },
  {
    label: "FollowUp",
    key: 5,
  },
  {
    label: "Dropped",
    key: 6,
  },
  {
    label: "Completed",
    key: 7,
  },
  {
    label: "ScreenFailed",
    key: 8,
  },
  {
    label: "RunInFailed",
    key: 9,
  },

];

export function getStudyPatientStatus(key: number) {
  return StudyPatientStatusEnum.find((f) => f.key === key)?.label;
}
