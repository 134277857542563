import { Box, Grid } from "@mui/material";
import {
  VisitsGetVisitByIdVisitModel,
  VisitsUpdateVisitForm,
} from "api";
import { useForm } from "react-hook-form";
import BackButtonComponent from "shared/components/BackButton";
import NumberField from "shared/forms/fields/NumberField";
import TextField from "shared/forms/fields/TextField";
import FormErrorAlert from "shared/forms/FormErrorAlert";
import SubmitButton from "shared/forms/SubmitButton";
import withFormStatus from "shared/forms/withFormStatus";
import StickyFooter from "shared/layout/StickyFooter";
import RemoveVisit from "studies/components/RemoveVisit";

interface Props {
  visit: VisitsGetVisitByIdVisitModel;
  onSave(form: VisitsUpdateVisitForm): void;
}

function EditVisitForm({ visit, onSave }: Props) {
  const { control, handleSubmit } = useForm<VisitsUpdateVisitForm>({
    defaultValues: visit,
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            <Grid item sm={6}>
              <TextField
                control={control}
                name="name"
                label="Visit Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item sm={6}>
              <NumberField
                control={control}
                name="sequence"
                label="Sequence"
                fullWidth
                rules={{
                  min: 1,
                }}
                required
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                control={control}
                name="referenceId"
                label="Id"
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <StickyFooter>
        <BackButtonComponent />
        <RemoveVisit visitId={visit.id} studyId={visit.studyId} />
        <SubmitButton variant="save" />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditVisitForm);
