import { Box, Card, CardContent, CardHeader, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { parseISO } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import { useGetImportHistory } from "api";
import { useDataGrid } from "shared";
import StickyFooter from "shared/layout/StickyFooter";

const columns = (studyId: number): GridColumns => [
  {
    field: "importDate",
    headerName: "Import Date",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${studyId}/imports/${row.id}`}>
        <DateTimeDisplay
          date={parseISO(row.importDate)}
          dateFormat="MM/dd/yyyy hh:mm a"
        />
      </Link>
    ),
  },
  {
    field: "importedBy",
    headerName: "Imported By",
    flex: 1,
    renderCell: ({ row }) => <>{row.importedBy}</>,
  },
];

interface Props {
  studyId: number;
}

function ImportHistoryList({ studyId }: Props) {
  const { grid, query } = useDataGrid("import-history", columns(studyId));
  const { data, isFetching } = useGetImportHistory(
    {
      ...query,
      studyId: studyId,
    },
    { query: { keepPreviousData: true } }
  );
  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardHeader title="Import History" />
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                autoHeight
                getRowId={(row) => row.importDate}
              />
            </Box>
          </Box>
          <StickyFooter></StickyFooter>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ImportHistoryList;
