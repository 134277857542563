import { Grid, Box } from "@mui/material";
import TextField from "../../shared/forms/fields/TextField";
import { useForm } from "react-hook-form";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import withFormStatus from "../../shared/forms/withFormStatus";
import { UsersAddUserForm } from "../../api";
import PhoneField from "../../shared/forms/fields/PhoneField";
import StickyFooter from "../../shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";
import RadioGroupField from "shared/forms/fields/RadioGroupField";
import { GetUserRoleList } from "shared/models/user-roles";

interface Props {
  onSave(form: UsersAddUserForm): void;
}

function AddUserForm({ onSave }: Props) {
  const { control, handleSubmit, formState } = useForm<UsersAddUserForm>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  const re = /^[0-9\b]{4}$/;
  const noSpaces = /^\S*$/;

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="username"
            label="Username"
            helperText="Enter a username with no spaces."
            rules={{ 
              validate:{
                checkName: (value: string) =>
                  noSpaces.test(value)
                },
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            control={control}
            name="name.first"
            label="First Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            control={control}
            name="name.middle"
            label="Middle Name"
            fullWidth
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextField
            control={control}
            name="name.last"
            label="Last Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <TextField
            control={control}
            name="contact.email"
            label="Contact Email"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4} xs={6}>
          <PhoneField
            control={control}
            name="contact.phone"
            label="Contact Phone"
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            type="password"
            control={control}
            name="pin"
            label="PIN"
            rules={{ 
              validate:{
                checkPIN: (value: string) =>
                  re.test(value)
                },
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={12} xs={6}>
          <RadioGroupField
            control={control}
            labelName="User Type"
            name="userTypeId"
            options={GetUserRoleList()}
            required
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent text="Cancel" />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AddUserForm);
