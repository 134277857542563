import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AssignSite from "studies/components/AssignSite";

import { StudiesAssignSiteResult, useAssignSite, useAvailableSites, useGetStudyById } from "../../api";

function AssignSitePage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();

  const studyId = Number(studyIdParam);

  const { data } = useGetStudyById(studyId);
  const { mutate, ...status } = useAssignSite({
    mutation: {
      onSuccess(data: StudiesAssignSiteResult) {
        enqueueSnackbar("Site Assigned", {
          variant: "success",
        });
        navigate(`/studies/${studyId}/sites`);
      },
    },
  });

  const availableSiteResults = useAvailableSites(studyId);

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      {data && data.study && availableSiteResults.data?.sites && (
        <>
          <CardHeader title="Assign Site" />

          <CardContent>
            <AssignSite
              availableSites={availableSiteResults.data.sites}
              status={status}
              onAssign={(siteId) =>
                mutate({
                  siteId: siteId,
                  studyId: studyId,
                })
              }
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default AssignSitePage;
