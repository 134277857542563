import { Route, Routes } from "react-router";

import NurseTabs from "./components/NurseTabs";
import AddPatientPage from "./pages/AddPatientPage";
import LookupPatientPage from "./pages/LookupPatientPage";
import PatientDetailsPage from "./pages/PatientDetailsPage";
import PatientSamplesPage from "./pages/PatientSamplesPage";
import PatientVisitsPage from "./pages/PatientVisitsPage";

function NurseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LookupPatientPage />} />
      <Route path="patients/lookup" element={<LookupPatientPage />} />
      <Route path="patients/add" element={<AddPatientPage />} />
      <Route
        path="patients/:patientId/studies/:studyId/"
        element={<NurseTabs />}
      >
        <Route path="" element={<PatientDetailsPage />} />
        <Route path="samples" element={<PatientSamplesPage />} />
        <Route path="visits" element={<PatientVisitsPage />} />
      </Route>
    </Routes>
  );
}

export default NurseRoutes;
