import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { SitesAddSiteResult, useAddSite } from "../../api";
import AddSiteForm from "../forms/AddSiteForm";

function AddSitePage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, ...status } = useAddSite({
    mutation: {
      onSuccess(data: SitesAddSiteResult) {
        enqueueSnackbar("Site Added", {
          variant: "success",
        });
        navigate(`/sites/${data.item?.id}`);
      },
    },
  });

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Site" />

      <CardContent>
        <AddSiteForm
          onSave={(form) => mutate({ data: form })}
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default AddSitePage;
