/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  AuditGetAuditLogResult,
  GetAuditLogsParams,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getAuditLogs = (
  params?: GetAuditLogsParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<AuditGetAuditLogResult>(
    { url: `/Audit`, method: "get", params },
    options
  );
};

export const getGetAuditLogsQueryKey = (params?: GetAuditLogsParams) => [
  `/Audit`,
  ...(params ? [params] : []),
];

export const useGetAuditLogs = <
  TData = AsyncReturnType<typeof getAuditLogs>,
  TError = unknown
>(
  params?: GetAuditLogsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getAuditLogs>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetAuditLogsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getAuditLogs>> = () =>
    getAuditLogs(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAuditLogs>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};
