import { useForm } from "react-hook-form";
import SubmitButton from "shared/forms/SubmitButton";
import { Grid, Box } from "@mui/material";
import { StudiesGetAvailablePatientsPatientModel } from "../../api";
import StickyFooter from "../../shared/layout/StickyFooter";
import DropDownField from "shared/forms/fields/DropDownField";
import withFormStatus from "shared/forms/withFormStatus";
import { FormErrorAlert } from "shared/forms";

interface Props {
  availablePatients: StudiesGetAvailablePatientsPatientModel[];
  onAssign(patientId: number): void;
}

function AssignPatient({ onAssign, availablePatients }: Props) {
  const { control, handleSubmit, formState } = useForm<{
    patientId?: number;
  }>({
    defaultValues: undefined,
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => {
    if (form.patientId) onAssign(form.patientId);
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <DropDownField
            options={availablePatients}
            getOptionKey={(opt: StudiesGetAvailablePatientsPatientModel) =>
              opt.id
            }
            getOptionLabel={(opt: StudiesGetAvailablePatientsPatientModel) =>
              `${opt.initials} (${opt.referenceId})`
            }
            control={control}
            name="patientId"
            label="Select Patient"
            required
          />
        </Grid>
      </Grid>

      <StickyFooter>
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AssignPatient);
