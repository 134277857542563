import { Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icons } from "../../theme";
import NavCard from "../../shared/components/NavCard";
import { NavLink } from "react-router-dom";

function NurseDashboard() {
  return (
    <Grid container spacing={2} p={3}>
      <Grid
        item
        xs={6}
        lg={4}
        display={"flex"}
        sx={{
          "& a": {
            width: "100%",
            textDecoration: "none",
            "&: hover": {
              "& .MuiCard-root": {
                backgroundColor: "primary.dark",
                color: "primary.contrastText",
              },
            },
          },
        }}
      >
        <NavLink to="/nurses/patients/lookup">
          <NavCard>
            <FontAwesomeIcon icon={icons.patient} size="lg"></FontAwesomeIcon>
            <Typography variant="h3" textAlign={"center"}>
              Patients
            </Typography>
          </NavCard>
        </NavLink>
      </Grid>
    </Grid>
  );
}
export default NurseDashboard;
