import { Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SampleTaskModalContainer from "studies/components/SampleTaskModalContainer";
import SampleForm from "studies/forms/SampleForm";

import { useGetAllVisits, useGetSampleById, useUpdateSample } from "../../api";

function SampleDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();

  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const { sampleId: sampleIdParam } = useParams<{ sampleId: string }>();
  const studyId = Number(studyIdParam);
  const sampleId = Number(sampleIdParam);
  const [open, setOpen] = useState(false);

  const { data } = useGetSampleById(sampleId);

  const allVisitsResults = useGetAllVisits({ studyId, pageSize: 0 });

  const { mutate, ...status } = useUpdateSample({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Sample Updated", {
          variant: "success",
        });
      },
    },
  });

  if (!allVisitsResults.data?.items || allVisitsResults.data?.count === 0)
    return <Typography>Please Add Samples</Typography>;

  if (!data) return null;

  return (
    <>
      <SampleTaskModalContainer sampleId={sampleId} open={open} setOpen={setOpen} />
      <Card elevation={0} sx={{ overflow: "unset" }}>
        <CardHeader title="Edit Sample" />
        <CardContent>
          <SampleForm
            setOpen={(state) => setOpen(state)}
            studyId={studyId}
            visits={allVisitsResults.data?.items}
            onSave={(form) =>
              mutate({
                sampleId,
                data: form,
              })
            }
            status={status}
            sample={data?.sample}
          />
        </CardContent>
        <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Scan
          </Button>
      </Card>
    </>
  );
}
export default SampleDetailsPage;
