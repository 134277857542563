import { Button } from "@mui/material";
import { useResendConfirmationEmail } from "api";
import { useSnackbar } from "notistack";

interface Props {
  userId: number;
  enabled: boolean;
}

function ResendConfirmationEmailButton({ userId, enabled }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate } = useResendConfirmationEmail({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Confirmation Email Resent", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Failed to Send Confirmation Email", {
          variant: "error",
        });
      },
    },
  });
  return (
    <Button
      disabled={!enabled}
      onClick={() => {
        mutate({ id: userId });
      }}
    >
      Resend Confirmation Email
    </Button>
  );
}

export default ResendConfirmationEmailButton;
