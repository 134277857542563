/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * QRS Sample Management Backend
 * OpenAPI spec version: v3
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "react-query";
import type {
  ImportGetImportHistoryResult,
  GetImportHistoryParams,
  ImportGetImportHistoryDetailsResult,
} from "../backend.schemas";
import { apiRequest } from ".././client";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getImportHistory = (
  params?: GetImportHistoryParams,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<ImportGetImportHistoryResult>(
    { url: `/api/Import`, method: "get", params },
    options
  );
};

export const getGetImportHistoryQueryKey = (
  params?: GetImportHistoryParams
) => [`/api/Import`, ...(params ? [params] : [])];

export const useGetImportHistory = <
  TData = AsyncReturnType<typeof getImportHistory>,
  TError = unknown
>(
  params?: GetImportHistoryParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getImportHistory>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImportHistoryQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getImportHistory>> = () =>
    getImportHistory(params, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getImportHistory>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getImportHistoryDetails = (
  id: number,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<ImportGetImportHistoryDetailsResult>(
    { url: `/api/Import/${id}`, method: "get" },
    options
  );
};

export const getGetImportHistoryDetailsQueryKey = (id: number) => [
  `/api/Import/${id}`,
];

export const useGetImportHistoryDetails = <
  TData = AsyncReturnType<typeof getImportHistoryDetails>,
  TError = unknown
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getImportHistoryDetails>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetImportHistoryDetailsQueryKey(id);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getImportHistoryDetails>
  > = () => getImportHistoryDetails(id, requestOptions);

  const query = useQuery<
    AsyncReturnType<typeof getImportHistoryDetails>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
