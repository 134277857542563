import { StudiesGetStudyByIdStudyModel, useDeleteStudy } from "api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import DeleteButton from "shared/components/DeleteButton";

interface Props {
  study: StudiesGetStudyByIdStudyModel;
}

function DeleteStudyForm({ study }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, error, ...status } = useDeleteStudy();

  return (
    <DeleteButton
      item="Study"
      status={status}
      error={error}
      onConfirm={() => {
        mutate(
          {
            studyId: study.id,
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Study deleted", {
                variant: "success",
              });
              navigate(`/studies`);
            },
          }
        );
      }}
    />
  );
}

export default DeleteStudyForm;
