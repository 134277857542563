import { Box, Checkbox, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import RequiredMarker from "shared/components/RequiredMarker";
import { FormTextFieldProps } from "./FieldVariants";

type CheckboxFieldProps<T> = FormTextFieldProps<
  T,
  {
    labelName: string;
  }
>;

function CheckboxField<T>({
  name,
  labelName,
  control,
  rules,
  required = false,
  ...props
}: CheckboxFieldProps<T>) {
  rules = { ...rules, required };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <Box>
          <FormLabel>
            <>
              {labelName}
              <RequiredMarker required={required} />
            </>
          </FormLabel>
          <Checkbox
            {...props}
            checked={(value as boolean) ?? false}
            onChange={(e) => onChange(e.target.checked)}
          />
        </Box>
      )}
    />
  );
}

export default CheckboxField;
