import { PatientsGetPatientByIdPatientModel, useDeletePatient } from "api";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import DeleteButton from "shared/components/DeleteButton";

interface Props {
  patient: PatientsGetPatientByIdPatientModel;
}

function DeletePatientForm({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate, error, ...status } = useDeletePatient();

  return (
    <DeleteButton
      item="Patient"
      status={status}
      error={error}
      onConfirm={() => {
        mutate(
          {
            patientId: patient.id!,
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Patient deleted", {
                variant: "success",
              });
              navigate(`/patient`);
            },
          }
        );
      }}
    />
  );
}

export default DeletePatientForm;
