import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useGetAllStudies } from "../../api";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";

const columns: GridColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${row.id}`}>
        {row.name}
      </Link>
    ),
  },
  {
    field: "referenceId",
    headerName: "Id",
    flex: 1,
    valueGetter: ({ row }) => row.referenceId,
  },
  {
    field: "sites",
    headerName: "Sites",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${row.id}/sites`}>
        View Sites
      </Link>
    ),
  },
  {
    field: "patients",
    headerName: "Patients",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${row.id}/patients`}>
        View Patients
      </Link>
    ),
  },
  {
    field: "visits",
    headerName: "Visits",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${row.id}/visits`}>
        View Visits
      </Link>
    ),
  },
  {
    field: "samples",
    headerName: "Samples",
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/studies/${row.id}/samples`}>
        View Samples
      </Link>
    ),
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"Study List"}
      addButtonLabel="Add Study"
      onAdd={() => navigate(`/studies/add`)}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function StudyList() {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "studies",
    columns
  );
  const { data, isFetching } = useGetAllStudies(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default StudyList;
