import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PhoneDisplay from "shared/components/display/PhoneDisplay";

import { useGetAllUsers } from "../../api";
import { DataGridToolbar, useDataGrid } from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";

const columns: GridColumns = [
  {
    field: "username",
    headerName: "Username",
    flex: 1,
    sortable: true,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/users/${row.id}`}>
        {row.username}
      </Link>
    ),
  },
  {
    field: "name.first",
    headerName: "First Name",
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) => row.name.first,
  },
  {
    field: "name.middle",
    headerName: "Middle Name",
    flex: 1,
    valueGetter: ({ row }) => row.name.middle,
  },
  {
    field: "name.last",
    headerName: "Last Name",
    flex: 1,
    valueGetter: ({ row }) => row.name.last,
  },
  {
    field: "contact.email",
    headerName: "Email",
    flex: 1,
    valueGetter: ({ row }) => row.contact.email,
  },
  {
    field: "contact.phone",
    headerName: "Phone",
    flex: 1,
    renderCell: ({ row }) => <PhoneDisplay phone={row.contact?.phone} />,
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={"User List"}
      addButtonLabel="Add User"
      onAdd={() => navigate(`/users/add`)}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function UserList() {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "studies",
    columns
  );
  const { data, isFetching } = useGetAllUsers(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default UserList;
