import HomeRoutes from "home/HomeRoutes";
import NurseRoutes from "nurses/NurseRoutes";
import { Route, Routes } from "react-router-dom";
import Shell from "shared/layout/Shell";
import StudyRoutes from "studies/StudyRoutes";
import PatientRoutes from "./patients/PatientRoutes";
import SiteRoutes from "./sites/SiteRoutes";
import UserRoutes from "./users/UserRoutes";
import { useGetCurrentUser } from "api";
import { canManageStudies, hasAdminRole, hasCoordinatorRole } from "shared";
import AccountRoutes from "account/AccountRoutes";
import AuditLogRoutes from "audits/AuditRoutes";
import LabRoutes from "lab/LabRoutes";
import ExportRoutes from "export/ExportRoutes";

function AppRoutes() {
  const { data } = useGetCurrentUser();

  if (!data?.user) return null;
  return (
    <Routes>
      <Route element={<Shell />}>
        <Route path="/" element={<HomeRoutes />} />
        <Route path="/account/*" element={<AccountRoutes />} />
        {canManageStudies(data.user) && (
          <>
            <Route path="/sites/*" element={<SiteRoutes />} />
            <Route path="/studies/*" element={<StudyRoutes />} />
            <Route path="/patients/*" element={<PatientRoutes />} />
            <Route path="/auditLogs/*" element={<AuditLogRoutes />} />
            <Route path="/lab/*" element={<LabRoutes />} />
          </>
        )}
        {hasAdminRole(data.user) && (
          <>
            <Route path="/users/*" element={<UserRoutes />} />
            <Route path="/export/*" element={<ExportRoutes />} />
          </>
        )}
        {hasCoordinatorRole(data.user) && (
          <Route path="/nurses/*" element={<NurseRoutes />} />
        )}
        <Route path="*" element={<HomeRoutes />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
