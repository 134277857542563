import { CardContent, CardHeader, Grid } from "@mui/material";
import { useParams } from "react-router";
import VisitDetails from "lab/components/VisitDetails";
import StudyVisitInformation from "lab/components/StudyVisitInformation";
import {
  useGetAllUsers,
  useGetPatientScheduledVisitDetails,
  } from "api";
import StickyFooter from "shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";

function VisitDetailsPage() {
  const { patientId: patientIdParam, visitId: visitIdParam } =
    useParams<{ patientId: string; visitId: string }>();

  const patientId = Number(patientIdParam);
  const visitId = Number(visitIdParam);

  const patientScheduledVisit = useGetPatientScheduledVisitDetails(
    patientId,
    visitId
  );

  const users = useGetAllUsers({
    userTypes: ["LabTechnician", "Admin"],
    pageSize: 0,
  });

  if (!patientScheduledVisit.data?.patientVisit || !users.data?.items)
    return null;

  return (
    <>
      <CardHeader title="Study-Patient Info" />
      <CardContent>
        <StudyVisitInformation
          patientId={patientId}
          visitId={visitId}
          hideVisitInfomation={true}
        />
        <Grid container spacing={2} p={3}>
          <Grid item xs={6} display={"flex"} sx={{ width: "100%" }}>
            {patientScheduledVisit.data.patientVisit && (
              <VisitDetails visit={patientScheduledVisit.data.patientVisit} />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <StickyFooter>
        <BackButtonComponent text="Back to list" to="/lab" />
      </StickyFooter>
    </>
  );
}

export default VisitDetailsPage;
