import { Route, Routes } from "react-router";

import AddPatientPage from "./pages/AddPatientPage";
import PatientDetailsPage from "./pages/PatientDetailsPage";
import PatientListPage from "./pages/PatientListPage";

function PatientRoutes() {
  return (
    <Routes>
      <Route path="" element={<PatientListPage />} />
      <Route path="add" element={<AddPatientPage />} />
      <Route path=":patientId" element={<PatientDetailsPage />} />
    </Routes>
  );
}

export default PatientRoutes;
