import React from "react";
import { Stack } from "@mui/material";

type Props = React.PropsWithChildren<{}>;

function StickyFooter({ children }: Props) {
  return (
    <Stack
      p={2}
      direction="row"
      justifyContent="flex-end"
      sx={{
        position: "fixed",
        bottom: "0",
        left: "0",
        zIndex: "2",
        marginTop: "1rem",
        width: "calc(100% - 32px)",
        backgroundColor: "primary.black",
      }}
    >
      {children}
    </Stack>
  );
}

export default StickyFooter;
