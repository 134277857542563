import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Box, Card, CardContent } from "@mui/material";
import { useDataGrid } from "../../shared";
import { NurseGetPatientVisitsPatientVisitModel } from "api";

const columns: GridColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: ({ row }) => row.name,
  },
  {
    field: "sequence",
    headerName: "Sequence",
    flex: 1,
    valueGetter: ({ row }) => row.sequence,
  },
  {
    field: "referenceId",
    headerName: "Visit Id",
    flex: 1,
    valueGetter: ({ row }) => row.referenceId,
  },
  {
    field: "accessionNumber",
    headerName: "Accession #",
    flex: 1,
    valueGetter: ({ row }) => row.accessionNumber,
  },
  {
    field: "completedAt",
    headerName: "Completed At",
    flex: 1,
    valueGetter: ({ row }) => `${row.completedAt ?? "N/A"}`,
  },
  {
    field: "samples",
    headerName: "Samples",
    flex: 1,
    valueGetter: ({ row }) => `${row.samplesCollected}/${row.samplesNeeded}`,
  },
];

interface Props {
  patientVisits: NurseGetPatientVisitsPatientVisitModel[];
  studyId: number;
  onVisitSelect(visitId: number): void;
}

function PatientVisitList({ patientVisits, studyId, onVisitSelect }: Props) {
  const { grid } = useDataGrid("patients-visits", columns);
  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={patientVisits}
                rowCount={patientVisits.length}
                autoHeight
                onRowClick={(row) => {
                  onVisitSelect(Number(row.id));
                }}
                hideFooter={true}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PatientVisitList;
