import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

import {
  useGetAllUsers,
  useGetStudyById,
  useSyncStudy,
  useUpdateStudy,
} from "../../api";
import EditStudyForm from "../forms/EditStudyForm";

function StudyDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const { data } = useGetStudyById(studyId);
  const { mutate, ...status } = useUpdateStudy();
  const syncStudy = useSyncStudy();
  const userData = useGetAllUsers();
  if (!userData.data?.items) return null;

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit Study" />

      <CardContent>
        {data && data.study && (
          <EditStudyForm
            users={userData.data.items}
            status={status}
            study={data.study}
            onSync={(study) => {
              syncStudy.mutate(
                {
                  studyId: study.id,
                },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Study Sync successful", {
                      variant: "success",
                    });
                  },
                  onError: (error, variables, context) => {
                    enqueueSnackbar("Error syncing study", {
                      variant: "error",
                    });
                  },
                }
              );
            }}
            onSave={(form) =>
              mutate(
                { studyId, data: form },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Study Updated", {
                      variant: "success",
                    });
                  },
                }
              )
            }
          />
        )}
      </CardContent>
    </Card>
  );
}

export default StudyDetailsPage;
