import { Grid, Box } from "@mui/material";
import TextField from "../../shared/forms/fields/TextField";
import DropDownField from "../../shared/forms/fields/DropDownField";
import { useForm } from "react-hook-form";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import withFormStatus from "../../shared/forms/withFormStatus";
import { StudiesAddStudyForm, UsersGetAllUsersUserModel } from "../../api";
import StickyFooter from "../../shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";

interface Props {
  users: UsersGetAllUsersUserModel[];
  onSave(form: StudiesAddStudyForm): void;
}

function AddStudyForm({ onSave, users }: Props) {
  const { control, handleSubmit, formState } = useForm<StudiesAddStudyForm>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item sm={4}>
          <TextField
            control={control}
            name="name"
            label="Study Name"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            control={control}
            name="referenceId"
            label="CC Id"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            control={control}
            name="siteId"
            label="Site Id"
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            control={control}
            name="description"
            label="Description"
            fullWidth
          />
        </Grid>
        <Grid item sm={4}>
          <DropDownField
            options={users}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name?.first} ${opt.name?.last}`}
            control={control}
            name="coordinatorId"
            label="Coordinator"
            required
          />
        </Grid>
        <Grid item sm={4}>
          <DropDownField
            options={users}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name?.first} ${opt.name?.last}`}
            control={control}
            name="principalInvestigatorId"
            label="Principal Investigator"
            required
          />
        </Grid>
        <Grid item sm={4}>
          <DropDownField
            options={users}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name?.first} ${opt.name?.last}`}
            control={control}
            name="researchAssistantId"
            label="Research Assistant"
          />
        </Grid>
        <Grid item sm={6}>
          <DropDownField
            options={users}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name?.first} ${opt.name?.last}`}
            control={control}
            name="labManagerId"
            label="Lab Manager"
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            control={control}
            name="labEmail"
            label="Lab Email"
            fullWidth
            required
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            control={control}
            name="labVendor1"
            label="Lab Vendor 1"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            control={control}
            name="labVendor2"
            label="Lab Vendor 2"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            control={control}
            name="labVendor3"
            label="Lab Vendor 3"
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            control={control}
            name="labVendor4"
            label="Lab Vendor 4"
            fullWidth
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <BackButtonComponent text="Cancel" />
        <SubmitButton variant="save" disabled={!formState.isValid} />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AddStudyForm);
