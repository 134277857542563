import { Tab, Tabs } from "@mui/material";
import { Outlet, useParams } from "react-router";
import { Link, useMatch } from "react-router-dom";

function NurseTabs() {
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const patientId = Number(patientIdParam);
  const studyId = Number(studyIdParam);

  const parentMatch = useMatch("/nurses/patients/:patientId/studies/:studyId/");
  const routeMatch = useMatch(
    "/nurses/patients/:patientId/studies/:studyId/:pattern"
  );
  const currentTab = parentMatch?.pathname ?? routeMatch?.pathname;
  const basePath = `/nurses/patients/${patientId}/studies/${studyId}`;

  return (
    <>
      <Tabs
        value={currentTab}
        sx={{
          backgroundColor: "gray.light",
          "& .Mui-selected": { backgroundColor: "gray.contrastText" },
        }}
      >
        <Tab
          label="Patient Details"
          value={basePath}
          to={basePath}
          component={Link}
        />
        <Tab
          label="Visits"
          value={`${basePath}/visits`}
          to={`${basePath}/visits`}
          component={Link}
        />
        <Tab
          label="Samples"
          value={`${basePath}/samples`}
          to={`${basePath}/samples`}
          component={Link}
        />
      </Tabs>
      <Outlet />
    </>
  );
}

export default NurseTabs;
