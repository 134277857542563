import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import {
  StudiesGetStudyByIdStudyModel,
  useGetAllStudyPatients,
} from "../../api";
import {
  DataGridToolbar,
  getStudyPatientStatus,
  useDataGrid,
} from "../../shared";
import { DataGridToolbarProps } from "../../shared/grid/DataGridToolbar";
import RemovePatient from "./RemovePatient";

function Toolbar(
  study: StudiesGetStudyByIdStudyModel,
  props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">
) {
  const navigate = useNavigate();
  return (
    <DataGridToolbar
      header={`${study.name} - Patient List`}
      addButtonLabel="Assign Patient"
      onAdd={() => navigate(`/studies/${study.id}/patients/assign`)}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

interface Props {
  study: StudiesGetStudyByIdStudyModel;
}

function AssignedPatientList({ study }: Props) {
  const columns = (studyId: number): GridColumns => [
    {
      field: "initials",
      headerName: "Initials",
      flex: 1,
      sortable: true,
      renderCell: ({ row }) => (
        <Link
          component={RouterLink}
          to={`/studies/${studyId}/patients/${row.id}`}
        >
          {`${row.initials}`}
        </Link>
      ),
    },
    {
      field: "referenceId",
      headerName: "Patient Id",
      flex: 1,
      valueGetter: ({ row }) => row.referenceId,
    },
    {
      field: "screeningNumber",
      headerName: "Screening Number",
      flex: 1,
      valueGetter: ({ row }) => row.screeningNumber,
    },
    {
      field: "status",
      headerName: "Patient Status",
      flex: 1,
      valueGetter: ({ row }) => getStudyPatientStatus(row.status) ?? "N/A",
    },
    {
      field: "id",
      headerName: "Remove",
      flex: 1,
      renderCell: ({ row }) => (
        <RemovePatient
          studyId={studyId}
          patientId={row.id}
          onDelete={() => {
            refetch();
          }}
        />
      ),
    },
  ];
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "patients",
    columns(study.id)
  );
  const { data, isFetching, refetch } = useGetAllStudyPatients(
    {
      ...query,
      studyId: study.id,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: (x) => Toolbar(study, x),
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default AssignedPatientList;
