import { Card, CardContent, CardHeader } from "@mui/material";
import { DownloadReportParams, useDownloadReport, useGetAllStudies } from "api";
import ExportSampleReportForm from "export/forms/ExportSampleReportForm";
import { useState, useEffect } from "react";
import { saveAs } from "file-saver";

function ExportPage() {
  const [form, setForm] = useState<DownloadReportParams | undefined>(undefined);

  const studies = useGetAllStudies({
    pageSize: 0,
  });
  const { refetch } = useDownloadReport(
    {
      studyId: form?.studyId,
      start: form?.start,
      end: form?.end,
    },
    {
      query: {
        enabled: false, // don't run the query immediately when mounted
        cacheTime: 0, // don't cache query results
        retry: 0, // don't retry the query if it fails
        onSuccess(data: Blob) {
          const fileName = `SampleReport`;
          saveAs(data, fileName || undefined);
        },
      },
      request: {
        // interpret the response as a blob
        responseType: "blob",
      },
    }
  );

  useEffect(() => {
    if (form) refetch();
  }, [form, refetch]);

  if (!studies.data?.items) return null;
  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Export Report" />
      <CardContent>
        <ExportSampleReportForm
          studies={studies.data.items}
          onSave={(form) => {
            setForm(form);
          }}
        />
      </CardContent>
    </Card>
  );
}

export default ExportPage;
