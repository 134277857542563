import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useDataGrid } from "../../shared";
import { Grid, Box, Typography } from "@mui/material";
import TextField from "../../shared/forms/fields/TextField";
import { useForm } from "react-hook-form";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import SubmitButton from "../../shared/forms/SubmitButton";
import withFormStatus from "../../shared/forms/withFormStatus";
import {
  NurseGetPatientVisitDetailsPatientVisitModel,
  NurseUpdatePatientVisitForm,
  useGetPatientVisitDetails,
} from "api";
import StickyFooter from "shared/layout/StickyFooter";
import BackButtonComponent from "shared/components/BackButton";
import { getPatientSampleStatus } from "shared/models/patient-sample-status.enum";
import { getSampleType, SampleTypeEnum } from "shared/models/sample-type.enum";
import DateField from "shared/forms/fields/DateField";
import React from "react";

const columns: GridColumns = [
  {
    field: "sampleType",
    headerName: "Sample Type",
    flex: 1,
    valueGetter: ({ row }) => getSampleType(SampleTypeEnum, row.sampleType),
  },
  {
    field: "quantity",
    headerName: "Qty",
    flex: 1,
    valueGetter: ({ row }) => row.quantity,
  },
  {
    field: "transferTubeQuantity",
    headerName: "# of Tubes",
    flex: 1,
    valueGetter: ({ row }) => row.transferTubeQuantity ?? "--",
  },
  {
    field: "sampleKitCode",
    headerName: "Sample Kit Code",
    flex: 1,
    valueGetter: ({ row }) => row.sampleKitCode,
  },
  {
    field: "drawTime",
    headerName: "Draw Time",
    flex: 1,
    valueGetter: ({ row }) => `${row.drawTime ?? "N/A"}`,
  },
  {
    field: "kitName",
    headerName: "Kit Number",
    flex: 1,
    valueGetter: ({ row }) => `${row.kitName ?? "N/A"}`,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    valueGetter: ({ row }) => getPatientSampleStatus(row.status),
  },
];

interface BaseProps {
  onSave(form: NurseUpdatePatientVisitForm): void;
}

interface EditPatientVisitFormProps extends BaseProps {
  patientVisit: NurseGetPatientVisitDetailsPatientVisitModel;
}

function EditPatientVisitForm({
  onSave,
  patientVisit,
}: EditPatientVisitFormProps) {
  const { grid } = useDataGrid("patient-visits", columns);
  const { control, handleSubmit, formState } =
    useForm<NurseUpdatePatientVisitForm>({
      defaultValues: {
        scheduledDate: patientVisit.scheduledDate,
        accessionNumber: patientVisit.accessionNumber ?? "",
      },
      mode: "onChange",
    });
  const onSubmit = handleSubmit((form) => onSave(form));
  
  return (
    <>
      <Box onSubmit={onSubmit} component="form">
        <FormErrorAlert />
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <Typography>Visit Name: {patientVisit.name}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>Visit Sequence: {patientVisit.sequence}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>
              Visit Completed: {patientVisit.completedAt ?? "N/A"}
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography>
              Sample Collected: {patientVisit.samplesCollected}/
              {patientVisit.samplesNeeded}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <DataGrid
              {...grid}
              rows={patientVisit.samples ?? []}
              rowCount={patientVisit.samples.length}
              autoHeight
              hideFooter={true}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              control={control}
              name="accessionNumber"
              label="Accession Number"
              required
            />
          </Grid>
          <Grid item sm={6}>
            <DateField control={control} name="scheduledDate" label="Scheduled Date" />
          </Grid>
          
        </Grid>
        <StickyFooter>
                  <BackButtonComponent text="Back to List" to="/nurses/patients/lookup" />
          <SubmitButton variant="save" disabled={!formState.isValid} />
        </StickyFooter>
      </Box>
    </>
  );
}

interface EditPatientVisitContainerProps extends BaseProps {
  patientId: number;
  visitId: number;
}

function EditPatientVisitContainer({
  onSave,
  patientId,
  visitId,
}: EditPatientVisitContainerProps) {
  const { data } = useGetPatientVisitDetails(patientId, visitId);
  if (!data?.patientVisit) return <></>;
  return (
    <EditPatientVisitForm patientVisit={data.patientVisit} onSave={onSave} />
  );
}

export default withFormStatus(EditPatientVisitContainer);
