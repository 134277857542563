import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faUniversity,
  faFileInvoiceDollar,
  faBell,
  faUser,
  faAngleDown,
  faAngleRight,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faSave,
  faArrowCircleRight,
  faUndo,
  faClipboardList,
  faHeadSideMask,
  faHospital,
  faBarcode,
  faBookMedical,
  faList,
  faMapPin,
  faTrash,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

const palette = {
  primary: {
    light: "#fed070",
    main: "#f26520",
    dark: "#ee4038",
    contrastText: "#ffffff",
    black: "#000000",
  },
  secondary: {
    light: "#e3a35f",
    main: "#DC8D38",
    dark: "#9a6227",
    contrastText: "#ffcc00",
  },
  gray: {
    light: "#EBEBEB",
    main: "#D1D1D1",
    dark: "#4A4A4A",
    contrastText: "#f6f6f6",
  },
  grid: {
    altRow: "#F6F6F6",
    rowHover: "#faf0e6",
  },
};

const defaults = createTheme();

export const theme = createTheme({
  palette,

  breakpoints: {
    values: {
      xs: 0,
      sm: 900,
      md: 1200,
      lg: 1500,
      xl: 1900,
    },
  },

  components: {
    /*! buttons */
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          minHeight: "3rem",
          border: "2px solid",
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.light,
          color: palette.primary.black,
          borderRadius: ".5rem",
          boxShadow: defaults.shadows[0],
          fontSize: "1rem",

          "&:hover": {
            borderColor: palette.primary.dark,
            backgroundColor: palette.primary.dark,
            boxShadow: defaults.shadows[2],
            color: palette.primary.contrastText,
          },
          "&.Mui-disabled": {
            backgroundColor: palette.primary.light,
          },
        },

        containedPrimary: {
          color: palette.primary.black,
          borderColor: palette.primary.light,
          backgroundColor: palette.primary.light,
        },
      },
    },

    /*! links */
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
          textDecoration: "none",

          "&:hover": {
            color: palette.secondary.main,
            textDecoration: "underline",
          },
        },
      },
    },

    /*! inputs */
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: palette.gray.dark,

          "&.Mui-focused": {
            color: palette.gray.dark,
          },
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        shrink: {
          position: "relative",
          transform: "none !important",
          marginBottom: defaults.spacing(0.5),
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "&.textfield-readonly .MuiOutlinedInput-root": {
            boxShadow: "none",
            backgroundColor: palette.grid.altRow,

            "&:hover fieldset": {
              borderColor: "inherit !important",
            },

            "&.Mui-focused fieldset": {
              borderColor: "inherit !important",
            },
          },

          "&.calculated .MuiInputLabel-root": {
            color: palette.primary.main,
          },

          "&.calculated .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary.main,
          },

          "& .MuiFormHelperText-root": {
            textAlign: "right",
            marginRight: "0",
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: "#ffffff !important",
          },
          "& .Mui-selected:hover": {
            backgroundColor: `${palette.gray.light} !important`,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem",
          borderColor: palette.gray.main,
          transition: "all .5s ease",

          "& fieldset": {
            top: "0",
          },

          "& fieldset legend": {
            display: "none",
          },

          "&:hover fieldset": {
            borderColor: `${palette.gray.dark} !important`,
          },

          "&.Mui-focused fieldset": {
            borderColor: `${palette.gray.dark} !important`,
          },
        },
        notchedOutline: {
          borderColor: palette.gray.main,
          borderWidth: "2px !important",
          transition: "all .5s ease",
        },
      },
    },

    /*! paper */
    MuiPaper: {
      styleOverrides: {
        root: {
          "& .MuiGridPanelWrapper-root .MuiDataGridPanelHeader-root": {
            padding: defaults.spacing(2),
          },
          "& .MuiGridPanelWrapper-root .MuiGridPanelContent-root": {
            padding: defaults.spacing(1),
          },
          "& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root": {
            paddingTop: defaults.spacing(2),
            paddingBottom: defaults.spacing(2),
            paddingRight: defaults.spacing(1),
            paddingLeft: defaults.spacing(1),
          },

          "& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root .MuiButton-root":
            {
              flex: 1,
              marginLeft: defaults.spacing(1),
              marginRight: defaults.spacing(1),
            },
        },
        rounded: {
          borderRadius: ".5rem",
        },
      },
    },

    /*! card */
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "0",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          "& .MuiCardHeader-title": {
            textTransform: "uppercase",
            fontSize: "1.25rem",
            fontWeight: "700",
            borderRadius: "0",
          },
        },
        action: {
          margin: defaults.spacing(0),
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: defaults.spacing(2),
        },
      },
    },

    /*! data grid */
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",

          "& .MuiDataGrid-toolbarContainer": {
            padding: "0",
          },

          "& .MuiDataGrid-row:hover": {
            backgroundColor: `${palette.grid.rowHover} !important`,
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${palette.gray.main}`,
          },

          "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0,
          },

          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-columnsContainer": {
            borderBottom: `1px solid ${palette.gray.dark}`,
            boxSizing: "border-box",
          },

          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: `1px solid ${palette.secondary.main}`,
          },

          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: palette.grid.altRow,
          },
        },
      },
    },
  },
});

export const icons = {
  loan: faFileInvoiceDollar as IconProp,
  lender: faUniversity as IconProp,
  //acivity: faFileUpload as IconProp,
  //settings: faCog as IconProp,
  export: faFile as IconProp,
  alerts: faBell as IconProp,
  user: faUser as IconProp,
  logs: faList as IconProp,
  angleDown: faAngleDown as IconProp,
  angleRight: faAngleRight as IconProp,
  close: faTimes as IconProp,
  next: faArrowCircleRight as IconProp,
  save: faSave as IconProp,
  success: faCheckCircle as IconProp,
  error: faTimesCircle as IconProp,
  restore: faUndo as IconProp,
  clipboard: faClipboardList as IconProp,
  patient: faHeadSideMask as IconProp,
  study: faBookMedical as IconProp,
  hospital: faHospital as IconProp,
  site: faUniversity as IconProp,
  barcode: faBarcode as IconProp,
  visit: faMapPin as IconProp,
  trash: faTrash as IconProp,
};
