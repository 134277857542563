import { useGetStudyById } from "api";
import { useParams } from "react-router";

import SampleList from "../components/SampleList";

function SampleListPage() {
  const { studyId: studyIdParam } = useParams<{ studyId: string }>();
  const studyId = Number(studyIdParam);

  const { data } = useGetStudyById(studyId);
  if (!data?.study) return null;
  return <SampleList study={data?.study} />;
}

export default SampleListPage;
