import { Box, Card, CardContent, Grid, TextField } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  useGetAllPatients,
  useGetAllStudies,
  useGetPatientScheduledVisits,
} from "api";
import { formatISO, parseISO, startOfMonth, endOfMonth } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDataGrid } from "shared";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";
import DateField from "shared/forms/fields/DateField";
import DropDownField from "shared/forms/fields/DropDownField";

const columns: GridColumns = [
  {
    field: "scheduledDate",
    headerName: "Scheduled Date",
    flex: 1,
    renderCell: ({ row }) => (
      <DateTimeDisplay
        date={parseISO(row.scheduledDate)}
        dateFormat="MM/dd/yyyy hh:mm"
      />
    ),
  },
  {
    field: "patientId",
    headerName: "Patient Initials",
    flex: 1,
    valueGetter: ({ row }) => row.patientInitials,
  },
  {
    field: "screeningNumber",
    headerName: "Screening #",
    flex: 1,
    valueGetter: ({ row }) => row.screeningNumber,
  },
  {
    field: "studyName",
    headerName: "Study Name",
    flex: 1,
    valueGetter: ({ row }) => row.studyName,
  },
  {
    field: "visitName",
    headerName: "Visit Name",
    flex: 1,
    valueGetter: ({ row }) => row.visitName,
  },
  {
    field: "accessionNumber",
    headerName: "Accession #",
    flex: 1,
    valueGetter: ({ row }) => row.accessionNumber,
  },
  {
    field: "sampleCount",
    headerName: "Sample Count",
    flex: 1,
    valueGetter: ({ row }) => row.sampleCount,
  },
];

interface Props {
  onPatientSelect: (
    studyId: number,
    visitId: number,
    patientId: number
  ) => void;
}

function PatientScheduledVisitList({ onPatientSelect }: Props) {
  const studies = useGetAllStudies({
    pageSize: 100,
  }).data;
  const patients = useGetAllPatients({
    pageSize: 100,
  }).data;
  const { control, register, getValues, watch } = useForm<{
    studyId?: number;
    patientId?: number;
    start?: string;
    end?: string;
    searchQuery: string;
  }>({
    defaultValues: {
      studyId: undefined,
      patientId: undefined,
      start: formatISO(startOfMonth(new Date()), {
        representation: "date",
      }),
      end: formatISO(endOfMonth(new Date()), {
        representation: "date",
      }),
    },
  });
  const { patientId, studyId, searchQuery, start, end } = watch();
  const { grid } = useDataGrid<{
    studyName?: string;
    patientId?: string;
    start?: string;
    end?: string;
    searchQuery: string;
  }>("patients-visit-schedule", columns);

  const { data, isFetching, refetch } = useGetPatientScheduledVisits(
    {
      start: getValues("start"),
      end: getValues("end"),
      patientId: getValues("patientId"),
      studyId: getValues("studyId"),
      searchQuery: getValues("searchQuery"),
    },
    { query: { keepPreviousData: true } }
  );

  useEffect(() => {
    refetch({
      cancelRefetch: !patientId || !studyId || !searchQuery || !start || !end,
    });
  }, [patientId, studyId, searchQuery, start, end, refetch]);

  return (
    <Card elevation={0} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <DateField control={control} name="start" label="Start" />
                </Grid>
                <Grid item xs={2}>
                  <DateField control={control} name="end" label="End" />
                </Grid>
                <Grid item xs={2}>
                  <DropDownField
                    options={studies?.items ?? []}
                    getOptionKey={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    control={control}
                    name="studyId"
                    label="Select Study"
                  />
                </Grid>
                <Grid item xs={2}>
                  <DropDownField
                    options={patients?.items ?? []}
                    getOptionKey={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.initials}
                    control={control}
                    name="patientId"
                    label="Select Patient"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField label="Search" {...register("searchQuery")} />
                </Grid>
              </Grid>
              <DataGrid
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                getRowId={(row) =>
                  `${row.patientId}-${row.visitId}-${row.studyId}`
                }
                onRowClick={({ row }) => {
                  onPatientSelect(row.studyId, row.visitId, row.patientId);
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PatientScheduledVisitList;
