import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { parseISO } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import DateTimeDisplay from "shared/components/display/DateTimeDisplay";

import { LabTechnicianGetPatientVisitDetailsPatientVisitModel } from "../../api";

interface BaseProps {}

interface VisitDetailsProps extends BaseProps {
  visit: LabTechnicianGetPatientVisitDetailsPatientVisitModel;
}

function VisitDetails({ visit }: VisitDetailsProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Card
      elevation={0}
      sx={{
        flex: 1,
        padding: "1rem",
        border: "solid thin",
        borderRadius: ".5rem",
        borderColor: "grey.300",
      }}
    >
      <CardHeader title="Visit Info" sx={{ paddingTop: "8px" }} />
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box>
              <Grid container spacing={4}>
                <Grid item sm={6} xs={6}>
                  <strong>Name: </strong>
                  {visit.name}
                </Grid>
                <Grid item sm={5} xs={5}>
                  <strong>Reference Id: </strong>
                  {visit.referenceId}
                </Grid>
                <Grid item sm={6} xs={6}>
                  <strong>Accession Number: </strong>
                  {visit.accessionNumber}
                </Grid>
                <Grid item sm={5} xs={5}>
                  <strong>Samples Needed: </strong>
                  {visit.samplesNeeded}
                </Grid>
                <Grid item sm={6} xs={6}>
                  <strong>Samples Collected: </strong>
                  {visit.samplesCollected}
                </Grid>
                {visit.scheduledDate && (
                  <Grid item sm={5} xs={5}>
                    <strong>Scheduled Date: </strong>
                    <DateTimeDisplay
                      date={parseISO(visit.scheduledDate)}
                      dateFormat="MM/dd/yyyy HH:mm:ss"
                    />
                  </Grid>
                )}
                {visit.completedAt && (
                  <Grid item sm={6} xs={6}>
                    <strong>Completed Date: </strong>
                    <DateTimeDisplay
                      date={parseISO(visit.completedAt)}
                      dateFormat="MM/dd/yyyy HH:mm:ss"
                    />
                  </Grid>
                )}
                <Grid item sm={4} xs={4} justifyContent="right">
                  <Button
                    sx={{ mx: 1, minHeight: "3rem", marginLeft: "0px" }}
                    onClick={() => {
                      navigate(pathname + "/samples");
                    }}
                  >
                    View Samples
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default VisitDetails;
