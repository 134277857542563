import { Box, Card, CardContent } from "@mui/material";
import React from "react";

type Props = React.PropsWithChildren<{}>;

function EmptyContainer({ children }: Props) {
  return (
    <Card
      elevation={0}
      sx={{
        flex: 1,
        marginTop: "32px",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        border: "solid thin",
        borderRadius: ".5rem",
        borderColor: "grey.300",
      }}
    >
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default EmptyContainer;
