import { useParams } from "react-router";
import { useGetSiteById, useUpdateSite } from "../../api";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import EditSiteForm from "../forms/EditSiteForm";

function SiteDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { siteId: siteIdParam } = useParams<{ siteId: string }>();

  const siteId = Number(siteIdParam);
  const { data } = useGetSiteById(siteId);
  const { mutate, ...status } = useUpdateSite();

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit Site" />

      <CardContent>
        {data && data.site && (
          <EditSiteForm
            status={status}
            site={data.site}
            onSave={(form) =>
              mutate(
                { siteId, data: form },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Site Updated", {
                      variant: "success",
                    });
                  },
                }
              )
            }
          />
        )}
      </CardContent>
    </Card>
  );
}

export default SiteDetailsPage;
