import { GridColDef, DataGridProps } from "@mui/x-data-grid";
import useLocationState from "../useLocationState";

interface GridState<TSearchQueryFilter> {
  page: number;
  pageSize: number;
  orderBy?: string;
  reverse?: true;
  searchFilter?: TSearchQueryFilter;
}

type GridQuery<TSearchQueryFilter> = {
  page: number;
  pageSize: number;
  orderBy?: string;
  reverse?: true;
} & TSearchQueryFilter;

const rowsPerPageOptions = [10, 20, 50, 100];

type GridDefaults = Omit<Partial<DataGridProps>, "columns"> &
  Pick<DataGridProps, "columns">;

export default function useDataGrid<TSearchQueryFilter = any>(
  key: string,
  columnDefs: GridColDef[]
) {
  const [state, setState] = useLocationState<GridState<TSearchQueryFilter>>(
    `grid.${key}`,
    {
      page: 0,
      pageSize: 20,
    }
  );

  const { page, pageSize, searchFilter, ...rest } = state;

  const grid: GridDefaults = {
    columns: columnDefs,
    pagination: true,
    paginationMode: "server",
    page,
    pageSize,
    rowsPerPageOptions,
    onPageChange(newPage) {
      setState({ ...state, page: newPage });
    },
    onPageSizeChange(newPageSize) {
      setState({ ...state, page: 1, pageSize: newPageSize });
    },
    onSortModelChange(sortModel) {
      const { field, sort } = sortModel?.[0] || {};
      setState({
        ...state,
        orderBy: field,
        reverse: sort === "desc" ? true : undefined,
      });
    },
  };

  const updateSearchQuery = (queryString: TSearchQueryFilter) => {
    setState({ ...state, searchFilter: queryString, page: 0 });
  };

  const query: GridQuery<TSearchQueryFilter> = {
    page: page + 1,
    pageSize,
    ...searchFilter,
    ...rest,
  } as any;

  return { grid, query, updateSearchQuery };
}
