import { useParams } from "react-router";
import { useGetPatientById, useUpdatePatient } from "../../api";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import EditPatientForm from "../forms/EditPatientForm";

function PatientDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const { patientId: patientIdParam } = useParams<{ patientId: string }>();

  const patientId = Number(patientIdParam);
  const { data } = useGetPatientById(patientId);
  const { mutate, ...status } = useUpdatePatient();

  return (
    <Card elevation={0} sx={{ overflow: "unset" }}>
      <CardHeader title="Edit Patient" />

      <CardContent>
        {data && data.patient && (
          <EditPatientForm
            status={status}
            patient={data.patient}
            onSave={(form) =>
              mutate(
                { patientId, data: form },
                {
                  onSuccess: () => {
                    enqueueSnackbar("Patient Updated", {
                      variant: "success",
                    });
                  },
                }
              )
            }
          />
        )}
      </CardContent>
    </Card>
  );
}

export default PatientDetailsPage;
