import React from "react";
import { Route, Routes } from "react-router";

import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import SignInPage from "./SignInPage";
import SignOutPage from "./SignOutPage";
import UnauthorizedPage from "./UnauthorizedPage";

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signout" element={<SignOutPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<SignInPage />} />
    </Routes>
  );
}

function AccountRoutes() {
  return (
    <Routes>
      <Route path="/signout" element={<SignOutPage />} />
      <Route path="*" element={<UnauthorizedPage />} />
    </Routes>
  );
}

export default AccountRoutes;
