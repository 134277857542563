import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import {
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { createRef, useState } from "react";
import { useDebounce } from "react-use";

export interface DataGridToolbarProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  addButtonLabel?: string;
  initialSearch?: string;
  enableExport?: boolean;
  onAdd?(): void;
  onSearch?(text: string): void;
}

function DataGridToolbar({
  header,
  subheader,
  addButtonLabel,
  initialSearch,
  enableExport = false,
  onAdd,
  onSearch,
}: DataGridToolbarProps) {
  const [state, setState] = useState(initialSearch);
  const searchFieldRef = createRef<HTMLInputElement>();

  useDebounce(
    () => {
      onSearch?.(state || "");
    },
    500,
    [state]
  );

  return (
    <GridToolbarContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{ pb: 2 }}
      >
        <Stack>
          <Typography
            variant="subtitle1"
            sx={{
              textTransform: "uppercase",
              fontSize: "1.25rem",
              fontWeight: 700,
            }}
          >
            {header}
          </Typography>
          {!!subheader && (
            <Typography variant="subtitle2">{subheader}</Typography>
          )}
        </Stack>
        <Stack direction="row">
          {!!onSearch && (
            <TextField
              autoFocus
              placeholder="Search..."
              inputRef={searchFieldRef}
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      searchFieldRef.current?.focus();
                      setState("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            ></TextField>
          )}
          {enableExport && (
            <Stack direction="row">
              <GridToolbarExport sx={{ ml: 2, px: 2, boxShadow: "none" }} />
            </Stack>
          )}
          {!!addButtonLabel && (
            <Button
              sx={{
                ml: 2,
                px: 2,
                minWidth: "auto",
                whiteSpace: "nowrap",
              }}
              onClick={() => onAdd?.()}
            >
              {addButtonLabel}
            </Button>
          )}
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}

export default DataGridToolbar;
