import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";
import { makeStyles } from "@mui/styles";
import RequiredMarker from "shared/components/RequiredMarker";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      display: "flex",
      alignItems: "center",
      "& .MuiBox-root": {
        marginLeft: "8px",
        marginTop: "0px",
        marginBottom: "0px",
        order: 999,
      },
    },
  },
});

type PhoneFieldProps<T> = FormTextFieldProps<T>;

function PhoneField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  required = false,
  label,
  ...props
}: PhoneFieldProps<T>) {
  const { getError } = useFormErrors();
  const classes = useStyles();
  rules = { ...rules, required };
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <MuiTextField
          {...props}
          {...getError(name)}
          className={clsx(
            className,
            classes.root,
            readOnly && "textfield-readonly"
          )}
          value={value ?? undefined}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          inputProps={{ unmask: true, style: { textAlign: "right" } }}
          InputProps={{
            readOnly,
            inputComponent: PhoneMaskedInput as any,
          }}
          label={
            <>
              {label}
              <RequiredMarker required={required} />
            </>
          }
        />
      )}
    />
  );
}

export interface MaskedInputProps {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const PhoneMaskedInput = React.forwardRef<HTMLElement, MaskedInputProps>(
  function MaskedInput(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask="{+1} (000) 000-0000"
        placeholderChar="#"
        overwrite
        {...other}
        //@ts-expect-error imask missing type def for mask
        inputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
      />
    );
  }
);

export default asVariants(PhoneField);
