import { useAddVisit, VisitsAddVisitForm, VisitsAddVisitResult } from "api";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import StyledDialog from "shared/components/StyledDialog";
import { DialogContent, Grid, Box, Button } from "@mui/material";
import { FormErrorAlert, SubmitButton } from "shared/forms";
import TextField from "shared/forms/fields/TextField";
import NumberField from "../../shared/forms/fields/NumberField";

interface Props {
  open: boolean;
  studyId: number;
  onClose: () => void;
}

function AddVisit({ open, studyId, onClose }: Props) {
  const { control, handleSubmit, formState, reset } =
    useForm<VisitsAddVisitForm>({
      mode: "onChange",
    });
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useAddVisit({
    mutation: {
      onSuccess(result: VisitsAddVisitResult) {
        enqueueSnackbar("Visit Added", {
          variant: "success",
        });
        onClose();
        reset();
      },
    },
  });

  const onSubmit = handleSubmit((form) => {
    mutate({
      data: {
        name: form.name,
        sequence: form.sequence,
        referenceId: form.referenceId,
      },
      studyId,
    });
  });

  return (
    <>
      {!open && <></>}
      {open && (
        <StyledDialog open={open} title={`Add Visit`} onClose={onClose} maxWidth="sm">
          <DialogContent>
            <Box onSubmit={onSubmit} component="form">
              <FormErrorAlert />
              <Grid container spacing={4}>
                <Grid item sm={6}>
                  <TextField
                    control={control}
                    name="name"
                    label="Visit Name"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <NumberField
                    control={control}
                    rules={{
                      min: 1,
                    }}
                    name="sequence"
                    label="Sequence"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    control={control}
                    name="referenceId"
                    label="Id"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item sm={6}>
                  <SubmitButton variant="save" disabled={!formState.isValid} />
                  <Button variant="contained" onClick={() => onClose()}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </StyledDialog>
      )}
    </>
  );
}

export default AddVisit;
