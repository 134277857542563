import { Card, CardHeader } from "@mui/material";
import AdminDashboard from "home/components/AdminDashboard";
import NurseDashboard from "home/components/NurseDashboard";
import { useGetCurrentUser } from "api";
import { canManageStudies, hasCoordinatorRole } from "shared";
import LabTechnicianDashboard from "home/components/LabTechnicianDashboard";

function NavigationDashboard() {
  const { data } = useGetCurrentUser();

  if (!data?.user) return null;
  return (
    <>
    {canManageStudies(data.user) && (
      <Card elevation={0} sx={{ overflow: "unset" }}>
        <CardHeader title="Admin / Lab Manager Dashboard" />
        <AdminDashboard user={data.user} />
      </Card>
    )}
    {hasCoordinatorRole(data.user) && (
      <Card elevation={0} sx={{ overflow: "unset" }}>
        <CardHeader title="Coordinator Dashboard" />
        <NurseDashboard />
      </Card>
    )}
    {hasCoordinatorRole(data.user) && (
      <Card elevation={0} sx={{ overflow: "unset" }}>
        <CardHeader title="Technician Dashboard" />
        <LabTechnicianDashboard />
      </Card>
    )}
    </>
  );
}
export default NavigationDashboard;
