import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { SubmitButton } from "../../shared/forms";
import DateTimeField from "../../shared/forms/fields/DateTimeField";
import DropDownField from "../../shared/forms/fields/DropDownField";
import NumberField from "../../shared/forms/fields/NumberField";
import TextField from "../../shared/forms/fields/TextField";
import {
  LabTechnicianUpdateBatchPatientSampleTaskForm,
  LabTechnicianUpdateBatchPatientSampleTaskPatientSampleTaskRecord,
  UsersGetAllUsersUserModel,
  useGetPatientSampleTasks,
} from "../../api";
import RequiredMarker from "shared/components/RequiredMarker";

interface Props {
  patientId: number;
  sampleId: number;
  users: UsersGetAllUsersUserModel[];
  onSave: (form: LabTechnicianUpdateBatchPatientSampleTaskForm) => void;
}

function ManagePatientSampleTasks({ patientId, sampleId, users, onSave }: Props) {
  const patientTasks = useGetPatientSampleTasks(patientId, sampleId);
  const { control, handleSubmit, formState, register, setValue } =
    useForm<LabTechnicianUpdateBatchPatientSampleTaskForm>({
      defaultValues: {
        labTasks: patientTasks.data?.patientSampleLabTasks.map(
          (m) =>
            ({
              patientId: m.patientId,
              sampleId: m.sampleId,
              labTaskId: m.labTaskId,
              executedById: m.executedById,
              timestamp: m.timestamp,
            } as LabTechnicianUpdateBatchPatientSampleTaskPatientSampleTaskRecord)
        ),
      },
    });

  const { fields } = useFieldArray({
    name: "labTasks",
    control: control,
    keyName: "id",
  });

  useEffect(() => {
    if(patientTasks.data){
      setValue(
        "labTasks",
        patientTasks.data?.patientSampleLabTasks.map((m) => ({
          patientId: m.patientId,
          sampleId: m.sampleId,
          labTaskId: m.labTaskId,
          executedById: m.executedById,
          timestamp: m.timestamp,
          quantity: m.quantity,
          comments: m.comments,
        }))
      );
    }
  }, [patientTasks.data, setValue]);

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        padding: "1rem",
        border: "solid thin",
        borderRadius: ".5rem",
        borderColor: "grey.300",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <Grid container mb={1}>
        <Grid
          item
          xs={12}
          sx={{
            paddingBottom: 1,
          }}
        >
          <Grid container>
            <Grid item xs={3}>
              <Typography variant="h5">Lab Tasks</Typography>
            </Grid>
            <Grid item xs={3} flexDirection='row' display='flex' alignItems='center'>
              <Typography variant="h5">Timestamp</Typography>&nbsp;<RequiredMarker  required={true} />
            </Grid>
            <Grid item xs={3} flexDirection='row' display='flex' alignItems='center'>
              <Typography variant="h5">Executed By</Typography>&nbsp;<RequiredMarker required={true} />
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5">Aliquot Quantity</Typography>
            </Grid>
          </Grid>
        </Grid>
        {fields.map((m, i) => {
          return (
            <Grid item xs={12} key={m.id} mt='1rem'>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h6">
                    {
                      patientTasks.data?.patientSampleLabTasks.find((f) => f.labTaskId === m.labTaskId)
                        ?.labTaskName
                    }
                    <input
                      type="hidden"
                      {...register(`labTasks.${i}.labTaskId`)}
                      name={`labTasks.${i}.labTaskId`}
                    />
                    <input
                      type="hidden"
                      {...register(`labTasks.${i}.patientId`)}
                      name={`labTasks.${i}.patientId`}
                    />
                    <input
                      type="hidden"
                      {...register(`labTasks.${i}.sampleId`)}
                      name={`labTasks.${i}.sampleId`}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <DateTimeField
                    control={control}
                    name={`labTasks.${i}.timestamp`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DropDownField
                    control={control}
                    name={`labTasks.${i}.executedById`}
                    options={users}
                    getOptionKey={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.username}
                    placeholder="Executed by"
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberField
                    control={control}
                    name={`labTasks.${i}.quantity`}
                    placeholder="Quantity"
                  />
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={9} mb='1rem'>
                  <TextField
                    control={control}
                    label="Comments"
                    name={`labTasks.${i}.comments`}
                    placeholder="Comments"
                  />
                </Grid>
              </Grid>
              <hr></hr>
            </Grid>
          );
        })}
      </Grid>
      <SubmitButton variant="save" disabled={!formState.isValid} />
    </Box>
  );
}
export default ManagePatientSampleTasks;
